@import "~bourbon/app/assets/stylesheets/bourbon";
@import "~bootstrap/scss/bootstrap";
@import "~bootstrap-touchspin/dist/jquery.bootstrap-touchspin.min.css";
@import "~slick-carousel/slick/slick.scss";

/*Partials*/
@import "partials/_featherlight.scss";;
@import "partials/_variables.scss";
@import "partials/_mixins.scss";
@import "partials/_typography.scss";

@import "partials/commons.scss";
/*Components*/
@import "components/header.scss";
@import "components/menu.scss";
@import "components/product-list.scss";
@import "components/dropdown.scss";
@import "components/button.scss";
@import "components/breadcrumb.scss";
@import "components/product-flags.scss";
@import "components/product-prices.scss";
@import "components/tabs.scss";
@import "components/forms.scss";
@import "components/alert.scss";
@import "components/notification.scss";
@import "components/pagination.scss";
@import "components/product-miniature.scss";
@import "components/footer.scss";
@import "components/quickview.scss";
@import "components/quantity.scss";
@import "components/product-sort.scss";
@import "components/mobile-menu.scss";

/*Modules*/
@import "modules/blockreassurance.scss";
@import "modules/ps_shoppingcart.scss";
@import "modules/ps_languageselector.scss";
@import "modules/ps_currencyselector.scss";
@import "modules/ps_customersignin.scss";
@import "modules/ps_facetedsearch.scss";
@import "modules/ps_customtext.scss";
@import "modules/ps_sharebuttons.scss";
@import "modules/ps_imageslider.scss";
@import "modules/ps_socialfollow.scss";
@import "modules/ps_searchbar.scss";

/*Pages*/
@import "pages/product.scss";
@import "pages/category.scss";
@import "pages/cart.scss";
@import "pages/checkout.scss";
@import "pages/customer.scss";
@import "pages/cms.scss";

body {
  @include var(background, body, background);
  overflow-x:hidden;
  padding-top: 60px;
  @include media-breakpoint-up(lg) {
    padding-top: 0;
  }
}

.modal {
    padding-right: 0 !important;
}
.modal-dialog {
    @include media-breakpoint-up(lg) {
        max-width: 960px;
    }
}

.products-section-title {
    margin: 0;
}
.block-header {
    padding-bottom: 1rem;
    margin-bottom: 1.5rem;
    @include var(border-bottom-style, border, style);
    @include var(border-bottom-width, border, thickness);
    @include var(border-bottom-color, border, color);
}
.featured-products {
    margin-bottom: 3rem;
}
.modal {
    figure {
        margin-bottom: 0;
    }
}

#menu-trigger {
    display: block;
    @include media-breakpoint-up(lg) {
        display: none;
    }
}

.home-banner{
    margin-top: 2em;
    img {
        width: 100%;
        height: auto;
    }
}

body#checkout {
    padding-top: 0 !important;
}

hr {
    border-top: 1px solid #eee;
}

.category-tree {
    background: #fff;

    ul {
        display: none;
    }
    > ul {
        display: block;

       > li {
            display: block;
            border-bottom: 1px solid #eee;

            > ul {
                display: block;

                > li {
                    border-bottom: 1px solid #eee;
                    padding-left: 1.5rem;
                    &:last-child {
                        border-bottom: none;
                    }
                }
            }
        }
    }
    a {
        display: block;
        padding: 1rem;
    }
}

#login-form {
    .col-12 {
        flex: 0 0 100%;
        max-width: 100%;
    }
}
.page-header {
    overflow: hidden;
}

#demo-trigger {
    position: absolute;
    left: 0;
    top: 50%;
    height: 70px;
    width: 70px;
    box-shadow: -2px 0 3px rgba(0, 0, 0, .2);
    z-index: 99999;
    background: #ffffff;
    border-radius: 3px 0 0 3px;
    padding: .5rem;
    text-align: center;
    color: #000;
    transform: translateX(-100%);
    cursor: pointer;
    span {
        position: absolute;
        left: 2px;
        top: 15px;
        transform: rotate(-90deg);
        transform-origin: bottom;
    }
    .icon {
        position: absolute;
        top: 50%;
        right: 10px;
        font-size: 2em;
        transform: translateY(-50%);
    }
}

#demo-flyout {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 99989;
    height: 100vh;
    background: #ffffff;
    box-shadow: 0 0 5px rgba(0, 0, 0, .2);
    transform: translateX(100%);
    transition: 300ms ease all;

    &.out {
        transform: translateX(0);
    }
    .inner {
        padding: 1.5rem;
    }
    .demo {
        margin-bottom: 1rem;
        img {
            border-radius: 5px;
            box-shadow: 0 0 5px rgba(0, 0, 0, .2);
            margin-bottom: .4rem;
            width: 150px;
        }
        span {
            display: block;
        }
    }
}