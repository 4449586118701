.block-social {
    > h4 {
    }

    > ul {
        margin: 0;
        padding: 0;

        li {
            display: inline-block;
        }
    }
    a {
        > span {
            display: none;
        }
        &:hover {
            .social-icon {
                
                @include var(fill, button-primary, default);
            }
        }
    }
}


.site-footer {
    .social-icon {
        height: 24px;
        width: 24px;
        @include var(fill, footer, link);

        vertical-align: middle;
    }
    .block-social {
        > ul {

            li {
                margin-bottom: .5em;
                display: inline-block;
                margin-right: .8em;
            }
        }
        a {
            > span {
                margin-left: .5em;
                display: inline-block;
            }
        }
    }
}