.notification {
    margin-top: 1rem;
    @include make-container();
    max-width: 1440px;

    ul {
        border: 2px solid #000;
        padding: 1rem;
    }

    &-danger {
        ul {
            border-color: #dc3545;
            color: #dc3545;
        }
    }
    &-success {
        border-color: #28a745;
        color: #28a745;
    }
    &-info {
        border-color: #007bff;
        color: #007bff;
    }
    &-warning {
        border-color: #ffc107;
        color: #ffc107;
    }
}