.customer-nav {
    background: #fff;
    border-style: solid;
    @include var(border-width, border, thickness);
    @include var(border-color, border, color);
    margin-bottom: 1.5rem;
    > ul {
        margin: 0;
        &.overview {
            > li {
                height: 53px;
            }
        }
        > li {
            
            position: relative;
            height: 0;
            overflow: hidden;
            transition: 300ms ease all;

            @include media-breakpoint-up(lg) {
                display: block;
                height: 53px;
            }

            a {
                height: 53px;
                display: block;
                width: 100%;
                max-width: none;
                border-bottom-style: solid;
                @include var(border-bottom-width, border, thickness);
                @include var(border-bottom-color, border, color);
                i {
                    margin-right: .3em;
                }

                display: block;
                padding: 1em;
            }
            &.current-page {
                height: 53px;
                border: none;

                &::after {
                    content: "\e842";
                    font-family: 'feather' !important;
                    text-rendering: optimizeLegibility;
                    font-size: 24px;
                    position: absolute;
                    top: 50%;
                    margin-top: -12px;
                    right: 1em;
                    cursor: pointer;
                    height: 24px;
                    line-height: 1;
                }
                    a {
                        border-bottom: none;
                    }
                @include media-breakpoint-up(lg) {
                    border-bottom: 1px solid #eee;
                    &:last-child {
                        border-bottom: 0;
                    }
                    &::after {
                        display: none;
                    }
                }

                a {
                    font-weight: bold;
                }
            }
            &:last-child a {
                border-bottom: none;
            }
        }
    }
    &.open {
        li {
            height: 53px;
            &.current-page {
                a {
                border-bottom-style: solid;
                @include var(border-bottom-width, border, thickness);
                @include var(border-bottom-color, border, color);
                }
                &::after {
                    content: "\e83f";
                }
            }
        }
    }
}


article.address {
    border-style: solid;
    @include var(border-width, border, thickness);
    @include var(border-color, border, color);
    .address-body {
        padding: 1rem;
    }
    .address-footer {
        padding: 1rem;
    }
}

.overview-block {
    border-style: solid;
    @include var(border-width, border, thickness);
    @include var(border-color, border, color);
    padding: 1em;
    margin-bottom: 1em;
}

.addresses-footer {
    margin-bottom: 1em;
    a {
        padding: 2em;
        border: 1px dashed #eee;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        &:hover {
            border-color: currentColor;
        }
    }
}

.customer-page .content-box {
    margin-top: 0;
}

.order, .cart-rule {
    border-bottom-style: solid;
    @include var(border-bottom-width, border, thickness);
    @include var(border-bottom-color, border, color);
    padding-bottom: 1em;
    margin-bottom: 1em;

    &:last-child {
        border: none;
        padding-bottom: 0;
        margin-bottom: 0;
    }
}


.ps-alert-error, .ps-alert-success {
    li {
        border: 2px solid #eee;
        padding: .5rem 1rem;
        text-align: center;
        p {
            margin: 0;
        }
    }
}
.ps-alert-success {
    li {
        border-color: #28a745;
        color: #28a745;
    }
}

.ps-alert-error {
    li {
        border-color: $sale;
        color: $sale;
    }
}

body:not(.layout-boxed) {
    .login-form .content-box {
        padding: 1.5rem;
        border: 1px solid #eee;
    }
}

#login-form  .btn[data-action="show-password"] {
    width: 80px;
}

.my-account-links {
    > a {
        margin-bottom: .5rem;
    }
    .link-item {
        display: block;
        padding: 1.5rem;
        border: 1px solid #eee;
    }
}

