.brand {
    opacity: .5;
    @include media-breakpoint-down(md) {
        img {
            height: 35px;
        }
    }
    &:hover {
        opacity: 1;
    }
}

#search_filters_brands {

    @include var(background, body, background);
    border-top-style: solid;
    @include var(border-top-width, border, thickness);
    @include var(border-top-color, border, color);
}


.site-footer {
    margin-top: 4em;
    @include var(background, footer, background);

    @include var(color, footer, color);

    h4 {
        color: var(--theme-footer-text);
    }
    a {
        @include var(color, footer, link);
        &:hover {
            @include var(color, button-primary, default);
        }
    }
    ul {
        > li {
            margin-bottom: .3em;
        }
    }
    .row {
        margin-bottom: 1em;
        &:last-child {
            margin-bottom: 0;
        }
    }
    .footer-section {
        padding: 2rem 0;
        &.--first {
            padding: 4rem 0 2rem 0;
        }
        &.--last {
            @include var(background, body, background);
            color: $body-color;
            padding: 1rem 0;
            margin-top: 1em;
            a {
                color: $link-color;
            }
        }
    }
    .footer-col {
        margin-bottom: 1em;
    }
    .block-title {
        font-weight: bold;
        margin-bottom: .5em;
        text-transform: uppercase;
    }
}

.email_subscription {
    input[type=text] {
        width: 100%;
        background: none;
        border-style: solid;
        @include var(border-width, border, thickness);
        @include var(border-color, footer, input);
        border-right: none;
        height: 55px;
        padding-left: 1.5em;
        border-right: none;



        &:focus {
            color: var(--theme-footer-text);

        }
    }
    input[type=submit] {
        background: none;
        border-style: solid;
        @include var(border-width, border, thickness);
        @include var(border-color, footer, input);
        height: 55px;
        display: block;
        border-radius: 0 !important;
        padding-left: 2rem;
        padding-right: 2rem;
        

        &:hover {
            @include var(background, footer, input);
        }
    }
    .sub_form {
        position: relative;
    }
    .notification {
        margin-top: .5em;
        border: 2px solid;
        @include var(border-color, footer, input);

        text-align: center;
        padding: .5em;
        &.notification-success {
            border-color: #28a745;
            color: #28a745;
        }
        &.notification-error {
            border-color: $danger;
            color: $danger;
        }
    }

}

