

.modal-content {
    border-radius: 0;
    border:none;
}

.quickview {
    .product-cover .layer {
        display: none !important;
    }
    .product-price {
        .product-price-current {
            font-size: 2em;
            font-weight: bold;
            color: #000;
        }
        .regular-price {
            color: #999;
            text-decoration: line-through;
        }
        .discount-percentage, .discount-amount {
            color: red;
        }
    }
}
