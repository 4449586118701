ul.cart-items {
    margin: 0;
    > li {
        padding: 1rem 0;
        border-bottom: 1px solid #eee;
        &:first-child {
            padding-top: 0;
        }
        &:last-child {
            padding-bottom: 0;
            border-bottom: none;
        }
    }
}
.cart-overview {
    

    .cart-product-title {
        margin-bottom: .3rem;
        a {
            color: #000;
        }
    }
    .content-box {
        padding: 1.5rem;
        border: 1px solid #eee;
    }
}


#cart-subtotal-discount {
    .value {
        color: $sale;
    }
}
.cart-actions {
    margin-bottom: 1rem;
    .button {
        display: block;
    }
}
.cart-total > .value {
    font-weight: bold;
    font-size: 1.286em;
}
.cart-product-name {
    font-weight: bold;
}

.remove-from-cart i {
    font-size: 1rem;
}
.cart-item {
    .product-image {
        display: block;
        //text-align: center;
    }
}
.product-line-info {
    &.product-details {
        font-size: 0.875em;
    }
}
.promo-code {
    .alert-danger {
        display: none;
        border: 2px solid $danger;
        background: none;
        color: $danger;
        padding:.5rem;
        font-size: 0.875em;
        font-weight: 500;
        margin-top: 1em;
    }
    span {
        vertical-align: middle;
    }
}
.promo-code-line {
    padding: .5em 1em;
    border: 1px solid #eee;
}

.cart-voucher {
    .cart-summary-line {
        background: #F5F5F5;
        padding: .5em 1em;
        font-size: 0.875em;
    }
    .voucher-discount {
        color: $sale;
        font-weight:500;
        a {
            margin-left: .5em;
        }
        .icon {
            font-size: 18px;
        }
    }
    .voucher-form {
        overflow: hidden;
        form {
            width: 100%;
        }
    }
}

.cart-detailed-totals {
    
    margin-bottom: 1rem;
}
.cart-summary-line {
    padding: 1rem;
    background: #eee;
    margin-bottom: 1px;
    &:last-child {
        border-bottom: none;
    }
}
.cart-summary-line, .promo-code-line {
    display: table;
    width: 100%;
    > div {
        display: table;
        width: 100%;
    }
    span {
        display: table-cell;
        &.value {
            text-align: right;
            font-weight: bold;
        }
    }
}

.layout-boxed .cart-overview .content-box {
    margin-top: 0;
}