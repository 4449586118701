.centered-header {
    .menu > ul > li {
    }
}

.menu {
    position: static;
    text-align: center;
    padding: 0;
    > ul {
        width: 100%;
        margin: 0;
        @media (min-width: 992px) {
            height: 100%;
        }

        > li {
            display: inline-block;
            padding: 1rem .5rem;
            font-size: 1em;
            cursor: pointer;
            position: relative;
            @media (min-width: 992px) {
                    align-items: center;
                display: inline-flex;
                height: 100%;
            }

            @include media-breakpoint-up(xl) {
                padding: 1rem .8rem;
            }

            > a {
                display: inline-block;
                text-align: center;
                text-transform: uppercase;
                @include var(color, nav, color);
            }

            &:hover {
                > div {
                    left: 0;
                }
                a {
                    position: relative;
                }
                > a::after {
                    position: absolute;
                    bottom: -1rem;
                    left: 0;
                    content: "";
                    width: 100%;
                    height: 3px;
                    display: block;
                    background: currentColor;
                }
            }
            > div {
                position: absolute;
                z-index: 8;
                left: -9999px;
                top: 100%;
                text-align: left;
                width: 18rem;
                &::before {
                        border-top: 1px solid #eee;
                        position: absolute;
                        top: 0;
                        z-index: -100;
                        left: 0;
                        right: 0;
                        @include var(background, header, background);
                        content: "";
                        display: block;
                        bottom: 0;
                        box-shadow: 0 2px 2px rgba(0,0,0,.07);
                }

                > ul {
                    padding: .5rem 0 .5rem 0;
                    > li {
                        line-height: 1.45;
                        padding: .5rem 1rem;
                        display: block;
                        overflow: hidden;

                        > a {
                            display: inline-block;

                            .icon {
                                display: none;
                            }
                        }
                        ul {
                            margin-top: .5rem;
                        }
                    }
                }
            }

            &.mega {
                position: static;
                &:hover {
                    > div {
                        left: 0;
                    }
                }
                > div {
                    position: absolute;
                    z-index: 8;
                    left: -9999px;
                    top: 100%;
                    margin: 0 auto;
                    text-align: left;
                    width: 100%;

                    &::before {
                        border-top: 1px solid #eee;
                        position: absolute;
                        top: 0;
                        z-index: -100;
                        left: -100%;
                        right: -100%;
                        @include var(background, header, background);
                        content: "";
                        display: block;
                        bottom: 0;
                        box-shadow: 0 2px 2px rgba(0,0,0,.07);
                    }

            
                    > ul {
                        padding: 1em 0 1rem 0;
                        margin: 0 auto;
                        max-width: 1440px;
                        column-count: auto;
                        column-width: 18rem;

                        > li {
                            line-height: 1.45;
                            padding: 1rem;
                            page-break-inside: avoid;
                            display: block;
                            overflow: hidden;

                            > a {
                                text-transform: uppercase;
                                font-weight: bold;
                                display: inline-block;

                                .icon {
                                    display: none;
                                }
                            }
                            ul {
                                margin-top: .5rem;
                            }
                        }
                    }
                }
            }


        }

    }
}