// Theme colors
$primary: #31C9AA;
$secondary: #6A7478;

$danger:#dc3545;
$success:#28a745;
$info:#007bff;
$warning:#ffc107;

//  CSS Properties and Theme Defaults
$theme: (

    color: (
        primary: $primary,
        secondary: $secondary,
    ),

    body: (
        background: #fff,
        color: #2d3132
    ),

    button-primary: (
        default: $primary,
        hover: saturate(darken($primary,10%), 10%),
        active: saturate(darken($primary,20%), 20%),
        color: #fff
    ),
    button-secondary: (
        default: $secondary,
        hover: darken($secondary,30%),
        active: darken($secondary,40%),
        color: #fff
    ),
    header: (
        background: #fff,
        color: #000,
        icon: #000,
        icon-change: $primary,
        border-bottom: 1px solid #eee,
    ),
    header-nav: (
        background: #000,
        color: #fff,
        link: #fff,
        hover: $primary
    ),
    nav: (
        color: #000,
        hover: $primary,
    ),
    border: (
        color: #eee,
        thickness: 1px,
        style: solid
    ),

    footer: (
        background: #000,
        color: #fff,
        link: transparentize(#fff, .6),
        input: transparentize(#fff, .6)
    ),

    link:(
        color: #000,
        hover: $primary
    ),
    content: (
        padding-top: 1.5rem,
        padding-right: 0,
        padding-bottom: 1.5rem,
        padding-left: 0,
        background: none,
        margin-top: 0,
    ),
    loader: (
        overlay: transparentize(#fff, .6),
        spinner: #ccc,
        indicator: $primary,
    ),
    dropdown: (
        background: #fff,
        link: #000,
        hover: $primary,
    ),

    typeface:(
        body: initial,
        heading: initial,
        price: initial,
        button: initial
    ),
    custom-text-block: (
        background: #fff,
        color: initial,
    ),
    product: (
        background: none,
    ),
);


// Generating CSS Properties
//:root {
    //@each $key, $value in $theme {
        //$parent: $key;
        //@each $keyx, $valuex in $value {
            
            //--#{$parent}-#{$keyx}: $valuex;
        //}
    //}
//}


$link-color:#202020;
$sale: #E44A4A;