.btn {
    @include button-size(0.8rem, 1rem, 1rem, 1.5, 0);
    //text-transform: uppercase;
    //font-weight: bold;
    font-family: $body;
    font-size: 1rem;
    &:focus,.focus {
        box-shadow: none !important;
    }
    &:hover {
        border-color: transparent;
    }
}

.btn-primary {

    @include var(background, button-primary, default);
    @include var(color, button-primary, color);
    border-color: $primary;
    

    &:hover {
        border-color: transparent;
        @include var(background, button-primary, hover);
    }
    &:active:not([disabled]):not(.disabled):active {
        border-color: transparent;
        @include var(background, button-primary, active);
    }

    &.disabled, &:disabled {
            border-color: transparent;
            background: #E6E6E6;
            color: #C8C8C8;
            &:hover {
                background: #E6E6E6;
                color: #C8C8C8;
                cursor: not-allowed;
            }
    }
    &:focus {
        @include var(color, button-primary, color);
    }
}

.btn-secondary {

    @include var(background, button-secondary, default);
    @include var(color, button-secondary, color);
    

    &:hover {
        @include var(background, button-secondary, hover);
        border-color: transparent;
    }
    &:active:not([disabled]):not(.disabled):active {
        @include var(background, button-secondary, active);
        border-color: transparent;
    }
    &.disabled, &:disabled {
            background: #E6E6E6;
            color: #C8C8C8;
            border-color: transparent;
    }
}
.btn-default {
    border: 1px solid #eee;
    box-sizing:border-box;
}

.btn-link {
    @include var(color, link, color);
    font-weight: normal;
    text-transform: none;
    &:hover {
        @include var(color, link, hover);
    }
}
.btn-unstyle {
    border: none;
    background: none;
    padding: 0;
    &:focus, &:active {
        outline: none;
    }
}
/* [class*="btn-outline-"] {
    text-transform: none;
    font-weight: normal;
} */
.btn-outline-secondary {
    background: none;
    &:focus, &:active {
        outline: none;
        box-shadow: none;
    }
}

.btn-sm {
    @include button-size(.25rem, .8rem, 0.875rem, 1.5rem, 0);
}
.btn-lg {
    @include button-size(1rem, 2rem, 1.2rem, 1.5rem, 0);
}