@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(~material-design-icons/iconfont/MaterialIcons-Regular.eot); /* For IE6-8 */
  src: local('Material Icons'),
       local('MaterialIcons-Regular'),
       url(~material-design-icons/iconfont/MaterialIcons-Regular.woff2) format('woff2'),
       url(~material-design-icons/iconfont/MaterialIcons-Regular.woff) format('woff'),
       url(~material-design-icons/iconfont/MaterialIcons-Regular.ttf) format('truetype');
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;  /* Preferred icon size */
  display: inline-block;
  vertical-align: middle;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}

$primary-font: 'Raleway', sans-serif;
$body: Arial, 'Helvetica Neue', sans-serif;
$headings: 'Raleway', sans-serif;


html {
    font-size: 93.8%;
}

body {
  font-family: $body;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.467;
  font-weight: 400;
  @include var(color, body, color);
}
h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  font-family: $primary-font;
  font-weight: bold;
}

h1, .h1 {
    font-size: 2.441em;
    margin: 0;
    line-height: 1.25;
    font-weight: bold;
}
h2, .h2 {
    font-size: 1.953em;
    font-weight: bold;
    line-height: 1.4;

}
h3, .h3 {
    font-size: 1.563em;
    font-weight: bold;
    line-height: 1.4;
}
h4, .h4 {
    font-size: 1.25em;
    line-height: 1.4;
}
h5, .h5 {
    font-size: 1em;
    line-height: 1.4;
}
h6, .h6 {
    font-size: 0.8em;
    line-height: 1.4;
}
p {
  font-size: 1em;
}

a {
  text-decoration: none;
  @include var(color, link, color);

  &:hover {
    @include var(color, link, hover);
    text-decoration: none;
  }
  &:focus {
    color: $link-color;
    text-decoration: none;
  }
}
