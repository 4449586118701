.spacer {
    @include var(padding-top, content, padding-top);
    @include var(padding-bottom, content, padding-bottom);
}
.breadcrumb[data-depth="1"] {
    display: none;
}
.breadcrumb {
    border-radius: 0;
    margin: 0;
    background: none;
    padding: 0;
    display: block;
    font-size: 0.875em;

    ol {
        padding: 0;
        margin: 0;
        display: block;
        overflow: hidden;
        list-style: none;

        li {
            float: left;
            margin-right: .5rem;

            &::after {
                float: right;
                vertical-align: top;
                margin-left: .5rem;
                font-family: 'feather' !important;
                content: "/";
                opacity: .7;

            }
            &:last-child {
                &::after {
                    content: "";
                }
            }
            a {
                display: block;
                float: left;
                text-transform: uppercase;

                span {
                    max-width: 200px;
                    text-overflow: ellipsis;
                    display: block;
                    white-space:nowrap;
                    overflow: hidden;
                }
            }
            &:last-child {
                a {
                    opacity: .5;
                    pointer-events:none;
                }
            }
        }
    }
}