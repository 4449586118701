.dropdown {
    button {
        > .icon {
            display: inline-block;
            transition: 100ms transform ease-in-out;
            font-size: 18px;
            margin-left: -3px;
        }
    }
    &.show {
        button {
            .icon {
                transform: rotate(180deg);
            }
        }
        .dropdown-menu {
            max-height: 300px;
            overflow-y: auto;
            opacity: 1;
            visibility: visible;
        }
    }
}


.dropdown-menu {
    border-radius: 0;
    border-color: #eee;
    @include var(background, dropdown, background);
    padding: 0;
    margin-top: 0;
    > ul {
        margin-bottom: 0;
    }
    li.dropdown-item {
        font-size: 0.875em;
        padding: 0;
        &.current {
            > a {
                font-weight: bold;
            }
        }
    }

}
.dropdown-item {
    a {
        padding: .8em 1em;
        display: block;
        position: relative;
        line-height: 1.25;
    }
}

.dropdown-menu-right {
    margin-right: -1px;
}