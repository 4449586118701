.cart-preview {
    position: relative;
    
    .header {
        > a {
            text-decoration: none;
            position: relative;
        }
    }
    .summary {


        .--blink {
            animation: blink 300ms cubic-bezier(.75,.34,.32,.62) 3 normal;
        }
        span {
            
            @include media-breakpoint-up(lg) {
                font-size: 14px;
            }
        }

        &__count {
            @include var(background, color, primary);
            border-radius: 50%;
            height: 17px;
            width: 17px;
            text-align: center;
            color: #fff;
            line-height: 17px;
            position: absolute;
            top: -14px;
            right: -3px;
            font-size: 10px;
            transform-origin: center;

            @include media-breakpoint-up(lg) {
                background: none;
                position: static;
                text-align: center;
                border-radius: 0;
                line-height: 20px;
                display: inline-block;
                height: 20px;
                @include var(color, link, color);
                vertical-align: text-bottom;
            }
        }

        &__label {
            display: none;
            @include media-breakpoint-up(lg) {
                display: inline-block;
                vertical-align: text-bottom;
            }

        }

    }
    .body-wrapper {
        display: flex;
        flex-direction: column;
    }
    .body {
        background: #fff;
        right: 0;
        width: 80vw;
        z-index: 25;
        visibility: hidden;
        opacity: 0;

        pointer-events: none;
        will-change: transform;
        transition: 500ms all cubic-bezier(0.19, 1, 0.22, 1);
        box-shadow: -2px 2px 2px rgba(0, 0, 0, 0.07);

        bottom: 0;
        position: fixed;
        top: 0;
        transform: translateX(100%);

        display: flex;
        flex-direction: column;

        &.visible {
            transform: translateX(0);
            opacity: 1;
            visibility: visible;
            pointer-events: auto;
        }
        #cart-close {
            position: absolute;
            top: .5rem;
            right: .5rem;
            cursor: pointer;
            @include media-breakpoint-up(lg) {
                right: 1rem;
            }
        }

        @include media-breakpoint-up(lg) {
            box-shadow: 0 2px 2px rgba(0, 0, 0, 0.07);
            position: absolute;
            top: 150%;
            width: 35rem;
            bottom: auto;
            height: auto;
            transform:  perspective(40cm)  scale(.9, .9) rotateX(5deg);
            transform-origin: top right;
            display: block;
            

            &.visible {
                opacity: 1;
                transform: perspective(40cm) scale(1,1) rotateX(0deg);
                visibility: visible;
                pointer-events: auto;

            }
        
            &::after {
                content: "";
                display: block;
                width: 0; 
                height: 0; 
                border-left: 10px solid transparent;
                border-right: 10px solid transparent;
                border-bottom: 10px solid #fff;
                position: absolute;
                top: -9px;
                right: 11px;
                z-index: 2;
                @include media-breakpoint-up(lg) {
                    right: 24px;
                }

            }

        }


        .blockcart {
            flex: 1;
            display: flex;
            flex-direction: column;

            @include media-breakpoint-up(lg) {
                display:block;
            }
           
            > ul {
                flex: 1;
                margin-bottom: 0;
                height: auto;
                overflow-y: auto;
                padding:0 1rem;
                @include media-breakpoint-up(lg) {
                    max-height: 500px;
                    display:block;
                }
                > li {
                    font-size: .875em;
                    padding: .5rem 0;
                    border-bottom: 1px solid #eee;
                    .row {
                        margin-left: -7px;
                        margin-right: -7px;
                        [class*="col-"] {
                            padding-right: 7px;
                            padding-left: 7px;
                        }
                    }
                    @include media-breakpoint-up(lg) {
                        border-top: none;
                        &:last-child {
                            border-bottom: none;
                        }
                    }
                }
            }
        }
   
        .cart-total {
            padding: 1rem;
            border-top: 1px solid #eee;

            .value {
                font-size: 1.375em;
                font-weight: bold;
            }
        }
        .cart-body-header {
            margin: 0;
            padding: .7rem 1rem;
            border-bottom: 1px solid #eee;
            @include media-breakpoint-up(lg) {
                border-top: none;
            }
        }
        .no-items {
            border-bottom: 1px solid #eee;
        }

    }
}

#cart-preview-toggle {
    cursor: pointer;
}
.hidden-on-scroll {
    .cart-preview {
        .body {
            top: 70px;
        }
    }
}
@keyframes blink {
    0% {
        transform: scale(.8);
    }
    50% {
        transform: scale(1.2);
    }
    100% {
        transform: scale(1);
    }
}


.cart-drawer .cart-preview {
    .body {
        background: #fff;
        right: 0;
        width: 80vw;
        max-width: 450px;
        z-index: 25;
        visibility: hidden;
        opacity: 0;

        pointer-events: none;
        will-change: transform;
        transition: 500ms all cubic-bezier(0.19, 1, 0.22, 1);
        box-shadow: -2px 2px 2px rgba(0, 0, 0, 0.07);

        height: auto;
        position: fixed;
        bottom: 0;
        top: 0;
        transform: translateX(100%);

        display: flex;
        flex-direction: column;
        &.visible {
            transform: translateX(0);
            opacity: 1;
            visibility: visible;
            pointer-events: auto;
        }
    }
}

@media (max-width: 991px) {
    #blockcart-wrapper {
        padding-left: 0.4em;
    }
    .cart-preview .summary__count {
        top: -5px;
    }
}