.product {
    @include var(background, product, background);
    margin-bottom: 2.5rem;
    padding-bottom: 2.5rem;

    &.has-bg {
        margin-bottom: 3rem;
        padding-bottom: 3rem;
    }

}
.layout-boxed {
    .product {
        padding-bottom: 0;
        margin-bottom: 0;
        &.has-bg {
            padding-bottom: 3rem;
        }
    }

}
.product_right_column, .site-brands {
     .carousel-arrows button, .carousel-products button.slick-arrow > svg {
       height: 32px;
       width: 32px;
     }
}
     
@include media-breakpoint-up(lg) {
.product_right_column {
    font-size: 1em;
    font-weight: bold;
    margin-bottom: 1em;
     .products-section-title {
        font-size: 15px;
     }
     .product-miniature {
        margin-bottom: 1rem;
     }
}
}
@include media-breakpoint-down(md) {
.images-container > .no-gutters { 
	margin-left: -15px;
	margin-right: -15px; }
}

.product-cover {
    position: relative;
    /*margin-bottom: 1em;*/
    > img {
        width: 100%;
        height: auto;
        display: block;
    }
    > .layer {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 2;
        cursor: pointer;
        background-color: rgba(0,0,0,0.2);
        transition: 200ms all ease-in-out;
        opacity: 0;

        > .icon {
            font-size: 72px;
            color: #fff;
        }
    }
    &:hover {
        > .layer {
            opacity: 1;
        }
    }
    .product-flags {
        position: absolute;
        top: 10px;
        right: 10px;
        z-index: 10;
    }
}
.cover-overlay {
    background-color: rgba(0,0,0,0.05);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
}

.product-add-to-cart {
    margin: 1rem 0 2rem 0;
    .add-to-cart {
        float: right;
        display: block;
        width: 100%;
        .icon {
            margin-right: .3em;
        }

    }
}

@include media-breakpoint-down(xs) {
  .product-add-to-cart .add-to-cart {
    padding-left: 0;
    padding-right: 0;
    font-size: .8rem;
  }
}

#kaliber_qty-add-to-cart {
  .product-quantity {
    height: 100%;
    .qty {
      height: 100%;
      .bootstrap-touchspin {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        & input, & button { height: 100%; }
      }
    }
  }
}

@include media-breakpoint-up(lg) {
.small-product-miniature,
.small-product-miniature button,
.small-product-miniature .btn {
    font-size: .8em;
}
}

.product-variants {
    margin-top: 1.5rem;
}



.variant {
    margin-bottom: 1em;

    select {
        width: auto;
        max-width: 100%;
    }
}


#product-modal {
    .image-container {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.product-information {
    > .product-prices {
        .product-price {
           >.price {

                @include media-breakpoint-up(md) {
                }
            }
/*             .regular-price {
            }
            .aeuc_before_label, .tax {
            }
            .discount-amount, .discount-percentage {
            } */
        }
    }

    #product-description-short {
        margin: 1em 0;
    }
}

.product-pack {
    margin: 1em 0;
    padding: 1em;
    @include var(border-width, border, thickness);
    @include var(border-color, border, color);
    border-style: solid;
}
.product-pack-miniature {
    margin-bottom: .5rem;
    padding: 0 0 .5rem 0;
    @include var(border-bottom-width, border, thickness);
    @include var(border-bottom-color, border, color);
    border-bottom-style: solid;
    font-size: 0.875em;
    text-align: left;

    .product-pack-title {
        font-size: 1em;
        margin-bottom: 0;
    }
    &:last-child {
        margin-bottom: 0;
        border-bottom: none;
    }
    p {
        margin-bottom: 0;
    }
}
.tax-delivery {
    font-size: 0.875em;
    color: #999;
    margin-bottom: .5em;
}

.option-label, .variant label {
    font-size: 0.875em;    
    font-weight: bold;
    display: block;
    margin-bottom: .3em;
}
#product-quantities {
    font-size: 0.875em;
}

.mail-alert {
    border: 1px solid #eee;
    padding: 1rem;
    margin-top: 1.5rem;
    &-title {
        font-weight: bold;
        margin-bottom: .5rem;
    }
}
.mail-alert-form {
    position: relative;
    .notification {
        margin-top: .5em;
        border: 1px solid rgba(255, 255, 255, .2);
        text-align: center;
        padding: .5em;
        &.notification-success {
            border-color: #00d08d;
            color: #00d08d;
        }
    }
}

.slider {
    opacity: 0;
    transition: opacity 1s ease;
    visibility: hidden;
}
.slider.slick-initialized {
    opacity: 1;
    visibility: visible;
}
.slick-slide {
     margin-bottom: 1em;
     overflow: hidden;
    .image {
        text-align: center;
        img {
            width: 100%;
            height: auto;
        }
    }
}
.slick-dots {
    text-align: center;
    li {
        display: inline-block;
        width: 12px;
        margin: 0 .2em;
        button {
            background: #999;
            text-indent: -9999px;
            border: none;
            border-radius: 12px;
            display: block;
            height: 12px;
            width: 12px;
            -webkit-appearance: none;
            cursor: pointer;
            padding: 0;

            &:focus {
                outline: none;
            }
        }
        &.slick-active {
            button {
                @include var(background, color, primary);
            }
        }
    }
}

.product-information .social-sharing {
    margin-top: 1.5rem;
}

.product-flags {
    margin-bottom: .4em;
}

.product-tabs {
    margin-top: 1.5rem;
    border-style:solid;
    border-width: 1px;
    @include var(border-color, border, color);
    .card {
        border: none;
        border-radius: 0;
    }
    .card-header {
        background: none;
    }
    .card-body, .card {
        background: none;
    }
    .tab-pane {
        padding-top: 0;
        padding-bottom: 0;
    }
    .nav-tabs > .nav-item a {
        font-size:0.875em;
        text-transform: uppercase;
    }
}

.product-customization {
    margin-top: 1.5rem;
  .file-input {
    width: 100%;
    opacity: 0;
    left: 0;
    z-index: 1;
    cursor: pointer;
    height: 2.625rem;
    overflow: hidden;
    position: absolute;
  }
  .custom-file {
    position: relative;
    width: 100%;
    height: 2.625rem;
    line-height: 2.625rem;
    text-indent: 0.625rem;
    display: block;
    button {
      z-index: 0;
      position: absolute;
      right: 0;
      top: 0;
    }
  }
}


.tabs-to-accordion {
    .active {
        display: none !important
    }
    .collapse {
        display: none !important
    }
    .collapse.show {
        display: block !important;
    }
}



.product-actions {
    .classic-variants-mode {
      .radio-group {
        > li {
          float: none;
          label {
            line-height: inherit;
            padding-left: 30px;
            cursor: pointer;
            font-weight: normal;
            
            .input-container input[type=radio] {
              position: absolute;
              opacity: 0;
              cursor: pointer;
              height: 0;
            }

            .checkmark {
              position: absolute;
              top: 0;
              left: 0;
              height: 20px;
              width: 20px;
              background-color: #eee;
              border-radius: 50%;
            }

            &:hover input[type=radio] ~ .checkmark {
              background-color: #ccc;
            }

            & input[type=radio]:checked ~ .checkmark {
              background-color: #333;
            }

            & .checkmark:after {
              content: "";
              position: absolute;
              display: none;
            }

            & input[type=radio]:checked ~ .checkmark:after {
              display: block;
            }

            & .checkmark:after {
              top: 7px;
              left: 7px;
              width: 6px;
              height: 6px;
              border-radius: 50%;
              background: white;
            }
            span { border: 0; }
          }
        }
      }
    }
    .radio-group {
        overflow: hidden;
        margin-bottom: 0;
        
        > li {
            float: left;
            position: relative;
            margin-right: .5rem;
            margin-bottom: .5rem;
        }
        input[type=radio] {
            position: absolute;
            top: 0;
            cursor: pointer;
            opacity: 0;
            left: -9999px;
        }
        li > label {
            margin: 0;
            line-height: 1;
            cursor: pointer;
            display: block;

            > span {
                border: 1px solid #eee;
                display: inline-block;
                height: 3rem;
                width: 3rem;
                line-height: 3rem;
                text-align: center;
                font-size: 0.875em;
                background: #fff;

            }
        }
        input[type=radio]:checked ~ span {
            background-color: #000;
            color: #fff;
        }
        .kaliber-rounded > label > span {
            border-radius: 50%;
        }
    }
    .color-variants {
        border: none;
        padding: 1px;
        li {
            margin-right: .5rem;
            margin-bottom: .5rem;
        }
        li > label {
            > span {
                border: none;
                height: 2rem;
                width: 2rem;
                display: inline-block;
                border-radius: 50%;
                line-height: 1;
                background-size: 2rem 2rem;
                box-shadow: inset 0 0 1px rgba(0,0,0,0.2);

            }
        }
        input[type=radio]:checked ~ span {
            box-shadow:inset 0 0 0 2px rgba(0, 0, 0, .5);
        }
    }
}

#product {
    .social-sharing {
        @include media-breakpoint-up(md) {
            display: block;
        }
    }
}

.js-response {
    > span {
        display: block;
        padding: 1em .5em;
        background: #eee;
        margin-top: .5em;
        font-size: 0.875em;
        text-align: center;
    }
    &.error {
        > span {
            border: 2px solid $danger;
            color: $danger;
            background: none;
        }
    }
    &.success {
        > span {
            margin-top: 0;
            border: 2px solid $success;
            color: $success;
            background: none;
        }
    }
}

.product-avalibility {
    font-size: .875em;
    text-align: center;
    padding: .5rem;
    font-weight: bold;
    text-transform: uppercase;
    span {
        vertical-align: middle;
    }
    &.last-items {
        color: #ffc107;
    }
    &.unavailable {
        color: #000;
    }
}

.delivery-information {
  vertical-align: middle;
}

.delivery-information-status {
  font-size: .875em;
  text-align: center;
}

.product-detail {
    margin-bottom: .5rem;
    label {
        font-weight: bold;
        margin-bottom: 0;
    }
}
.product-features {
    margin-top: 1rem;
}
.list-group-item {
    border-color: #eee;
}


.feature-table {
    dt, dd {
        margin-right: 0;
        padding: .5rem 1rem;
        background: #f7f7f7;
        display: inline-table;
        width: 49%;
    }
    dd {
        margin-right: 0;
    }
}


/**
 * Remember to adjust the CSS according to the theme. This does not
 * override the classic theme. This is the essentials you need for
 * this to work. Adjust accordingly.
 */
.js-qv-mask.mask {
    overflow: hidden;
    position: relative;
}
.product-images {
    font-size: 0;
    list-style: none;
    padding: 0;
    z-index: 1;
	margin-bottom: 0;

    .thumb-container {
        padding-bottom: 0.5rem;
		&:last-child { padding-bottom: 0; }
    }

    &.is-expanded {
        .thumb-container {
            display: inline-block;
        }
    }
}
.modal {
    .product-images {
        &.is-expanded {
            .thumb-container {
                width: 100%;
            }
        }
    }
}
.thumb-container {
    cursor: pointer;
    display: inline-block;
    width: 100%;
}
.thumb-container.is-hidden {
    display: none;
}
.thumb-container img {
    display: block;
    height: auto;
    width: 100%;
}
.thumb-container.show-all-images span {
    display:block;
    font-size: 14px;
    font-weight: 700;
    height:0;
    line-height:0;
    position: relative;
    width:100%;
}
.show-all-images span:before {
    content: attr(data-content-title);
    line-height: normal;
    text-align: center;
    position: absolute;
    top: calc(50% + 0.5em);
    right: 0;
    bottom: 0;
    left: 0;
}
.show-all-images .icon {
    position: absolute;
    top: calc(50% - 0.7em);
    text-align: center;
    width: 100%;
    left: 0;
}

#product-modal .modal-dialog {
    max-width: 800px;
}
#product-modal {
    figure {
        margin-bottom: 1rem;
    }
}

.table-product-discounts {
  width: 100%;
    thead > tr {
      background: #cccccc;
      color: #000000;
      
      th {
        padding: 10px;
        text-transform: uppercase;
      }
    }
    tbody {
      border-right: 1px solid #EEEEEE;
      border-left: 1px solid #EEEEEE;
      border-bottom: 1px solid #EEEEEE;
      
      tr {
        border-bottom: 1px solid #EEEEEE;
        
        td {
          padding: 5px 10px;
        }
        &:last-child {
          border-bottom: 0;
        }
      }
    }
}

.--updating#product-list, .--updating .content-box {
    filter: blur(2px);
}

.highlighted-informations {
  position: absolute;
  bottom: 0;
  left: 0;
  padding-left: 10px; }