.lang-rtl {
  * {
    direction: rtl !important;
  }
  main {
    text-align: right !important;
  }
}
html {
  box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
}

body,
html {
  height: 100%;
}

ul {
  list-style: none;
  padding-left: 0;
}

.dropdown-item:focus,
.dropdown-item:hover {
  background: none;
}

.container {
    margin: 0 auto;
    padding-left: 15px;
    padding-right: 15px;
    width: auto;
    max-width: 1440px;
    
}

img {
    max-width: 100%;
    height: auto;
}
.icon{
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    display: inline-block;
    vertical-align: middle;
    width: 1em;
    height: 1em;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
    text-rendering: optimizeLegibility;
    font-feature-settings: 'liga';
}

:focus {outline:none;}

.touchspin-up {
  &:after {
    content: "\E5CE";
  }
}
.touchspin-down {
  &:after {
    content: "\E5CF";
  }
}

.hidden {
  display: none;
}
.content-box {
  @include var(background, content, background);
  &.last {
    padding: 0;
  }
  &.no-bg {
    background: none;
  }
}

.layout-boxed {
  .content-box {
    padding: 1.5rem;
    margin-top: 1.5rem;
  }
  .product_right_column .content-box {
    margin-top: 0;
    padding: 0;
  }
}

@include media-breakpoint-up(xl) {
  .container {
    max-width: 1440px;
  }
}

.bright {
  color: #fff;
}

.section {
    padding: 4em 0;

}

main > .page-content{
    padding: 0 0 1.5rem 0;

}

main > .page-header {
    margin-bottom: 3rem;
}

.--updating {
    &::before {
        content:'';
        box-sizing: border-box;
        position: fixed;
        top: 50%;
        left: 50%;
        width: 48px;
        height: 48px;
        margin-top: -24px;
        margin-left: -24px;
        z-index: 9999;
        border-radius: 50%;
        background:rgba(0, 0, 0, .05);
        opacity: 0;
        animation: fadeIn2 500ms cubic-bezier(0.075, 0.82, 0.165, 1) forwards;
        transform: scale(0);
    }
    &:after {
        content: '';
        box-sizing: border-box;
        position: fixed;
        top: 50%;
        left: 50%;
        width:  36px;
        height:  36px;
        margin-top: -18px;
        margin-left: -18px;
        border: 2px solid rgba(0, 0, 0, .7);
        animation: spinner 800ms linear infinite;
        z-index: 10000;
        border-radius: 50%;
        border-top-color: rgba(0, 0, 0, .3);
        
    }

    &.--animout {
      &::before {
        opacity: 1;
        animation: fadeOut 300ms linear forwards;
      }
    }
}

.product-miniature {
  .add-to-cart.btn-text.--updating {
    span:first-child {
      float: left;
    }
    &::before {
      display: none;
    }
    &::after {
      position: static;
      top: 0;
      left: 0;
      float: right;
      width: 16px;
      height: 16px;
      margin-top: .2rem;
      margin-left: .5rem;
      border-top-color:transparent;
    }
  }
}

.product-add-to-cart .add-to-cart.--updating, .search-results.--updating, .product-miniature .add-to-cart.--updating {
  position: relative;
  &::before {
    position: absolute;
    width:  30px;
    height:  30px;
    margin-top: -15px;
    margin-left: -15px;
    top: 50%;
    display:none;
    
  }
  &::after {
    top: 50%;
    width: 24px;
    height: 24px;
    margin-top: -12px;
    margin-left: -12px;
    position: absolute;
    border-top-color:transparent;
  }
  span {
    opacity: 0;
  }
}

.product-miniature .add-to-cart.btn-primary.--updating, .product-miniature .add-to-cart.btn-secondary.--updating {
&::after {
  border-color: #fff;
  border-top-color:transparent;
}
}

 .search-results.--updating  {
   &::after {
     position: absolute;
     border-color: #ccc;
     border-top-color: #333;
   }
 }


@keyframes fadeIn2 {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(1);
  }
}


@keyframes spinner {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

.full-width {
  display: flex;
  justify-content: center;
  margin-right: calc(50% - 50vw);
  margin-left: calc(50% - 50vw);
}
.full-width-inner {
  width: 100vw;
}

.product-name {
    font-weight: bold;
    margin-bottom: 0;
}
.modal-backdrop {
  background-color: rgba(0, 0, 0, .2);
}
.modal-backdrop.show {
  opacity: 1;
}

.overlay {
    background: rgba(0, 0, 0, .2);
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 8;
    cursor: pointer;
    transition: 500ms opacity ease;
    opacity: 0;
    visibility: hidden;
    pointer-events:none;
    @include media-breakpoint-up(lg) {
      top: 0;
    }
}

.--cart-open, .--search-open, .--menu-open {
  
  @include media-breakpoint-down(md) {
    overflow: hidden;
  }
    .overlay {
        visibility: visible;
        opacity: 1;
        pointer-events: auto;
    }
}
.--search-open {
  .overlay {
    top: 60px;
    @include media-breakpoint-up(lg) {
      top: 0;
    }
  }
}

.cms-content {
  ul {
    list-style-type: disc;
    padding-left: 1.5em;
  }
}

.img-bg {
  .img-item {
    position: relative;
    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background: rgba(0, 0, 0, .05);
      pointer-events: none;
    }
  }
}

#authentication main, #order-confirmation main {
    .container {
        max-width: 1024px;
    }
}


.left-column {
    /*padding-top: 1.5rem;*/
    ul {
      margin-bottom:0;
        li {
          padding-right: 1em;
          display: block;
          position: relative;
            &[data-depth="1"], &[data-depth="2"], &[data-depth="3"]  {
              padding-left: 1em;
            }
          a {
            padding: .15em 0;
            display: block;
          }
          .navbar-toggler {
            position: absolute;
            right: 0;
            top: 0px;
            padding: 0;
            z-index: 2;
            .add {
              display: none;
            }
            &.collapsed {
              .add {
                display: block;
              }
              .remove {
                display: none;
              }
            }
          }
        }
    }
    .category-sub-menu ul li a {
      display: inline-block;
    }
    .block-categories .arrows[aria-expanded=true] .arrow-down {
      display: inline-block;
    }
    .block-categories .arrows .arrow-down,
    .block-categories .arrows[aria-expanded=true] .arrow-right {
      display: none;
    }
    .block-categories .arrows .arrow-down, .block-categories .arrows .arrow-right {
      font-size: .875rem;
      cursor: pointer;
      margin-left: 2px;
    }
}
.layout-boxed {
  .left-column {
    padding-top: 0;
  }
}

.layout-left-side-column {
  .page-content {
    /*margin-top: 2rem;*/
    padding-top: 15px;
  }
  .spacer {
    padding-top: 0;
  }
  
.kaliber-block {
    padding: 1.25rem;
    margin-bottom: 1.25rem;
}
}

#category {
    main > .page-header {
        margin-bottom: 0;
        }
}

.carousel-products {
  position: relative;
  -webkit-tap-highlight-color: transparent;
  tap-highlight-color: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  
  .carousel-arrows { position: relative; }
  
  button.slick-arrow {
    /*transform: translateY(-100%);*/
    position: absolute;
    background: rgba(255,255,255,.5);
    cursor: pointer;
    border-width: 0;
    height: 32px;
    width: 32px;
    z-index: 1;
    top: 0;
    
    &:focus {
      outline: 0;
    }
    
    > svg {
      position: absolute;
      left: 0;
      top: 0;
      width: 32px;
      height: 32px;
    }
  }
  
  button.slick-prev {
    left: 0;
  }
  button.slick-next {
    right: 0;
  }
  
  .slick-list {
    min-width: 100%;
      .slick-track {
        margin-left: 0;
        margin-right: 0;
      }
  }
}

.site-brands {
     .carousel-arrows button, .carousel-arrows button > svg {
       height: 32px;
       width: 32px;
     }
}