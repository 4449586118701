
#product-list {
   // margin-top: 1rem;
}
 .product-grid {
    @include make-row();
 }
    @keyframes fadeIn{
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }

.js-product-miniature {
    animation: fadeIn 250ms ease;
}

#product-list {
    position: relative;
    
    &.--updating {
        &:after, &:before {
            top: 200px;
        }
    }
}

/*.product-miniature {
    @include make-col-ready();
    @include make-col(3);
}
@include media-breakpoint-up(lg) {
    .desktop-half .product-grid .product-miniature {
        @include make-col(6);
    }
    .desktop-third .product-grid .product-miniature {
        @include make-col(4);
    }
    .desktop-fourth .product-grid .product-miniature {
        @include make-col(3);
    }
    .desktop-sixth .product-grid .product-miniature {
        @include make-col(2);
    }
    .desktop-full .product-grid .product-miniature {
        @include make-col(12);
    }
}

@include media-breakpoint-down(md) {
    .tablet-half .product-grid .product-miniature {
        @include make-col(6);
    }
    .tablet-third .product-grid .product-miniature {
        @include make-col(4);
    }
    .tablet-fourth .product-grid .product-miniature {
        @include make-col(3);
    }
    .tablet-sixth .product-grid .product-miniature {
        @include make-col(2);
    }
    .tablet-full .product-grid .product-miniature {
        @include make-col(12);
    }
}



@include media-breakpoint-down(sm) {
    .mobile-half .product-grid .product-miniature {
        @include make-col(6);
    }
    .mobile-third .product-grid .product-miniature {
        @include make-col(4);
    }
    .mobile-fourth .product-grid .product-miniature {
        @include make-col(3);
    }
    .mobile-sixth .product-grid .product-miniature {
        @include make-col(2);
    }
    .mobile-full .product-grid .product-miniature {
        @include make-col(12);
    }
}*/
