.site-header {
    position: fixed;
    left: 0;
    width: 100%;
    top: 0;
    z-index: 55;
    transition: 300ms ease all;
    backface-visibility: hidden;
    
  
    
    @include media-breakpoint-up(lg) {
        position: relative;
    }

}

.header-top-alt {
    @include var(background, header-nav, background);
    @include var(color, header-nav, color);
    padding: 8px 0px;
    font-size: 0.875rem;
}

.header-top {
    width: 100%;
    @include var(background, header-nav, background);
    @include var(color, header-nav, color);
    display: none;
    font-size: 0.875rem;

    @include media-breakpoint-up(lg) {
        display: block;
    }

    .row > div {
        padding-left: 1rem;
    }

    a, button {
        text-transform: uppercase;
        padding: .7rem 0;

        display: block;
    }
    select {
        border: none;
        &:focus {
            outline: none;
        }
    }
    .dropdown-menu {
        margin-top: -10px;
    }
    .linklist {
        ul {
            margin: 0;
            li {
                display: inline;
                margin-right: .5em;
            }
        }
    }

}

.header-main {
    @include var(background, header, background);
    @include var(border-bottom, header, border-bottom);
    .header-icon {
        @include var(color, header, icon);
    }
    .widget-button {
        height: 26px;
    }
}
.header-main-inner {
    position: relative;
    height: 60px;
    padding: 1rem 0;

    @include media-breakpoint-up(lg) {
        height: auto;
        padding: 0;
    }
}


.header-logo {
    @include media-breakpoint-down(md) {
        position: absolute;
        left: 0;
        width: 100%;
        text-align: center;
        img {
            height: 24px;
        }
    }
    @media (min-width: 992px) {
        padding: 15px;
    }
    a {
        display: inline-block;
        position: relative;
        z-index: 1;
    }
}
.header-menu {
    display: none;
    @include media-breakpoint-up(lg) {
        display: block;
    }
    @media (min-width: 992px) {
        align-self: stretch;
    }
}

.cart-preview .header-icon {
    vertical-align: text-bottom;
}

.header-right {
    > .row {
        margin-right: 0;
        margin-left: -.7em;
      > .col,
      > [class*="col-"] {
        padding-right: 0;
        padding-left: .4em;
        @include media-breakpoint-up(md) {
            padding-left: 1em;
        }
      }    
    }

}

.sticky-shadow .sticky-wrapper.is-sticky .stick {
    box-shadow: 0 2px 3px rgba(0, 0, 0, .08);
}

.header-top-action {
    color: #fff;
}

.hamburger {
    height: 20px;
    width: 24px;
    position: relative;
    display: block;
    cursor: pointer;
    overflow: hidden;
    > span {
        height: 2px;
        width: 100%;
        @include var(background, header, icon);
        display: block;
        position: absolute;
        top: 2px;
        left: 0;
        transition: 200ms transform ease-in-out;
        opacity: 1;

        &:nth-child(2) {
            top: 10px;
        }
        &:last-child {
            top: 18px;
        }
    }
    &:hover {
        span {
            @include var(background, header, icon-change);
        }
    }
    &.active {
        span {
            @include var(background, header, icon-change);
            &:first-child {
                top: 10px;
                transform: rotate(-45deg);

            }
            &:nth-child(2) {
                transform: translateX(-100%);
                opacity: 0;
            }
            &:last-child {
                top: 10px;
                transform: rotate(45deg);
            }         
        }
    }
}



// Different layouts for the header

// Standard
.col-fill {
    display: none;
}
.header-right {
    @include make-col-ready();
    @include make-col(12);
    margin-left: auto;
}

@include media-breakpoint-up(lg) {
    .header-logo {
        @include make-col-ready();
        @include make-col(2);
    }
    .header-menu {
        @include make-col-ready();
    }
    .header-right {
        @include make-col-ready();
        @include make-col(2);
    }
}


@include media-breakpoint-up(lg) {
    .centered-header {
        .header-main-inner {
            height: auto;
            justify-content:center;
        }
        .col-fill {
            display: block;
            order: 1;
            @include make-col-ready();
            @include make-col(5);
            .header-top {
                background: none;
            }
            .header-top .row > div {
                padding-left: 0;
                padding-right: 1rem;
            }
        }
        #header > .header-top {
            display: none;
        }

        .header-logo {
            @include make-col-ready();
            @include make-col(2);
            order: 2;
            text-align: center;
        }
        .header-right {
            @include make-col-ready();
            @include make-col(5);
            order: 3;
            margin-left: 0;
        }
        .header-menu {
            @include make-col-ready();
            @include make-col(12);
            order: 4;
        }
    }
}

@include media-breakpoint-up(lg) {
    .logo-left-menu-left {
        .header-main-inner {
            padding-top: 1rem;
        }
        .header-menu {
            @include make-col-ready();
            @include make-col(12);
            order: 4;
        }
        .menu {
            text-align: left;
        }
    }
}

.is-sticky {
    @include media-breakpoint-up(lg) {
        > div {
            backface-visibility:hidden;
            position: fixed;
            top: 0;
            width: 100%;
            left: 0;
        }
    }
}