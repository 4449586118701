.slideshow-narrow {
    .home-slideshow {
        @include make-container();
        margin-top: 2rem;
        max-width: 1440px;

    }
}
.slideshow-text-centered {
    .text-container {
        justify-content: center;
    }
    .home-slideshow .slide {
        text-align: center;
    }
}
.home-slideshow {
    position: relative;
    overflow: hidden;
    z-index: 5;
    margin-bottom: 2rem;

    .slide {
        position: relative;
        font-size: 1em;
        @include media-breakpoint-up(md) {
            font-size: 1.2em;    
        }
        @include media-breakpoint-up(lg) {
           font-size: 1.7em; 
        }
        @include media-breakpoint-up(xl) {
           font-size: 2em; 
        }
        .container {
            width: 100%;
        }
        a > .content {
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            right: 0;
            display: flex;
            padding: 3em 0;
            width: 100%;
            color: #fff;
            opacity: 0;
            transition: 600ms all cubic-bezier(.64,.2,.43,.88);
        }
        p {
            font-size: .875em;
            @include media-breakpoint-up(lg) {
            font-size: .750em; 
            }
        }
        h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
            color: #fff;
            line-height: 1;
            margin-bottom: 1rem;

        }
        img {
            width: 200%;
            max-width: none;
            height: auto;
            margin-left: -100%;
            @include media-breakpoint-up(lg) {
                width: 100%;
                margin-left: 0;
            }

        }

        .text-content {
            @include make-col-ready();
            @include make-col(10);
            @include make-col-offset(1);

            @include media-breakpoint-up(md) {
                @include make-col(6);
            }
            @include media-breakpoint-up(xl) {
                @include make-col-offset(0);
                @include make-col(8);
                padding: 0 5rem;
            }
           
        }

        &.homeslider1_on {
            a > .content {
                opacity: 1;
                //transform: translate3d(0, -50%, 0);
            }
        }
    }
}
.slides-control {
    span {
        display: block;
        height: 20px;
        width: 20px;
        background: black;
    }
}

.homeslider_tabs {
    padding-top: 1em;
    text-align: center;
    margin: 0;
    li {
        display: inline-block;
        width: 12px;
        margin: 0 .2em;
        > a {
            background: #999;
            text-indent: -9999px;
            border: none;
            border-radius: 50%;
            display: block;
            height: 12px;
            width: 12px;
            &:focus {
                outline: none;
            }
        }
        &.homeslider_here {
            a {
                background: $primary;
            }
        }
    }
}

@include media-breakpoint-up(md) {
    .homeslider_tabs {
        position: absolute;
        top: 50%;
        right: 2em;
        transform: translateY(-50%);
        z-index: 44;


        li {
            display: block;
        }
        > li > a {
            display: block;
            min-width: 20px;
            border-bottom: 2px solid #fff;
            text-align: right;
            float: right;
            color: #fff;
            font-size: 1.143em;
            transition: 300ms all ease-in-out;
            margin-bottom: .5em;
            text-decoration: none;
            background: none !important;
            border-radius: 0;
            height: auto;
            width: auto;
            
            &:hover {
                min-width: 30px;
                color: $primary;
                border-color: currentColor;
            }
            &:focus, &:active {
                outline: none;
                text-decoration: none;
            }
        }
        .homeslider_here {
            a {
                background: none;
                min-width: 30px;
                color: $primary;
                border-color: currentColor;
            }
        }
    }  
}

