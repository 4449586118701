.user-info {
    span {
        display: inline-block;
    }
    .icon {
        @include media-breakpoint-up(lg) {
            font-size:18px;
        }
    }

}

