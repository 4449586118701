.pagination {
    width: 100%;
    display: block;


    .icon {
        font-size: 18px;
    }

    .pagination-links { 
        text-align: right;
        > ul {
        display: inline-block;
        margin-bottom: 0;
            > li {
                float: left;
                a {
                    display: block;
                    padding: .5em 1em;
                }
                &:not(.current) {
                    .disabled {
                        opacity: .5;
                        pointer-events:none;
                    }
                }
                &.current {
                    a {
                        font-weight: bold;
                    }
                    .disabled {
                        pointer-events:none;
                    }
                }
            }
        }
    }
}
.pagination-mobile {
    position: relative;
    text-align: center;
    padding: .8em 0;
    display: block;
    > a {
        position: absolute;
        display: block;
        top: 0;
        padding: .7em 1em;
          box-shadow: 
            1px 0 0 0 #eee, 
            0 1px 0 0 #eee, 
            1px 1px 0 0 #eee,   /* Just to fix the corner */
            1px 0 0 0 #eee inset, 
            0 1px 0 0 #eee inset;
        &.left {
            left: 0;
        }
        &.right {
            right: 0;
        }
        &.disabled {
            pointer-events:none;
            opacity: .5;
        }
    }
}