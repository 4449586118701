.form-control {
    border: 1px solid #eee;
    color: initial;
    border-radius: 0;
    background: #fff;
    color: #000;

    &::-webkit-input-placeholder {
        color: #999;
    }
    &:focus {
        outline: none;
        border-color: #666;
        box-shadow: none;
        background: #fff;
    }
}
select.form-control:not([size]):not([multiple]) {
    height: auto;
}
input[type=text], input[type=email], input[type=password], select, .form-control {
    width: 100%;
    border: 1px solid #eee;
    padding: .8rem .7rem;
    transition: 200ms ease all;
    border-radius: 0;
    font-size: 1rem;

    &:focus {
        background: #fff;
        outline: none;
        border-color: #666;
        box-shadow: none;
    }
    &.not-empty {
        border-color: $primary;
    }
}
textarea, textarea.form-control {
    width: 100%;
    border: 1px solid #eee;
    padding: 1em;
    transition: 200ms ease all;
}
label {
    &.required {
        &::after {
            content: "*";
            display: inline-block;
            color: $sale;
        }
    }
}

.option-input-label  {
    position: relative;
    padding-left: 2.3em;
    line-height: 1.6rem;

    &::before {
        content: "";
        display: inline-block;
        height: 1.6rem;
        width: 1.6rem;
        border: 2px solid #ccc;
        position: absolute;
        left: 0;
        top: 0;
        border-radius: 2px;
        font-size: 0;
        line-height: 1;
        transition: 200ms ease all;
    }

    &:hover {
        &::before {
            background: #f5f5f5;
            border-color: #999;
        }
    }
    &.radio {
        &::before {
            border-radius: 100%;
            content: "";
        }
    }

    a {
        text-decoration: underline;
    }
}

.option-input {
    display: none !important;
    height: 0;
    width: 0;
    &:checked + label {
        &::before {
            border-color: #3e3e3e;
            content: "\e83f";
            font-family: 'feather' !important;
            font-size: 1.3rem;
        }
    }
    &.radio {
        &:checked + label {
            &::before {
                content: "";
                background: #3e3e3e;
                box-shadow: inset 0 0 0 4px #ffffff;
            }
        }
    }
}


.group-span-filestyle {
    label {
        margin-bottom: 0;
    }
}