
.social-sharing {
    > span {
        display: block;
        margin-bottom: .5em;
    }
    > ul {
        margin: 0;
        padding: 0;
        li {
            margin-right: 10px;
            display: inline-block;

            > a {
                display: block;
                border: 1px solid #eee;
                padding: .7rem;
                border-radius: 50%;
            }
        }
    }
    a {
        &:hover {
            .social-icon {
                fill: #000;
            }
        }
    }
    .social-icon {
        height: 16px;
        width: 16px;
        fill : #999;
    }
}
