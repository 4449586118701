 .listing-text-center {
    .product-miniature {
        text-align: center;
    }
 }
.product-miniature {
    position: relative;
    //background: var(--product-miniature-background);
    //padding: var(--product-miniature-padding);
    margin-bottom: 2rem;

    .thumbnail-container {
        position: relative;
        margin-bottom: 1rem;
        height: auto !important;
        overflow: hidden;
    }

    .thumbnail {
        display: block;
        img {
            width: 100%;
            height: auto;
            display: block;
        }
       .thumbnail-overlay {
            background-color: rgba(0,0,0,0.05);
            position: absolute;
            top: 0;
            right: 0;
            bottom: 5px;
            left: 0;
            z-index: 1;
        }
    }
    .product-name {
        display: block;
        margin-bottom: .3rem;
    }

    .product-flags {
        position: absolute;
        top: 10px;
        right: 10px;
        margin: 0;
    }
    .product-price {
        font-size: 1em;
    }
    .variant-links {
        a.color {
            text-indent: -9999px;
            display: inline-block;
            height: 1rem;
            width: 1rem;
            border-radius: 50%;
            background-size: 1rem;
            box-shadow: inset 0 0 1px rgba(0,0,0,0.2);
        }
    }

    .product-list-actions {
        margin-top: .5rem;
        form {
            display: inline;
        }
    }
    .quick-view {
        position: absolute;
        bottom: 0;
        right: 0;
        background: rgba(255, 255, 255, .6);
        padding: 1em;
        font-weight: bold;
        font-size: .750em;
        text-transform: uppercase;
        display: none;
        color: #000;

    }
    
    .add-to-cart {
        .mini {
            .icon {
                font-size: 16px;
            }
        }
    }
    .variant-links {
        margin-top: .3rem;
    }
    &:hover {
        .quick-view {
            display: block;
        }
    }

}

.product_right_column {
  .quick-view {
    i {
      font-size: 12px;
    }
    span { display: none; }
  }
  .variant-links a.color {
    width: .5rem;
    height: .5rem;
  }
}


.hide-quickview {
    .quick-view {
        display: none !important;
    }
}
.hide-add {
    .product-miniature {
        .product-list-actions {
            display: none;
        }
    }
}
.hide-variants {
    .highlighted-informations {
        display: none;
    }
}