.product-flag {
    background: #fff;
    padding: .1em .7em .15em .7em;
    font-size: 0.875em;
    z-index: 3;
    display: inline-block;
    color: #000;

    &.new {
        background: #000;
        color: #fff;
    }
    &.on-sale {
        color: #fff;
        background: $sale;
    }
    &.discount {
        background: $sale;
        color: #fff;
    }
    @include media-breakpoint-down(md) {
        font-size: 0.750em;
    }
}