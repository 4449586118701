
body.--menu-open {
    .off-canvas-menu {
        transform: translateY(0);
        pointer-events:auto;
        
        overflow-y: auto;
        box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.07);
    }

}

.off-canvas-menu {
    background: #F5F5F5;
    position: fixed;
    z-index: 60;
    width: 80vw;
    transition: 500ms all cubic-bezier(0.19, 1, 0.22, 1);
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0);
    transform: translateX(-100%);
    border-top: 1px solid #eee;
    overflow: auto;
    overflow: hidden;
    top: 0;
    pointer-events:none;
    height: calc(100vh);

    @include media-breakpoint-up(lg) {
        display: none;
    }

    .icon {
        display: inline-block;
        position: relative;
        transition: 100ms transform ease-in-out;

    }

    #menu-close {
        position: absolute;
        top: 1rem;
        left: .8rem;
        cursor: pointer;
        z-index: 3;
    }


    li.open {
        > a {
            color: $primary;
            > .icon {
                transform: rotate(-180deg);
            }
        }
    }
    > ul {
        margin:4rem 0 1rem 0;
        > li {
            > a {
                display: block;
                padding: .6rem 1rem;
                position: relative;
                font-size: 1em;

                >.icon {
                    float: right;
                }
                &:hover {
                    color: $primary;
                }
            }
           > ul {
                display: none;
                > li {
                    > a {
                        display: block;
                        padding: 0.3em 1em 0.3em 3em;
                        .icon {
                            font-size: 16px;
                        }
                    }
                    > ul {
                        display: none;
                        > li {
                            > a {
                                display: block;
                                padding: .3em 1em .3em 5em;
                            }
                            > ul {
                                display: none;
                            }
                        }
                    }
                }
            }

            &:last-child {
                a {
                    border-bottom: none;
                }
            }   
        }
    }
}

.shop-options {
    padding: 1rem;
}

.shop-options {
    button {
        padding: .5rem 1rem;
        background: #fff;
        width: 100%;
        text-align: left;
        border: 1px solid #eee;
        line-height: 24px;
        span {
            float: left;
        }
        .button-label {
            margin-right: 1rem;
        }
    }
    .dropdown-menu {
        position: static !important;
        top: 0;
        left: 0;
        width: 100%;
        transform: translate3d(0, 0, 0) !important;
        border: none;
        padding: .3em 0;
    }
    a {
        padding: .3em 1em;
    }
    .icon {
        float: right;
    }
    .shop-option {
        margin-bottom: .5rem;
        &:last-child {
            border-bottom: 0;
        }
        &.show {
            button {
                border-bottom: 1px solid #eee;
            }
        }
        > a {
            display: block;
            padding: 1rem .1rem;
        }
    }
}