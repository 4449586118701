.qty {
    font-size: .875em;
    .js-cart-line-product-quantity, #quantity_wanted  {
        text-align: center;
        height: 3.3em;
        margin: 0;
        width: calc(2.5em + 5em);
        padding: 0;
        display: block;
        box-sizing: content-box;
        font-size: 1em;
    }
    &.qty-small {
        font-size: .750em;
    }
}

.bootstrap-touchspin {
    position: relative;
    padding: 0 2.5em;
    border: 1px solid #eee;
    height: 3.3em;
    box-sizing: content-box;
    width: 2.5em;

    #quantity_wanted {
        border: none !important;
    }
    .js-cart-line-product-quantity, #quantity_wanted {
        width: 2.5em;
        border: none;
        font-size: 1em;
    }
    .input-group-btn-vertical {
        display: block;
        width: auto;
        position: static;
    }
    .btn.btn-touchspin {
        position: absolute;
        background: none;
        width: 2.5em;
        height: 3.3em;
        text-align: center;
        padding: 0;
        line-height: calc(1em + 0.7em);
        border: none;
        margin: 0;
        font-size: 1em;
    }

    .btn.bootstrap-touchspin-down {
        left: 0;
        right: auto;
    }
    .btn.bootstrap-touchspin-up {
        right: 0;
        left: auto;
    }
    .input-group-btn-vertical i {
        position:static;
        font-size: 12px;
        font-weight: 400;
        @include var(color, link,color);
    }
}



