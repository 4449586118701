.subcategories {
    margin-top: 1.5rem;
    @include media-breakpoint-up(lg) {
        margin-top: 0;
    }
    ul {
        margin-left: 0;

        > li {
            margin-bottom: .3rem;
            page-break-inside: avoid;

            > a {
                padding: .5rem 0;
                .icon {
                    font-size: 18px;
                }
                span {
                    vertical-align: middle;
                }
            }
            .center-sub {
                text-align: center;
            }
        }
        &.show-images, &.show-desc {
            > li {
                padding-bottom: .5rem;
                border-bottom: 1px solid #eee;
                margin-bottom: .5rem;
                 a {

                }
                .category-title {
                    font-weight: bold;
                }
                .icon {
                    display: none;
                }
                .category-description {
                    *:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}

.subcategory-box {
  ul {
    margin-left: -3px;
    margin-right: -3px;
    &.show-images {
      > li { border-width:0; }
    }
    li {
      padding-left: 3px;
      padding-right: 3px;
      .align-items-center {
        padding-left: 3px;
        padding-right: 3px;
        margin-left: -3px;
        margin-right: -3px;
      }
        .center-sub:first-child {
          border-style: solid;
          border-width: 1px;
        }
    }
  }
    // ul.show-images > li {
        // border-width: 0;
        
        // > div:first-child {
            // padding: 0px 3px;
            
            // > .center-sub:first-child {
                // border-style: solid;
                // border-width: 1px;
            // }
        // }
    // }
}

#category-description {
    *:last-child {
        margin-bottom: 0;
    }
}

.back-to-top > a {
    display: block;
    background: #eee;
    padding: 1em;
    text-align: center;
    font-size: 0.857em;
    margin-top: 1em;
}
.category-header {
    #category-description {
        font-size: 1.250em;
    }
}
.product-list-bottom {
    .content-box {
        padding-top: 1em;
        padding-bottom: 1em;
        //border-top: 1px solid #eee;
    }
}

.cover-narrow {
    .category-cover {
        @include make-container();
        margin-top: 2rem;
        max-width: 1440px;

    }
}
.category-cover > div {
    min-height: 160px;
    height: 20vh;
    background-position: center;
    background-size: cover;

    @include media-breakpoint-up(lg) {
        height: 300px;
    }
}

