.layout-boxed {
     #search_filters {
        border-bottom: none;
        padding: 1.5rem;
        padding-bottom: .5rem;
    }
    .filter-sidebar .dropdown-menu {
        background: none;
    }

.facet {
    margin-top: 0;
}
}
body:not(.layout-boxed) {
    #search_filters {
        @include var(border-bottom-style, border, style);
        @include var(border-bottom-width, border, thickness);
        @include var(border-bottom-color, border, color);
    }
}
#search_filters {
    //background: #f5f5f5;
    //padding: 1rem 0;
    margin-bottom: 1rem;
    
}

#search_filters .card-header a:not(.collapsed) .icon{
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
}

#search_filters .card-header .icon {
    transition: 100ms -webkit-transform ease-in-out;
    transition: 100ms transform ease-in-out;
    transition: 100ms transform ease-in-out, 100ms -webkit-transform ease-in-out;
}

.horizontal-filter {
    @include media-breakpoint-up(lg) {
        .card-header { display: none; }
    }
    @include media-breakpoint-down(lg) {
        #collapse-faceted {  }
    }
    .facets { padding: 0; }
}

.filter_fullwidth {
    .filter-list { margin-right: 0; }
    .filter-list > li {
        margin-bottom: .5rem;
        margin-left: 0;
        margin-right: .5rem;
    }
    .reset-filters {
        display: block;
        float: none;
        width: 100%;
        text-align: center;
        background: rgba(0, 0, 0, 0.03);
        border: 1px solid rgba(0, 0, 0, 0.125);
        border-radius: 5rem;
        padding: 0.15rem 0.3rem;
    }
}

.filter_left_column {
    .js-search-link { display: inline;}
    .filter-list { margin-right: 0; }
    .filter-list > li {
        margin-bottom: .5rem;
        margin-left: 0;
        margin-right: .5rem;
    }
    .card-header { background-color: rgba(0, 0, 0, 0.03); }
    .reset-filters {
        display: block;
        float: none;
        width: 100%;
        text-align: center;
        background: rgba(0, 0, 0, 0.01);
        border: 1px solid rgba(0, 0, 0, 0.125);
        border-radius: 5rem;
        padding: 0.35rem 0.3rem;
        margin-bottom: 0.75rem;
    }
}

.left-column {
    @include media-breakpoint-up(lg) {
        .card-header { display: none; }
    }
    @include media-breakpoint-down(lg) {
        #collapse-faceted {  }
    }
    .facets .card-header { background-color: rgba(0, 0, 0, 0.03); }
    .facets { padding: 0; }
}

.facet {
    margin-top: 1rem;
    margin-bottom: 1rem;
    margin-right: 1rem;
    @include media-breakpoint-up(lg) {
        float: left;
        position: relative;
    }
    > button {
        text-transform: uppercase;
        cursor: pointer;
    }

    .dropdown-menu {
        right: auto;
        margin: 1px 0;
        left: -1px !important;
        > ul {
            padding: .5em 0;
        }
    }

}
.active_filters {
    width: 100%;
    .filter-list {
        margin-right: .5em;
    }
    .content-box {
        padding-top: 1em;
        padding-bottom: 0;

    }
    span, .filter-list, p {
        
        @include media-breakpoint-up(lg) {
            display: inline;
        }
    }
    > span {
        font-weight: bold;
    }
    p {
        margin: 0;
        @include media-breakpoint-up(lg) {
            float: right;
        }
    }
}
.filter-list {
    margin: 0;
    > li {
        padding: 0.35rem 0.6rem;
        font-weight: normal;
        border: 1px solid #eee;
        border-radius: 5rem;
        float: left;
        margin-left: .5rem;
        line-height: 1;

        > span {
            font-size: 0.875em;
        }
        .icon {
            font-size: 14px;
        }
    }
}

.filter-option {
    > a {
        padding: .5rem 1rem .5rem 2.3rem;
        display: block;
        position: relative;
        line-height: 1.3rem;
        

        .magnitude {
            color: #999;
        }

    }
    .color {
        position: absolute;
        left: 10px;
        top: 7px;
        height: 1.3rem;
        width: 1.3rem;
        display: block;
        z-index: -1;
        border-radius: 50%;
        background-size: 1.3rem;
        box-shadow: inset 0 0 1px rgba(0,0,0,0.2);
    }

    a.has-color {
        &::before {
            display: none !important;
        }
        &.active {
            .color {
                border: 2px solid #000;
            }
        }
        &:hover {
            .color {
                box-shadow:inset 0 0 0 2px rgba(255, 255, 255, .4);
            }
        }
    }

    &.option-check {
        a {
            &::before {
                content: "";
                display: block;
                height: 1.3rem;
                width: 1.3rem;
                border: 2px solid #eee;
                position: absolute;
                left: 10px;
                top: 7px;
                border-radius: 2px;
                font-size: 1.2em;
                line-height: 17px;
                text-align: center;

            }
            &.active {
                &::before {
                    border-color: #3e3e3e;
                    content: "\e83f";
                    font-family: 'feather' !important;
                }
            }

        }
    }
    &.option-radio {
        a {
            &::before {
                content: "";
                display: block;
                height: 1.3rem;
                width: 1.3rem;
                border: 2px solid #eee;
                position: absolute;
                left: 10px;
                top: 7px;
                border-radius: 50%;
            }
            &.active {
                &::before {
                content: "";
                background: #3e3e3e;
                box-shadow: inset 0 0 0 4px #ffffff;
                }
            }
        }
        .color {
            border-radius: 100%;
        }
    }
    &:hover {
        a {
            &::before {
                border-color: #999;
            }
        }
    }
    &:last-child a {
        border-bottom: none;
    }
}

.left-column {
    .facets {
        .dropdown-menu {
            display: block;
            position: static;
            float: none;
            border: none;
            background: none;
        }
        .facet {
            margin-top: 0;
            > button {
                pointer-events:none;
                .icon {
                    display: none;
                }
            }
            @include media-breakpoint-up(lg) {
                float: none;
                position: relative;
            }
        }
        .filter-option.option-check a::before, .filter-option.option-radio a::before {
            left: 0;
            top: 2px;
        }
        .filter-option > a {
            padding: .2rem 1rem .2rem 2.1rem;
        }
        .filter-option.option-check a::before {
            top: 3px;
        }
        .filter-option .color {
            z-index: 0;
            left: 0;
            top: 3px;
        }
    }

    #search_filters {
        border-bottom: none !important;
    }
}