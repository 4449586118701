#search-trigger {
    display: inline;
    cursor: pointer;
}


.searchbar {
    .header {
        > a {
            text-decoration: none;
            position: relative;
        }
    }

    .body {
        
        background: #fff;
        position: fixed;
        right: 0;
        top: 60px;
        width: 100%;
        z-index: 20;
        visibility: hidden;
        opacity: 0;
        transform:  perspective(40cm)  scale(.9, .9) rotateX(5deg);
        transform-origin: top right;
        transition: 500ms all cubic-bezier(0.19, 1, 0.22, 1);
        pointer-events: none;
        will-change: transform;
        box-shadow: 0 2px 2px rgba(0, 0, 0, 0.07);

        #search-close {
            position: absolute;
            top: .6rem;
            right: 1rem;
            cursor: pointer;
            z-index: 3;
        }

        &.visible {
            opacity: 1;
            transform: perspective(40cm) scale(1,1) rotateX(0deg);
            visibility: visible;
            pointer-events: auto;
        }

        @include media-breakpoint-up(lg) {
            top: 150%;
            right: -25px;
            width: 35rem;
            position: absolute;

            &::after {
                content: "";
                display: block;
                width: 0; 
                height: 0; 
                border-left: 10px solid transparent;
                border-right: 10px solid transparent;
                border-bottom: 10px solid #fff;
                position: absolute;
                top: -9px;
                right: 11px;
                z-index: 2;
                @include media-breakpoint-up(lg) {
                    right: 26px;
                }

            }

        }

    }
}


.search-query {
    
    border-top: 1px solid #eee;
    position: relative;

    @include media-breakpoint-up(lg) {
        border-top: none;
    }

    .icon {
        position: absolute;
        top: 50%;
        margin-top: -12px;
        left: 10px;
    }
    input[type=text] {
        border: none;
        padding-left: 2.5em;
        font-size: 16px;
        &::-ms-clear {display: none; width:0; height:0;}
    }
}
.not-found {
    display: none;
    padding: 1em;
    .h3 {
        font-size: 1em;
    }
    p {
        margin: 0;
    }
}
.search-results {
    border-top: 1px solid #eee;



    &.has-results {
        .not-found {
            display: none;
        }
    }
    &.no-results {
        .not-found {
            display: block;
        }
    }
}
.js-search-results {
    margin: 0;
    padding:0 1rem; 
    > li {
        font-size: .875em;
        padding: .5rem 0;
        border-bottom: 1px solid #eee;
        .row {
            margin-left: -7px;
            margin-right: -7px;
            [class*="col-"] {
                padding-right: 7px;
                padding-left: 7px;
            }
        }
        &:last-child {
            border-bottom: none;
        }
        .product-description {
            > *:last-child {
                margin-bottom: 0;
            }
        }
    }
}

.js-more-results {
    border-top: 1px solid #eee;
}

#search_widget {
    padding-right: 0;
    padding-left: 0;
}