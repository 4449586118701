.nav-tabs {
    background: none;
    border-bottom-style: solid;
    @include var(border-bottom-color, border, color);
    border-bottom-width: 1px;
    padding: 0 1.3rem;
    > .nav-item {
        h2, h3, h4 {
            font-weight: normal;
            font-size: 1em;
            margin: 0;
        }
        a {
            background: none;
            padding: 0.7em 1em .5em 1em;
            color: #3e3e3e;
            border: none;
            border-radius: 0;
            &.active {
                background: none;
                font-weight: bold;
                border-bottom: 3px solid;
                @include var(border-bottom-color, link, color);
            }

        }
    }
}
.product-tabs {
        > ul {
            display: none;
            @include media-breakpoint-up(lg) {
                display: flex;
            }
        }
    
    .tab-content {
    }
}


#tabToAcc {
    .nav-tabs {
        display:none;
    }
    .card-header {
        border: none;
    }
    .tab-pane {
        border-bottom: 1px solid #eee;

        &:last-child {
            border-bottom: none;
        }
    }

    @media(min-width:992px) {
        .nav-tabs {
            display: flex;
        }
        
        .card {
            border: none;
        }

        .card .card-header {
            display:none;
        }  

        .card .collapse{
            display:block;
        }
    }

    @media(max-width:991px){
        .tab-pane {
            display: block !important;
            opacity: 1;
        }
    }

    .tab-content {
        ul {
            list-style: disc;
            list-style-position: inside;
            li {
                padding-bottom: .5em;
            }
        }
    }

}

.tab-pane {
    padding: 1em 0;
}