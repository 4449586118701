.product-price {
    margin-bottom: 0;

    &.has-discount {
        .price {
            color: $sale;
        }
        .regular-price {
            text-decoration: line-through;
            display: inline-block;
            margin-left: .2em;
            color: #888;
        }
        .aeuc_before_label, .tax {
        }
        .discount-amount, .discount-percentage {
            display: inline-block;
            margin-right: .2em;
        }
    }
}
.product-miniature {
    .product-price {
        .price {
            font-size: 1em;
        }
    }
}


