/*!
 * Bootstrap v4.0.0-beta.3 (https://getbootstrap.com)
 * Copyright 2011-2017 The Bootstrap Authors
 * Copyright 2011-2017 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
@import url(~bootstrap-touchspin/dist/jquery.bootstrap-touchspin.min.css);
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #868e96;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #868e96;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

@-ms-viewport {
  width: device-width; }

article, aside, dialog, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

dfn {
  font-style: italic; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects; }
  a:hover {
    color: #0056b3;
    text-decoration: underline; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([tabindex]):focus, a:not([href]):not([tabindex]):hover {
    color: inherit;
    text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

pre,
code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg:not(:root) {
  overflow: hidden; }

a,
area,
button,
[role="button"],
input:not([type="range"]),
label,
select,
summary,
textarea {
  touch-action: manipulation; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #868e96;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: .5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.2;
  color: inherit; }

h1, .h1 {
  font-size: 2.5rem; }

h2, .h2 {
  font-size: 2rem; }

h3, .h3 {
  font-size: 1.75rem; }

h4, .h4 {
  font-size: 1.5rem; }

h5, .h5 {
  font-size: 1.25rem; }

h6, .h6 {
  font-size: 1rem; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #868e96; }
  .blockquote-footer::before {
    content: "\2014 \00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #868e96; }

code,
kbd,
pre,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-break: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem; }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700; }

pre {
  display: block;
  font-size: 87.5%;
  color: #212529; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px; } }

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
  background-color: transparent; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6; }
  .table tbody + tbody {
    border-top: 2px solid #dee2e6; }
  .table .table {
    background-color: #fff; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #dee2e6; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.table-hover tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.075); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b8daff; }

.table-hover .table-primary:hover {
  background-color: #9fcdff; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #9fcdff; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #dddfe2; }

.table-hover .table-secondary:hover {
  background-color: #cfd2d6; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #cfd2d6; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb; }

.table-hover .table-success:hover {
  background-color: #b1dfbb; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #b1dfbb; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb; }

.table-hover .table-info:hover {
  background-color: #abdde5; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #abdde5; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba; }

.table-hover .table-warning:hover {
  background-color: #ffe8a1; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #ffe8a1; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb; }

.table-hover .table-danger:hover {
  background-color: #f1b0b7; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #f1b0b7; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe; }

.table-hover .table-light:hover {
  background-color: #ececf6; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #ececf6; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca; }

.table-hover .table-dark:hover {
  background-color: #b9bbbe; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #b9bbbe; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

.table .thead-dark th {
  color: #fff;
  background-color: #212529;
  border-color: #32383e; }

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6; }

.table-dark {
  color: #fff;
  background-color: #212529; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #32383e; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.99px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 767.99px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 991.99px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1199.99px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar; }
  .table-responsive > .table-bordered {
    border: 0; }

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .form-control::placeholder {
    color: #868e96;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

select.form-control:not([size]):not([multiple]) {
  height: calc(2.25rem + 2px); }

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  margin-bottom: 0;
  line-height: 1.5;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .input-group-sm > .form-control-plaintext.form-control,
  .input-group-sm > .input-group-prepend > .form-control-plaintext.input-group-text,
  .input-group-sm > .input-group-append > .form-control-plaintext.input-group-text,
  .input-group-sm > .input-group-prepend > .form-control-plaintext.btn,
  .input-group-sm > .input-group-append > .form-control-plaintext.btn, .form-control-plaintext.form-control-lg, .input-group-lg > .form-control-plaintext.form-control,
  .input-group-lg > .input-group-prepend > .form-control-plaintext.input-group-text,
  .input-group-lg > .input-group-append > .form-control-plaintext.input-group-text,
  .input-group-lg > .input-group-prepend > .form-control-plaintext.btn,
  .input-group-lg > .input-group-append > .form-control-plaintext.btn {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm, .input-group-sm > .form-control,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

select.form-control-sm:not([size]):not([multiple]), .input-group-sm > select.form-control:not([size]):not([multiple]),
.input-group-sm > .input-group-prepend > select.input-group-text:not([size]):not([multiple]),
.input-group-sm > .input-group-append > select.input-group-text:not([size]):not([multiple]),
.input-group-sm > .input-group-prepend > select.btn:not([size]):not([multiple]),
.input-group-sm > .input-group-append > select.btn:not([size]):not([multiple]) {
  height: calc(1.8125rem + 2px); }

.form-control-lg, .input-group-lg > .form-control,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

select.form-control-lg:not([size]):not([multiple]), .input-group-lg > select.form-control:not([size]):not([multiple]),
.input-group-lg > .input-group-prepend > select.input-group-text:not([size]):not([multiple]),
.input-group-lg > .input-group-append > select.input-group-text:not([size]):not([multiple]),
.input-group-lg > .input-group-prepend > select.btn:not([size]):not([multiple]),
.input-group-lg > .input-group-append > select.btn:not([size]):not([multiple]) {
  height: calc(2.875rem + 2px); }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input:disabled ~ .form-check-label {
    color: #868e96; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  width: 250px;
  padding: .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.8);
  border-radius: .2rem; }

.was-validated .form-control:valid, .form-control.is-valid, .was-validated
.custom-select:valid,
.custom-select.is-valid {
  border-color: #28a745; }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus, .was-validated
  .custom-select:valid:focus,
  .custom-select.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }
  .was-validated .form-control:valid ~ .valid-feedback,
  .was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback,
  .form-control.is-valid ~ .valid-tooltip, .was-validated
  .custom-select:valid ~ .valid-feedback,
  .was-validated
  .custom-select:valid ~ .valid-tooltip,
  .custom-select.is-valid ~ .valid-feedback,
  .custom-select.is-valid ~ .valid-tooltip {
    display: block; }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    background-color: #71dd8a; }

.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  background-color: #34ce57; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745; }
  .was-validated .custom-file-input:valid ~ .custom-file-label::before, .custom-file-input.is-valid ~ .custom-file-label::before {
    border-color: inherit; }

.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  width: 250px;
  padding: .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.8);
  border-radius: .2rem; }

.was-validated .form-control:invalid, .form-control.is-invalid, .was-validated
.custom-select:invalid,
.custom-select.is-invalid {
  border-color: #dc3545; }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus, .was-validated
  .custom-select:invalid:focus,
  .custom-select.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }
  .was-validated .form-control:invalid ~ .invalid-feedback,
  .was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
  .form-control.is-invalid ~ .invalid-tooltip, .was-validated
  .custom-select:invalid ~ .invalid-feedback,
  .was-validated
  .custom-select:invalid ~ .invalid-tooltip,
  .custom-select.is-invalid ~ .invalid-feedback,
  .custom-select.is-invalid ~ .invalid-tooltip {
    display: block; }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    background-color: #efa2a9; }

.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  background-color: #e4606d; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545; }
  .was-validated .custom-file-input:invalid ~ .custom-file-label::before, .custom-file-input.is-invalid ~ .custom-file-label::before {
    border-color: inherit; }

.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  .btn:focus, .btn:hover {
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .btn.disabled, .btn:disabled {
    opacity: 0.65; }
  .btn:not([disabled]):not(.disabled) {
    cursor: pointer; }
  .btn:not([disabled]):not(.disabled):active, .btn:not([disabled]):not(.disabled).active {
    background-image: none; }

a.btn.disabled,
fieldset[disabled] a.btn {
  pointer-events: none; }

.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff; }
  .btn-primary:hover {
    color: #fff;
    background-color: #0069d9;
    border-color: #0062cc; }
  .btn-primary:focus, .btn-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    background-color: #007bff;
    border-color: #007bff; }
  .btn-primary:not([disabled]):not(.disabled):active, .btn-primary:not([disabled]):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #0062cc;
    border-color: #005cbf; }
    .btn-primary:not([disabled]):not(.disabled):active:focus, .btn-primary:not([disabled]):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }

.btn-secondary {
  color: #fff;
  background-color: #868e96;
  border-color: #868e96; }
  .btn-secondary:hover {
    color: #fff;
    background-color: #727b84;
    border-color: #6c757d; }
  .btn-secondary:focus, .btn-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(134, 142, 150, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    background-color: #868e96;
    border-color: #868e96; }
  .btn-secondary:not([disabled]):not(.disabled):active, .btn-secondary:not([disabled]):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #6c757d;
    border-color: #666e76; }
    .btn-secondary:not([disabled]):not(.disabled):active:focus, .btn-secondary:not([disabled]):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(134, 142, 150, 0.5); }

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745; }
  .btn-success:hover {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34; }
  .btn-success:focus, .btn-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    background-color: #28a745;
    border-color: #28a745; }
  .btn-success:not([disabled]):not(.disabled):active, .btn-success:not([disabled]):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #1e7e34;
    border-color: #1c7430; }
    .btn-success:not([disabled]):not(.disabled):active:focus, .btn-success:not([disabled]):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8; }
  .btn-info:hover {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b; }
  .btn-info:focus, .btn-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-info:not([disabled]):not(.disabled):active, .btn-info:not([disabled]):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #117a8b;
    border-color: #10707f; }
    .btn-info:not([disabled]):not(.disabled):active:focus, .btn-info:not([disabled]):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107; }
  .btn-warning:hover {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00; }
  .btn-warning:focus, .btn-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-warning:not([disabled]):not(.disabled):active, .btn-warning:not([disabled]):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #212529;
    background-color: #d39e00;
    border-color: #c69500; }
    .btn-warning:not([disabled]):not(.disabled):active:focus, .btn-warning:not([disabled]):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545; }
  .btn-danger:hover {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130; }
  .btn-danger:focus, .btn-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-danger:not([disabled]):not(.disabled):active, .btn-danger:not([disabled]):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #bd2130;
    border-color: #b21f2d; }
    .btn-danger:not([disabled]):not(.disabled):active:focus, .btn-danger:not([disabled]):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-light:hover {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5; }
  .btn-light:focus, .btn-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-light:not([disabled]):not(.disabled):active, .btn-light:not([disabled]):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #dae0e5;
    border-color: #d3d9df; }
    .btn-light:not([disabled]):not(.disabled):active:focus, .btn-light:not([disabled]):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40; }
  .btn-dark:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124; }
  .btn-dark:focus, .btn-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    background-color: #343a40;
    border-color: #343a40; }
  .btn-dark:not([disabled]):not(.disabled):active, .btn-dark:not([disabled]):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1d2124;
    border-color: #171a1d; }
    .btn-dark:not([disabled]):not(.disabled):active:focus, .btn-dark:not([disabled]):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.btn-outline-primary {
  color: #007bff;
  background-color: transparent;
  background-image: none;
  border-color: #007bff; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #007bff;
    background-color: transparent; }
  .btn-outline-primary:not([disabled]):not(.disabled):active, .btn-outline-primary:not([disabled]):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #212529;
    background-color: #007bff;
    border-color: #007bff;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }

.btn-outline-secondary {
  color: #868e96;
  background-color: transparent;
  background-image: none;
  border-color: #868e96; }
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #868e96;
    border-color: #868e96; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(134, 142, 150, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #868e96;
    background-color: transparent; }
  .btn-outline-secondary:not([disabled]):not(.disabled):active, .btn-outline-secondary:not([disabled]):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #212529;
    background-color: #868e96;
    border-color: #868e96;
    box-shadow: 0 0 0 0.2rem rgba(134, 142, 150, 0.5); }

.btn-outline-success {
  color: #28a745;
  background-color: transparent;
  background-image: none;
  border-color: #28a745; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #28a745;
    background-color: transparent; }
  .btn-outline-success:not([disabled]):not(.disabled):active, .btn-outline-success:not([disabled]):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #212529;
    background-color: #28a745;
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.btn-outline-info {
  color: #17a2b8;
  background-color: transparent;
  background-image: none;
  border-color: #17a2b8; }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #17a2b8;
    background-color: transparent; }
  .btn-outline-info:not([disabled]):not(.disabled):active, .btn-outline-info:not([disabled]):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #212529;
    background-color: #17a2b8;
    border-color: #17a2b8;
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.btn-outline-warning {
  color: #ffc107;
  background-color: transparent;
  background-image: none;
  border-color: #ffc107; }
  .btn-outline-warning:hover {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #ffc107;
    background-color: transparent; }
  .btn-outline-warning:not([disabled]):not(.disabled):active, .btn-outline-warning:not([disabled]):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107;
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.btn-outline-danger {
  color: #dc3545;
  background-color: transparent;
  background-image: none;
  border-color: #dc3545; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #dc3545;
    background-color: transparent; }
  .btn-outline-danger:not([disabled]):not(.disabled):active, .btn-outline-danger:not([disabled]):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #212529;
    background-color: #dc3545;
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.btn-outline-light {
  color: #f8f9fa;
  background-color: transparent;
  background-image: none;
  border-color: #f8f9fa; }
  .btn-outline-light:hover {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #f8f9fa;
    background-color: transparent; }
  .btn-outline-light:not([disabled]):not(.disabled):active, .btn-outline-light:not([disabled]):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #fff;
    background-color: #f8f9fa;
    border-color: #f8f9fa;
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.btn-outline-dark {
  color: #343a40;
  background-color: transparent;
  background-image: none;
  border-color: #343a40; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent; }
  .btn-outline-dark:not([disabled]):not(.disabled):active, .btn-outline-dark:not([disabled]):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #212529;
    background-color: #343a40;
    border-color: #343a40;
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.btn-link {
  font-weight: 400;
  color: #007bff;
  background-color: transparent; }
  .btn-link:hover {
    color: #0056b3;
    text-decoration: underline;
    background-color: transparent;
    border-color: transparent; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline;
    border-color: transparent;
    box-shadow: none; }
  .btn-link:disabled, .btn-link.disabled {
    color: #868e96; }

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  opacity: 0;
  transition: opacity 0.15s linear; }
  .fade.show {
    opacity: 1; }

.collapse {
  display: none; }
  .collapse.show {
    display: block; }

tr.collapse.show {
  display: table-row; }

tbody.collapse.show {
  display: table-row-group; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }

.dropup,
.dropdown {
  position: relative; }

.dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent; }

.dropdown-toggle:empty::after {
  margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem; }

.dropup .dropdown-menu {
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  width: 0;
  height: 0;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:focus, .dropdown-item:hover {
    color: #16181b;
    text-decoration: none;
    background-color: #f8f9fa; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #007bff; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #868e96;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #868e96;
  white-space: nowrap; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 0 1 auto; }
    .btn-group > .btn:hover,
    .btn-group-vertical > .btn:hover {
      z-index: 1; }
    .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .btn.active {
      z-index: 1; }
  .btn-group .btn + .btn,
  .btn-group .btn + .btn-group,
  .btn-group .btn-group + .btn,
  .btn-group .btn-group + .btn-group,
  .btn-group-vertical .btn + .btn,
  .btn-group-vertical .btn + .btn-group,
  .btn-group-vertical .btn-group + .btn,
  .btn-group-vertical .btn-group + .btn-group {
    margin-left: -1px; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:first-child {
  margin-left: 0; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem; }
  .dropdown-toggle-split::after {
    margin-left: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical .btn,
  .btn-group-vertical .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn + .btn,
  .btn-group-vertical > .btn + .btn-group,
  .btn-group-vertical > .btn-group + .btn,
  .btn-group-vertical > .btn-group + .btn-group {
    margin-top: -1px;
    margin-left: 0; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0; }
  .btn-group-toggle > .btn input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group .form-control,
  .input-group .custom-select,
  .input-group .custom-file {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0; }
    .input-group .form-control:focus,
    .input-group .custom-select:focus,
    .input-group .custom-file:focus {
      z-index: 3; }
    .input-group .form-control + .form-control,
    .input-group .custom-select + .form-control,
    .input-group .custom-file + .form-control {
      margin-left: -1px; }
  .input-group .form-control:not(:last-child),
  .input-group .custom-select:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input-group .form-control:not(:first-child),
  .input-group .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .input-group .custom-file {
    display: flex;
    align-items: center; }
    .input-group .custom-file:not(:last-child) .custom-file-control,
    .input-group .custom-file:not(:last-child) .custom-file-control::before {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .input-group .custom-file:not(:first-child) .custom-file-control,
    .input-group .custom-file:not(:first-child) .custom-file-control::before {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }

.input-group-prepend,
.input-group-append {
  display: flex;
  align-items: center; }
  .input-group-prepend .btn,
  .input-group-append .btn {
    position: relative;
    z-index: 2; }
  .input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-append .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn {
    margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: -1px; }

.input-group-text {
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem; }

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    background-color: #007bff; }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .custom-control-input:active ~ .custom-control-label::before {
    color: #fff;
    background-color: #b3d7ff; }
  .custom-control-input:disabled ~ .custom-control-label {
    color: #868e96; }
    .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #e9ecef; }

.custom-control-label {
  margin-bottom: 0; }
  .custom-control-label::before {
    position: absolute;
    top: 0.25rem;
    left: 0;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    user-select: none;
    background-color: #dee2e6; }
  .custom-control-label::after {
    position: absolute;
    top: 0.25rem;
    left: 0;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%; }

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #007bff; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  background-color: #007bff; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23fff' d='M0 2h4'/%3E%3C/svg%3E"); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #007bff; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E"); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 0.75rem center;
  background-size: 8px 10px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  appearance: none; }
  .custom-select:focus {
    border-color: #80bdff;
    outline: 0;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
    .custom-select:focus::-ms-value {
      color: #495057;
      background-color: #fff; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none; }
  .custom-select:disabled {
    color: #868e96;
    background-color: #e9ecef; }
  .custom-select::-ms-expand {
    opacity: 0; }

.custom-select-sm {
  height: calc(1.8125rem + 2px);
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 75%; }

.custom-select-lg {
  height: calc(2.875rem + 2px);
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 125%; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(2.25rem + 2px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(2.25rem + 2px);
  margin: 0;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-control {
    border-color: #80bdff;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
    .custom-file-input:focus ~ .custom-file-control::before {
      border-color: #80bdff; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(calc(2.25rem + 2px) - 1px * 2);
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #495057;
    content: "Browse";
    background-color: #e9ecef;
    border-left: 1px solid #ced4da;
    border-radius: 0 0.25rem 0.25rem 0; }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:focus, .nav-link:hover {
    text-decoration: none; }
  .nav-link.disabled {
    color: #868e96; }

.nav-tabs {
  border-bottom: 1px solid #dee2e6; }
  .nav-tabs .nav-item {
    margin-bottom: -1px; }
  .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
    .nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover {
      border-color: #e9ecef #e9ecef #dee2e6; }
    .nav-tabs .nav-link.disabled {
      color: #868e96;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 0.25rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #007bff; }

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem; }
  .navbar > .container,
  .navbar > .container-fluid {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:focus, .navbar-brand:hover {
    text-decoration: none; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem; }
  .navbar-toggler:focus, .navbar-toggler:hover {
    text-decoration: none; }
  .navbar-toggler:not([disabled]):not(.disabled) {
    cursor: pointer; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%; }

@media (max-width: 575.99px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .dropdown-menu-right {
        right: 0;
        left: auto; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: .5rem;
        padding-left: .5rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; }
    .navbar-expand-sm .dropup .dropdown-menu {
      top: auto;
      bottom: 100%; } }

@media (max-width: 767.99px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .dropdown-menu-right {
        right: 0;
        left: auto; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: .5rem;
        padding-left: .5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; }
    .navbar-expand-md .dropup .dropdown-menu {
      top: auto;
      bottom: 100%; } }

@media (max-width: 991.99px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .dropdown-menu-right {
        right: 0;
        left: auto; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: .5rem;
        padding-left: .5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; }
    .navbar-expand-lg .dropup .dropdown-menu {
      top: auto;
      bottom: 100%; } }

@media (max-width: 1199.99px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .dropdown-menu-right {
        right: 0;
        left: auto; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: .5rem;
        padding-left: .5rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; }
    .navbar-expand-xl .dropup .dropdown-menu {
      top: auto;
      bottom: 100%; } }

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .dropdown-menu-right {
      right: 0;
      left: auto; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: .5rem;
      padding-left: .5rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    flex-wrap: nowrap; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }
  .navbar-expand .dropup .dropdown-menu {
    top: auto;
    bottom: 100%; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:focus, .navbar-light .navbar-brand:hover {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9); }
    .navbar-light .navbar-text a:focus, .navbar-light .navbar-text a:hover {
      color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #fff; }
  .navbar-dark .navbar-brand:focus, .navbar-dark .navbar-brand:hover {
    color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:focus, .navbar-dark .navbar-nav .nav-link:hover {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-text a {
    color: #fff; }
    .navbar-dark .navbar-text a:focus, .navbar-dark .navbar-text a:hover {
      color: #fff; }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group:first-child .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  .card > .list-group:last-child .list-group-item:last-child {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }

.card-body {
  flex: 1 1 auto;
  padding: 1.25rem; }

.card-title {
  margin-bottom: 0.75rem; }

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.25rem; }

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  .card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0; }
  .card-header + .list-group .list-group-item:first-child {
    border-top: 0; }

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125); }
  .card-footer:last-child {
    border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px); }

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem; }

.card-img {
  width: 100%;
  border-radius: calc(0.25rem - 1px); }

.card-img-top {
  width: 100%;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px); }

.card-deck {
  display: flex;
  flex-direction: column; }
  .card-deck .card {
    margin-bottom: 15px; }
  @media (min-width: 576px) {
    .card-deck {
      flex-flow: row wrap;
      margin-right: -15px;
      margin-left: -15px; }
      .card-deck .card {
        display: flex;
        flex: 1 0 0%;
        flex-direction: column;
        margin-right: 15px;
        margin-bottom: 0;
        margin-left: 15px; } }

.card-group {
  display: flex;
  flex-direction: column; }
  .card-group > .card {
    margin-bottom: 15px; }
  @media (min-width: 576px) {
    .card-group {
      flex-flow: row wrap; }
      .card-group > .card {
        flex: 1 0 0%;
        margin-bottom: 0; }
        .card-group > .card + .card {
          margin-left: 0;
          border-left: 0; }
        .card-group > .card:first-child {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0; }
          .card-group > .card:first-child .card-img-top,
          .card-group > .card:first-child .card-header {
            border-top-right-radius: 0; }
          .card-group > .card:first-child .card-img-bottom,
          .card-group > .card:first-child .card-footer {
            border-bottom-right-radius: 0; }
        .card-group > .card:last-child {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0; }
          .card-group > .card:last-child .card-img-top,
          .card-group > .card:last-child .card-header {
            border-top-left-radius: 0; }
          .card-group > .card:last-child .card-img-bottom,
          .card-group > .card:last-child .card-footer {
            border-bottom-left-radius: 0; }
        .card-group > .card:only-child {
          border-radius: 0.25rem; }
          .card-group > .card:only-child .card-img-top,
          .card-group > .card:only-child .card-header {
            border-top-left-radius: 0.25rem;
            border-top-right-radius: 0.25rem; }
          .card-group > .card:only-child .card-img-bottom,
          .card-group > .card:only-child .card-footer {
            border-bottom-right-radius: 0.25rem;
            border-bottom-left-radius: 0.25rem; }
        .card-group > .card:not(:first-child):not(:last-child):not(:only-child) {
          border-radius: 0; }
          .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-img-top,
          .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-img-bottom,
          .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-header,
          .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-footer {
            border-radius: 0; } }

.card-columns .card {
  margin-bottom: 0.75rem; }

@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  color: #868e96;
  content: "/"; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none; }

.breadcrumb-item.active {
  color: #868e96; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem; }

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #007bff;
  background-color: #fff;
  border: 1px solid #dee2e6; }
  .page-link:focus, .page-link:hover {
    color: #0056b3;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #dee2e6; }
  .page-link:not([disabled]):not(.disabled) {
    cursor: pointer; }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; }

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem; }

.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff; }

.page-item.disabled .page-link {
  color: #868e96;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem; }

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem; }

.badge-primary {
  color: #fff;
  background-color: #007bff; }
  .badge-primary[href]:focus, .badge-primary[href]:hover {
    color: #fff;
    text-decoration: none;
    background-color: #0062cc; }

.badge-secondary {
  color: #fff;
  background-color: #868e96; }
  .badge-secondary[href]:focus, .badge-secondary[href]:hover {
    color: #fff;
    text-decoration: none;
    background-color: #6c757d; }

.badge-success {
  color: #fff;
  background-color: #28a745; }
  .badge-success[href]:focus, .badge-success[href]:hover {
    color: #fff;
    text-decoration: none;
    background-color: #1e7e34; }

.badge-info {
  color: #fff;
  background-color: #17a2b8; }
  .badge-info[href]:focus, .badge-info[href]:hover {
    color: #fff;
    text-decoration: none;
    background-color: #117a8b; }

.badge-warning {
  color: #212529;
  background-color: #ffc107; }
  .badge-warning[href]:focus, .badge-warning[href]:hover {
    color: #212529;
    text-decoration: none;
    background-color: #d39e00; }

.badge-danger {
  color: #fff;
  background-color: #dc3545; }
  .badge-danger[href]:focus, .badge-danger[href]:hover {
    color: #fff;
    text-decoration: none;
    background-color: #bd2130; }

.badge-light {
  color: #212529;
  background-color: #f8f9fa; }
  .badge-light[href]:focus, .badge-light[href]:hover {
    color: #212529;
    text-decoration: none;
    background-color: #dae0e5; }

.badge-dark {
  color: #fff;
  background-color: #343a40; }
  .badge-dark[href]:focus, .badge-dark[href]:hover {
    color: #fff;
    text-decoration: none;
    background-color: #1d2124; }

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem; }
  @media (min-width: 576px) {
    .jumbotron {
      padding: 4rem 2rem; } }

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0; }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 4rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1.25rem;
    color: inherit; }

.alert-primary {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff; }
  .alert-primary hr {
    border-top-color: #9fcdff; }
  .alert-primary .alert-link {
    color: #002752; }

.alert-secondary {
  color: #464a4e;
  background-color: #e7e8ea;
  border-color: #dddfe2; }
  .alert-secondary hr {
    border-top-color: #cfd2d6; }
  .alert-secondary .alert-link {
    color: #2e3133; }

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb; }
  .alert-success hr {
    border-top-color: #b1dfbb; }
  .alert-success .alert-link {
    color: #0b2e13; }

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb; }
  .alert-info hr {
    border-top-color: #abdde5; }
  .alert-info .alert-link {
    color: #062c33; }

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba; }
  .alert-warning hr {
    border-top-color: #ffe8a1; }
  .alert-warning .alert-link {
    color: #533f03; }

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb; }
  .alert-danger hr {
    border-top-color: #f1b0b7; }
  .alert-danger .alert-link {
    color: #491217; }

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe; }
  .alert-light hr {
    border-top-color: #ececf6; }
  .alert-light .alert-link {
    color: #686868; }

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca; }
  .alert-dark hr {
    border-top-color: #b9bbbe; }
  .alert-dark .alert-link {
    color: #040505; }

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  text-align: center;
  background-color: #007bff;
  transition: width 0.6s ease; }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite; }

.media {
  display: flex;
  align-items: flex-start; }

.media-body {
  flex: 1; }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0; }

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }
  .list-group-item-action:focus, .list-group-item-action:hover {
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa; }
  .list-group-item-action:active {
    color: #212529;
    background-color: #e9ecef; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  .list-group-item:last-child {
    margin-bottom: 0;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }
  .list-group-item:focus, .list-group-item:hover {
    z-index: 1;
    text-decoration: none; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #868e96;
    background-color: #fff; }
  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }

.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0; }

.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0; }

.list-group-flush:last-child .list-group-item:last-child {
  border-bottom: 0; }

.list-group-item-primary {
  color: #004085;
  background-color: #b8daff; }

a.list-group-item-primary,
button.list-group-item-primary {
  color: #004085; }
  a.list-group-item-primary:focus, a.list-group-item-primary:hover,
  button.list-group-item-primary:focus,
  button.list-group-item-primary:hover {
    color: #004085;
    background-color: #9fcdff; }
  a.list-group-item-primary.active,
  button.list-group-item-primary.active {
    color: #fff;
    background-color: #004085;
    border-color: #004085; }

.list-group-item-secondary {
  color: #464a4e;
  background-color: #dddfe2; }

a.list-group-item-secondary,
button.list-group-item-secondary {
  color: #464a4e; }
  a.list-group-item-secondary:focus, a.list-group-item-secondary:hover,
  button.list-group-item-secondary:focus,
  button.list-group-item-secondary:hover {
    color: #464a4e;
    background-color: #cfd2d6; }
  a.list-group-item-secondary.active,
  button.list-group-item-secondary.active {
    color: #fff;
    background-color: #464a4e;
    border-color: #464a4e; }

.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb; }

a.list-group-item-success,
button.list-group-item-success {
  color: #155724; }
  a.list-group-item-success:focus, a.list-group-item-success:hover,
  button.list-group-item-success:focus,
  button.list-group-item-success:hover {
    color: #155724;
    background-color: #b1dfbb; }
  a.list-group-item-success.active,
  button.list-group-item-success.active {
    color: #fff;
    background-color: #155724;
    border-color: #155724; }

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb; }

a.list-group-item-info,
button.list-group-item-info {
  color: #0c5460; }
  a.list-group-item-info:focus, a.list-group-item-info:hover,
  button.list-group-item-info:focus,
  button.list-group-item-info:hover {
    color: #0c5460;
    background-color: #abdde5; }
  a.list-group-item-info.active,
  button.list-group-item-info.active {
    color: #fff;
    background-color: #0c5460;
    border-color: #0c5460; }

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba; }

a.list-group-item-warning,
button.list-group-item-warning {
  color: #856404; }
  a.list-group-item-warning:focus, a.list-group-item-warning:hover,
  button.list-group-item-warning:focus,
  button.list-group-item-warning:hover {
    color: #856404;
    background-color: #ffe8a1; }
  a.list-group-item-warning.active,
  button.list-group-item-warning.active {
    color: #fff;
    background-color: #856404;
    border-color: #856404; }

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb; }

a.list-group-item-danger,
button.list-group-item-danger {
  color: #721c24; }
  a.list-group-item-danger:focus, a.list-group-item-danger:hover,
  button.list-group-item-danger:focus,
  button.list-group-item-danger:hover {
    color: #721c24;
    background-color: #f1b0b7; }
  a.list-group-item-danger.active,
  button.list-group-item-danger.active {
    color: #fff;
    background-color: #721c24;
    border-color: #721c24; }

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe; }

a.list-group-item-light,
button.list-group-item-light {
  color: #818182; }
  a.list-group-item-light:focus, a.list-group-item-light:hover,
  button.list-group-item-light:focus,
  button.list-group-item-light:hover {
    color: #818182;
    background-color: #ececf6; }
  a.list-group-item-light.active,
  button.list-group-item-light.active {
    color: #fff;
    background-color: #818182;
    border-color: #818182; }

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca; }

a.list-group-item-dark,
button.list-group-item-dark {
  color: #1b1e21; }
  a.list-group-item-dark:focus, a.list-group-item-dark:hover,
  button.list-group-item-dark:focus,
  button.list-group-item-dark:hover {
    color: #1b1e21;
    background-color: #b9bbbe; }
  a.list-group-item-dark.active,
  button.list-group-item-dark.active {
    color: #fff;
    background-color: #1b1e21;
    border-color: #1b1e21; }

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5; }
  .close:focus, .close:hover {
    color: #000;
    text-decoration: none;
    opacity: .75; }
  .close:not([disabled]):not(.disabled) {
    cursor: pointer; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none; }

.modal-open {
  overflow: hidden; }

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  outline: 0; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -25%); }
  .modal.show .modal-dialog {
    transform: translate(0, 0); }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - (0.5rem * 2)); }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid #e9ecef;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem; }
  .modal-header .close {
    padding: 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #e9ecef; }
  .modal-footer > :not(:first-child) {
    margin-left: .25rem; }
  .modal-footer > :not(:last-child) {
    margin-right: .25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-centered {
    min-height: calc(100% - (1.75rem * 2)); }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg {
    max-width: 800px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #000; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #000; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #000; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #000; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem; }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem; }
    .popover .arrow::before, .popover .arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem; }
  .bs-popover-top .arrow, .bs-popover-auto[x-placement^="top"] .arrow {
    bottom: calc((0.5rem + 1px) * -1); }
  .bs-popover-top .arrow::before, .bs-popover-auto[x-placement^="top"] .arrow::before,
  .bs-popover-top .arrow::after,
  .bs-popover-auto[x-placement^="top"] .arrow::after {
    border-width: 0.5rem 0.5rem 0; }
  .bs-popover-top .arrow::before, .bs-popover-auto[x-placement^="top"] .arrow::before {
    bottom: 0;
    border-top-color: rgba(0, 0, 0, 0.25); }
  
  .bs-popover-top .arrow::after,
  .bs-popover-auto[x-placement^="top"] .arrow::after {
    bottom: 1px;
    border-top-color: #fff; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem; }
  .bs-popover-right .arrow, .bs-popover-auto[x-placement^="right"] .arrow {
    left: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
  .bs-popover-right .arrow::before, .bs-popover-auto[x-placement^="right"] .arrow::before,
  .bs-popover-right .arrow::after,
  .bs-popover-auto[x-placement^="right"] .arrow::after {
    border-width: 0.5rem 0.5rem 0.5rem 0; }
  .bs-popover-right .arrow::before, .bs-popover-auto[x-placement^="right"] .arrow::before {
    left: 0;
    border-right-color: rgba(0, 0, 0, 0.25); }
  
  .bs-popover-right .arrow::after,
  .bs-popover-auto[x-placement^="right"] .arrow::after {
    left: 1px;
    border-right-color: #fff; }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem; }
  .bs-popover-bottom .arrow, .bs-popover-auto[x-placement^="bottom"] .arrow {
    top: calc((0.5rem + 1px) * -1); }
  .bs-popover-bottom .arrow::before, .bs-popover-auto[x-placement^="bottom"] .arrow::before,
  .bs-popover-bottom .arrow::after,
  .bs-popover-auto[x-placement^="bottom"] .arrow::after {
    border-width: 0 0.5rem 0.5rem 0.5rem; }
  .bs-popover-bottom .arrow::before, .bs-popover-auto[x-placement^="bottom"] .arrow::before {
    top: 0;
    border-bottom-color: rgba(0, 0, 0, 0.25); }
  
  .bs-popover-bottom .arrow::after,
  .bs-popover-auto[x-placement^="bottom"] .arrow::after {
    top: 1px;
    border-bottom-color: #fff; }
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem; }
  .bs-popover-left .arrow, .bs-popover-auto[x-placement^="left"] .arrow {
    right: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
  .bs-popover-left .arrow::before, .bs-popover-auto[x-placement^="left"] .arrow::before,
  .bs-popover-left .arrow::after,
  .bs-popover-auto[x-placement^="left"] .arrow::after {
    border-width: 0.5rem 0 0.5rem 0.5rem; }
  .bs-popover-left .arrow::before, .bs-popover-auto[x-placement^="left"] .arrow::before {
    right: 0;
    border-left-color: rgba(0, 0, 0, 0.25); }
  
  .bs-popover-left .arrow::after,
  .bs-popover-auto[x-placement^="left"] .arrow::after {
    right: 1px;
    border-left-color: #fff; }

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  color: inherit;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529; }

.carousel {
  position: relative; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }

.carousel-item {
  position: relative;
  display: none;
  align-items: center;
  width: 100%;
  transition: transform 0.6s ease;
  backface-visibility: hidden;
  perspective: 1000px; }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next,
.carousel-item-prev {
  position: absolute;
  top: 0; }

.carousel-item-next.carousel-item-left,
.carousel-item-prev.carousel-item-right {
  transform: translateX(0); }
  @supports (transform-style: preserve-3d) {
    .carousel-item-next.carousel-item-left,
    .carousel-item-prev.carousel-item-right {
      transform: translate3d(0, 0, 0); } }

.carousel-item-next,
.active.carousel-item-right {
  transform: translateX(100%); }
  @supports (transform-style: preserve-3d) {
    .carousel-item-next,
    .active.carousel-item-right {
      transform: translate3d(100%, 0, 0); } }

.carousel-item-prev,
.active.carousel-item-left {
  transform: translateX(-100%); }
  @supports (transform-style: preserve-3d) {
    .carousel-item-prev,
    .active.carousel-item-left {
      transform: translate3d(-100%, 0, 0); } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5; }
  .carousel-control-prev:focus, .carousel-control-prev:hover,
  .carousel-control-next:focus,
  .carousel-control-next:hover {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: .9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: transparent no-repeat center center;
  background-size: 100% 100%; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 10px;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators li {
    position: relative;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    background-color: rgba(255, 255, 255, 0.5); }
    .carousel-indicators li::before {
      position: absolute;
      top: -10px;
      left: 0;
      display: inline-block;
      width: 100%;
      height: 10px;
      content: ""; }
    .carousel-indicators li::after {
      position: absolute;
      bottom: -10px;
      left: 0;
      display: inline-block;
      width: 100%;
      height: 10px;
      content: ""; }
  .carousel-indicators .active {
    background-color: #fff; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #007bff !important; }

a.bg-primary:focus, a.bg-primary:hover,
button.bg-primary:focus,
button.bg-primary:hover {
  background-color: #0062cc !important; }

.bg-secondary {
  background-color: #868e96 !important; }

a.bg-secondary:focus, a.bg-secondary:hover,
button.bg-secondary:focus,
button.bg-secondary:hover {
  background-color: #6c757d !important; }

.bg-success {
  background-color: #28a745 !important; }

a.bg-success:focus, a.bg-success:hover,
button.bg-success:focus,
button.bg-success:hover {
  background-color: #1e7e34 !important; }

.bg-info {
  background-color: #17a2b8 !important; }

a.bg-info:focus, a.bg-info:hover,
button.bg-info:focus,
button.bg-info:hover {
  background-color: #117a8b !important; }

.bg-warning {
  background-color: #ffc107 !important; }

a.bg-warning:focus, a.bg-warning:hover,
button.bg-warning:focus,
button.bg-warning:hover {
  background-color: #d39e00 !important; }

.bg-danger {
  background-color: #dc3545 !important; }

a.bg-danger:focus, a.bg-danger:hover,
button.bg-danger:focus,
button.bg-danger:hover {
  background-color: #bd2130 !important; }

.bg-light {
  background-color: #f8f9fa !important; }

a.bg-light:focus, a.bg-light:hover,
button.bg-light:focus,
button.bg-light:hover {
  background-color: #dae0e5 !important; }

.bg-dark {
  background-color: #343a40 !important; }

a.bg-dark:focus, a.bg-dark:hover,
button.bg-dark:focus,
button.bg-dark:hover {
  background-color: #1d2124 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #e9ecef !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #007bff !important; }

.border-secondary {
  border-color: #868e96 !important; }

.border-success {
  border-color: #28a745 !important; }

.border-info {
  border-color: #17a2b8 !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #dc3545 !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #343a40 !important; }

.border-white {
  border-color: #fff !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

.d-print-block {
  display: none !important; }
  @media print {
    .d-print-block {
      display: block !important; } }

.d-print-inline {
  display: none !important; }
  @media print {
    .d-print-inline {
      display: inline !important; } }

.d-print-inline-block {
  display: none !important; }
  @media print {
    .d-print-inline-block {
      display: inline-block !important; } }

@media print {
  .d-print-none {
    display: none !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  clip-path: inset(50%);
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
  clip-path: none; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.text-justify {
  text-align: justify !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #007bff !important; }

a.text-primary:focus, a.text-primary:hover {
  color: #0062cc !important; }

.text-secondary {
  color: #868e96 !important; }

a.text-secondary:focus, a.text-secondary:hover {
  color: #6c757d !important; }

.text-success {
  color: #28a745 !important; }

a.text-success:focus, a.text-success:hover {
  color: #1e7e34 !important; }

.text-info {
  color: #17a2b8 !important; }

a.text-info:focus, a.text-info:hover {
  color: #117a8b !important; }

.text-warning {
  color: #ffc107 !important; }

a.text-warning:focus, a.text-warning:hover {
  color: #d39e00 !important; }

.text-danger {
  color: #dc3545 !important; }

a.text-danger:focus, a.text-danger:hover {
  color: #bd2130 !important; }

.text-light {
  color: #f8f9fa !important; }

a.text-light:focus, a.text-light:hover {
  color: #dae0e5 !important; }

.text-dark {
  color: #343a40 !important; }

a.text-dark:focus, a.text-dark:hover {
  color: #1d2124 !important; }

.text-muted {
  color: #868e96 !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a,
  a:visited {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #fff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #ddd !important; } }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

/*Partials*/
@font-face {
  font-family: "feather";
  src: url("../../assets/fonts/feather.eot?t=1525787366991");
  /* IE9*/
  src: url("../../assets/fonts/feather.eot?t=1525787366991#iefix") format("embedded-opentype"), url("../../assets/fonts/feather.woff?t=1525787366991") format("woff"), url("../../assets/fonts/feather.ttf?t=1525787366991") format("truetype"), url("../../assets/fonts/feather.svg?t=1525787366991#feather") format("svg");
  /* iOS 4.1- */ }

.feather {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'feather' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-alert-octagon:before {
  content: "\e81b"; }

.icon-alert-circle:before {
  content: "\e81c"; }

.icon-activity:before {
  content: "\e81d"; }

.icon-alert-triangle:before {
  content: "\e81e"; }

.icon-align-center:before {
  content: "\e81f"; }

.icon-airplay:before {
  content: "\e820"; }

.icon-align-justify:before {
  content: "\e821"; }

.icon-align-left:before {
  content: "\e822"; }

.icon-align-right:before {
  content: "\e823"; }

.icon-arrow-down-left:before {
  content: "\e824"; }

.icon-arrow-down-right:before {
  content: "\e825"; }

.icon-anchor:before {
  content: "\e826"; }

.icon-aperture:before {
  content: "\e827"; }

.icon-arrow-left:before {
  content: "\e828"; }

.icon-arrow-right:before {
  content: "\e829"; }

.icon-arrow-down:before {
  content: "\e82a"; }

.icon-arrow-up-left:before {
  content: "\e82b"; }

.icon-arrow-up-right:before {
  content: "\e82c"; }

.icon-arrow-up:before {
  content: "\e82d"; }

.icon-award:before {
  content: "\e82e"; }

.icon-bar-chart:before {
  content: "\e82f"; }

.icon-at-sign:before {
  content: "\e830"; }

.icon-bar-chart-2:before {
  content: "\e831"; }

.icon-battery-charging:before {
  content: "\e832"; }

.icon-bell-off:before {
  content: "\e833"; }

.icon-battery:before {
  content: "\e834"; }

.icon-bluetooth:before {
  content: "\e835"; }

.icon-bell:before {
  content: "\e836"; }

.icon-book:before {
  content: "\e837"; }

.icon-briefcase:before {
  content: "\e838"; }

.icon-camera-off:before {
  content: "\e839"; }

.icon-calendar:before {
  content: "\e83a"; }

.icon-bookmark:before {
  content: "\e83b"; }

.icon-box:before {
  content: "\e83c"; }

.icon-camera:before {
  content: "\e83d"; }

.icon-check-circle:before {
  content: "\e83e"; }

.icon-check:before {
  content: "\e83f"; }

.icon-check-square:before {
  content: "\e840"; }

.icon-cast:before {
  content: "\e841"; }

.icon-chevron-down:before {
  content: "\e842"; }

.icon-chevron-left:before {
  content: "\e843"; }

.icon-chevron-right:before {
  content: "\e844"; }

.icon-chevron-up:before {
  content: "\e845"; }

.icon-chevrons-down:before {
  content: "\e846"; }

.icon-chevrons-right:before {
  content: "\e847"; }

.icon-chevrons-up:before {
  content: "\e848"; }

.icon-chevrons-left:before {
  content: "\e849"; }

.icon-circle:before {
  content: "\e84a"; }

.icon-clipboard:before {
  content: "\e84b"; }

.icon-chrome:before {
  content: "\e84c"; }

.icon-clock:before {
  content: "\e84d"; }

.icon-cloud-lightning:before {
  content: "\e84e"; }

.icon-cloud-drizzle:before {
  content: "\e84f"; }

.icon-cloud-rain:before {
  content: "\e850"; }

.icon-cloud-off:before {
  content: "\e851"; }

.icon-codepen:before {
  content: "\e852"; }

.icon-cloud-snow:before {
  content: "\e853"; }

.icon-compass:before {
  content: "\e854"; }

.icon-copy:before {
  content: "\e855"; }

.icon-corner-down-right:before {
  content: "\e856"; }

.icon-corner-down-left:before {
  content: "\e857"; }

.icon-corner-left-down:before {
  content: "\e858"; }

.icon-corner-left-up:before {
  content: "\e859"; }

.icon-corner-up-left:before {
  content: "\e85a"; }

.icon-corner-up-right:before {
  content: "\e85b"; }

.icon-corner-right-down:before {
  content: "\e85c"; }

.icon-corner-right-up:before {
  content: "\e85d"; }

.icon-cpu:before {
  content: "\e85e"; }

.icon-credit-card:before {
  content: "\e85f"; }

.icon-crosshair:before {
  content: "\e860"; }

.icon-disc:before {
  content: "\e861"; }

.icon-delete:before {
  content: "\e862"; }

.icon-download-cloud:before {
  content: "\e863"; }

.icon-download:before {
  content: "\e864"; }

.icon-droplet:before {
  content: "\e865"; }

.icon-edit-2:before {
  content: "\e866"; }

.icon-edit:before {
  content: "\e867"; }

.icon-edit-1:before {
  content: "\e868"; }

.icon-external-link:before {
  content: "\e869"; }

.icon-eye:before {
  content: "\e86a"; }

.icon-feather:before {
  content: "\e86b"; }

.icon-facebook:before {
  content: "\e86c"; }

.icon-file-minus:before {
  content: "\e86d"; }

.icon-eye-off:before {
  content: "\e86e"; }

.icon-fast-forward:before {
  content: "\e86f"; }

.icon-file-text:before {
  content: "\e870"; }

.icon-film:before {
  content: "\e871"; }

.icon-file:before {
  content: "\e872"; }

.icon-file-plus:before {
  content: "\e873"; }

.icon-folder:before {
  content: "\e874"; }

.icon-filter:before {
  content: "\e875"; }

.icon-flag:before {
  content: "\e876"; }

.icon-globe:before {
  content: "\e877"; }

.icon-grid:before {
  content: "\e878"; }

.icon-heart:before {
  content: "\e879"; }

.icon-home:before {
  content: "\e87a"; }

.icon-github:before {
  content: "\e87b"; }

.icon-image:before {
  content: "\e87c"; }

.icon-inbox:before {
  content: "\e87d"; }

.icon-layers:before {
  content: "\e87e"; }

.icon-info:before {
  content: "\e87f"; }

.icon-instagram:before {
  content: "\e880"; }

.icon-layout:before {
  content: "\e881"; }

.icon-link-2:before {
  content: "\e882"; }

.icon-life-buoy:before {
  content: "\e883"; }

.icon-link:before {
  content: "\e884"; }

.icon-log-in:before {
  content: "\e885"; }

.icon-list:before {
  content: "\e886"; }

.icon-lock:before {
  content: "\e887"; }

.icon-log-out:before {
  content: "\e888"; }

.icon-loader:before {
  content: "\e889"; }

.icon-mail:before {
  content: "\e88a"; }

.icon-maximize-2:before {
  content: "\e88b"; }

.icon-map:before {
  content: "\e88c"; }

.icon-map-pin:before {
  content: "\e88e"; }

.icon-menu:before {
  content: "\e88f"; }

.icon-message-circle:before {
  content: "\e890"; }

.icon-message-square:before {
  content: "\e891"; }

.icon-minimize-2:before {
  content: "\e892"; }

.icon-mic-off:before {
  content: "\e893"; }

.icon-minus-circle:before {
  content: "\e894"; }

.icon-mic:before {
  content: "\e895"; }

.icon-minus-square:before {
  content: "\e896"; }

.icon-minus:before {
  content: "\e897"; }

.icon-moon:before {
  content: "\e898"; }

.icon-monitor:before {
  content: "\e899"; }

.icon-more-vertical:before {
  content: "\e89a"; }

.icon-more-horizontal:before {
  content: "\e89b"; }

.icon-move:before {
  content: "\e89c"; }

.icon-music:before {
  content: "\e89d"; }

.icon-navigation-2:before {
  content: "\e89e"; }

.icon-navigation:before {
  content: "\e89f"; }

.icon-octagon:before {
  content: "\e8a0"; }

.icon-package:before {
  content: "\e8a1"; }

.icon-pause-circle:before {
  content: "\e8a2"; }

.icon-pause:before {
  content: "\e8a3"; }

.icon-percent:before {
  content: "\e8a4"; }

.icon-phone-call:before {
  content: "\e8a5"; }

.icon-phone-forwarded:before {
  content: "\e8a6"; }

.icon-phone-missed:before {
  content: "\e8a7"; }

.icon-phone-off:before {
  content: "\e8a8"; }

.icon-phone-incoming:before {
  content: "\e8a9"; }

.icon-phone:before {
  content: "\e8aa"; }

.icon-phone-outgoing:before {
  content: "\e8ab"; }

.icon-pie-chart:before {
  content: "\e8ac"; }

.icon-play-circle:before {
  content: "\e8ad"; }

.icon-play:before {
  content: "\e8ae"; }

.icon-plus-square:before {
  content: "\e8af"; }

.icon-plus-circle:before {
  content: "\e8b0"; }

.icon-plus:before {
  content: "\e8b1"; }

.icon-pocket:before {
  content: "\e8b2"; }

.icon-printer:before {
  content: "\e8b3"; }

.icon-power:before {
  content: "\e8b4"; }

.icon-radio:before {
  content: "\e8b5"; }

.icon-repeat:before {
  content: "\e8b6"; }

.icon-refresh-ccw:before {
  content: "\e8b7"; }

.icon-rewind:before {
  content: "\e8b8"; }

.icon-rotate-ccw:before {
  content: "\e8b9"; }

.icon-refresh-cw:before {
  content: "\e8ba"; }

.icon-rotate-cw:before {
  content: "\e8bb"; }

.icon-save:before {
  content: "\e8bc"; }

.icon-search:before {
  content: "\e8bd"; }

.icon-server:before {
  content: "\e8be"; }

.icon-scissors:before {
  content: "\e8bf"; }

.icon-share-2:before {
  content: "\e8c0"; }

.icon-share:before {
  content: "\e8c1"; }

.icon-shield:before {
  content: "\e8c2"; }

.icon-settings:before {
  content: "\e8c3"; }

.icon-skip-back:before {
  content: "\e8c4"; }

.icon-shuffle:before {
  content: "\e8c5"; }

.icon-sidebar:before {
  content: "\e8c6"; }

.icon-skip-forward:before {
  content: "\e8c7"; }

.icon-slack:before {
  content: "\e8c8"; }

.icon-slash:before {
  content: "\e8c9"; }

.icon-smartphone:before {
  content: "\e8ca"; }

.icon-square:before {
  content: "\e8cb"; }

.icon-speaker:before {
  content: "\e8cc"; }

.icon-star:before {
  content: "\e8cd"; }

.icon-stop-circle:before {
  content: "\e8ce"; }

.icon-sun:before {
  content: "\e8cf"; }

.icon-sunrise:before {
  content: "\e8d0"; }

.icon-tablet:before {
  content: "\e8d1"; }

.icon-tag:before {
  content: "\e8d2"; }

.icon-sunset:before {
  content: "\e8d3"; }

.icon-target:before {
  content: "\e8d4"; }

.icon-thermometer:before {
  content: "\e8d5"; }

.icon-thumbs-up:before {
  content: "\e8d6"; }

.icon-thumbs-down:before {
  content: "\e8d7"; }

.icon-toggle-left:before {
  content: "\e8d8"; }

.icon-toggle-right:before {
  content: "\e8d9"; }

.icon-trash-2:before {
  content: "\e8da"; }

.icon-trash:before {
  content: "\e8db"; }

.icon-trending-up:before {
  content: "\e8dc"; }

.icon-trending-down:before {
  content: "\e8dd"; }

.icon-triangle:before {
  content: "\e8de"; }

.icon-type:before {
  content: "\e8df"; }

.icon-twitter:before {
  content: "\e8e0"; }

.icon-upload:before {
  content: "\e8e1"; }

.icon-umbrella:before {
  content: "\e8e2"; }

.icon-upload-cloud:before {
  content: "\e8e3"; }

.icon-unlock:before {
  content: "\e8e4"; }

.icon-user-check:before {
  content: "\e8e5"; }

.icon-user-minus:before {
  content: "\e8e6"; }

.icon-user-plus:before {
  content: "\e8e7"; }

.icon-user-x:before {
  content: "\e8e8"; }

.icon-user:before {
  content: "\e8e9"; }

.icon-users:before {
  content: "\e8ea"; }

.icon-video-off:before {
  content: "\e8eb"; }

.icon-video:before {
  content: "\e8ec"; }

.icon-voicemail:before {
  content: "\e8ed"; }

.icon-volume-x:before {
  content: "\e8ee"; }

.icon-volume-2:before {
  content: "\e8ef"; }

.icon-volume-1:before {
  content: "\e8f0"; }

.icon-volume:before {
  content: "\e8f1"; }

.icon-watch:before {
  content: "\e8f2"; }

.icon-wifi:before {
  content: "\e8f3"; }

.icon-x-square:before {
  content: "\e8f4"; }

.icon-wind:before {
  content: "\e8f5"; }

.icon-x:before {
  content: "\e8f6"; }

.icon-x-circle:before {
  content: "\e8f7"; }

.icon-zap:before {
  content: "\e8f8"; }

.icon-zoom-in:before {
  content: "\e8f9"; }

.icon-zoom-out:before {
  content: "\e8fa"; }

.icon-command:before {
  content: "\e8fb"; }

.icon-cloud:before {
  content: "\e8fc"; }

.icon-hash:before {
  content: "\e8fd"; }

.icon-headphones:before {
  content: "\e8fe"; }

.icon-underline:before {
  content: "\e8ff"; }

.icon-italic:before {
  content: "\e900"; }

.icon-bold:before {
  content: "\e901"; }

.icon-crop:before {
  content: "\e902"; }

.icon-help-circle:before {
  content: "\e903"; }

.icon-paperclip:before {
  content: "\e904"; }

.icon-shopping-cart:before {
  content: "\e905"; }

.icon-tv:before {
  content: "\e906"; }

.icon-wifi-off:before {
  content: "\e907"; }

.icon-minimize:before {
  content: "\e88d"; }

.icon-maximize:before {
  content: "\e908"; }

.icon-gitlab:before {
  content: "\e909"; }

.icon-sliders:before {
  content: "\e90a"; }

.icon-star-on:before {
  content: "\e90b"; }

.icon-heart-on:before {
  content: "\e90c"; }

.icon-archive:before {
  content: "\e90d"; }

.icon-arrow-down-circle:before {
  content: "\e90e"; }

.icon-arrow-up-circle:before {
  content: "\e90f"; }

.icon-arrow-left-circle:before {
  content: "\e910"; }

.icon-arrow-right-circle:before {
  content: "\e911"; }

.icon-bar-chart-line-:before {
  content: "\e912"; }

.icon-bar-chart-line:before {
  content: "\e913"; }

.icon-book-open:before {
  content: "\e914"; }

.icon-code:before {
  content: "\e915"; }

.icon-database:before {
  content: "\e916"; }

.icon-dollar-sign:before {
  content: "\e917"; }

.icon-folder-plus:before {
  content: "\e918"; }

.icon-gift:before {
  content: "\e919"; }

.icon-folder-minus:before {
  content: "\e91a"; }

.icon-git-commit:before {
  content: "\e91b"; }

.icon-git-branch:before {
  content: "\e91c"; }

.icon-git-pull-request:before {
  content: "\e91d"; }

.icon-git-merge:before {
  content: "\e91e"; }

.icon-linkedin:before {
  content: "\e91f"; }

.icon-hard-drive:before {
  content: "\e920"; }

.icon-more-vertical-:before {
  content: "\e921"; }

.icon-more-horizontal-:before {
  content: "\e922"; }

.icon-rss:before {
  content: "\e923"; }

.icon-send:before {
  content: "\e924"; }

.icon-shield-off:before {
  content: "\e925"; }

.icon-shopping-bag:before {
  content: "\e926"; }

.icon-terminal:before {
  content: "\e927"; }

.icon-truck:before {
  content: "\e928"; }

.icon-zap-off:before {
  content: "\e929"; }

.icon-youtube:before {
  content: "\e92a"; }

@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(~material-design-icons/iconfont/MaterialIcons-Regular.eot);
  /* For IE6-8 */
  src: local("Material Icons"), local("MaterialIcons-Regular"), url(~material-design-icons/iconfont/MaterialIcons-Regular.woff2) format("woff2"), url(~material-design-icons/iconfont/MaterialIcons-Regular.woff) format("woff"), url(~material-design-icons/iconfont/MaterialIcons-Regular.ttf) format("truetype"); }

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  /* Preferred icon size */
  display: inline-block;
  vertical-align: middle;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }

html {
  font-size: 93.8%; }

body {
  font-family: Arial, "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.467;
  font-weight: 400;
  color: #2d3132; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  font-family: "Raleway", sans-serif;
  font-weight: bold; }

h1, .h1 {
  font-size: 2.441em;
  margin: 0;
  line-height: 1.25;
  font-weight: bold; }

h2, .h2 {
  font-size: 1.953em;
  font-weight: bold;
  line-height: 1.4; }

h3, .h3 {
  font-size: 1.563em;
  font-weight: bold;
  line-height: 1.4; }

h4, .h4 {
  font-size: 1.25em;
  line-height: 1.4; }

h5, .h5 {
  font-size: 1em;
  line-height: 1.4; }

h6, .h6 {
  font-size: 0.8em;
  line-height: 1.4; }

p {
  font-size: 1em; }

a {
  text-decoration: none;
  color: #000; }
  a:hover {
    color: #31C9AA;
    text-decoration: none; }
  a:focus {
    color: #202020;
    text-decoration: none; }

.lang-rtl * {
  direction: rtl !important; }

.lang-rtl main {
  text-align: right !important; }

html {
  box-sizing: border-box; }

*, *:before, *:after {
  box-sizing: inherit; }

body,
html {
  height: 100%; }

ul {
  list-style: none;
  padding-left: 0; }

.dropdown-item:focus, .dropdown-item:hover {
  background: none; }

.container {
  margin: 0 auto;
  padding-left: 15px;
  padding-right: 15px;
  width: auto;
  max-width: 1440px; }

img {
  max-width: 100%;
  height: auto; }

.icon {
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  display: inline-block;
  vertical-align: middle;
  width: 1em;
  height: 1em;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  text-rendering: optimizeLegibility;
  font-feature-settings: 'liga'; }

:focus {
  outline: none; }

.touchspin-up:after {
  content: "\E5CE"; }

.touchspin-down:after {
  content: "\E5CF"; }

.hidden {
  display: none; }

.content-box {
  background: none; }
  .content-box.last {
    padding: 0; }
  .content-box.no-bg {
    background: none; }

.layout-boxed .content-box {
  padding: 1.5rem;
  margin-top: 1.5rem; }

.layout-boxed .product_right_column .content-box {
  margin-top: 0;
  padding: 0; }

@media (min-width: 1200px) {
  .container {
    max-width: 1440px; } }

.bright {
  color: #fff; }

.section {
  padding: 4em 0; }

main > .page-content {
  padding: 0 0 1.5rem 0; }

main > .page-header {
  margin-bottom: 3rem; }

.--updating::before {
  content: '';
  box-sizing: border-box;
  position: fixed;
  top: 50%;
  left: 50%;
  width: 48px;
  height: 48px;
  margin-top: -24px;
  margin-left: -24px;
  z-index: 9999;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.05);
  opacity: 0;
  animation: fadeIn2 500ms cubic-bezier(0.075, 0.82, 0.165, 1) forwards;
  transform: scale(0); }

.--updating:after {
  content: '';
  box-sizing: border-box;
  position: fixed;
  top: 50%;
  left: 50%;
  width: 36px;
  height: 36px;
  margin-top: -18px;
  margin-left: -18px;
  border: 2px solid rgba(0, 0, 0, 0.7);
  animation: spinner 800ms linear infinite;
  z-index: 10000;
  border-radius: 50%;
  border-top-color: rgba(0, 0, 0, 0.3); }

.--updating.--animout::before {
  opacity: 1;
  animation: fadeOut 300ms linear forwards; }

.product-miniature .add-to-cart.btn-text.--updating span:first-child {
  float: left; }

.product-miniature .add-to-cart.btn-text.--updating::before {
  display: none; }

.product-miniature .add-to-cart.btn-text.--updating::after {
  position: static;
  top: 0;
  left: 0;
  float: right;
  width: 16px;
  height: 16px;
  margin-top: .2rem;
  margin-left: .5rem;
  border-top-color: transparent; }

.product-add-to-cart .add-to-cart.--updating, .search-results.--updating, .product-miniature .add-to-cart.--updating {
  position: relative; }
  .product-add-to-cart .add-to-cart.--updating::before, .search-results.--updating::before, .product-miniature .add-to-cart.--updating::before {
    position: absolute;
    width: 30px;
    height: 30px;
    margin-top: -15px;
    margin-left: -15px;
    top: 50%;
    display: none; }
  .product-add-to-cart .add-to-cart.--updating::after, .search-results.--updating::after, .product-miniature .add-to-cart.--updating::after {
    top: 50%;
    width: 24px;
    height: 24px;
    margin-top: -12px;
    margin-left: -12px;
    position: absolute;
    border-top-color: transparent; }
  .product-add-to-cart .add-to-cart.--updating span, .search-results.--updating span, .product-miniature .add-to-cart.--updating span {
    opacity: 0; }

.product-miniature .add-to-cart.btn-primary.--updating::after, .product-miniature .add-to-cart.btn-secondary.--updating::after {
  border-color: #fff;
  border-top-color: transparent; }

.search-results.--updating::after {
  position: absolute;
  border-color: #ccc;
  border-top-color: #333; }

@keyframes fadeIn2 {
  0% {
    transform: scale(0);
    opacity: 0; }
  100% {
    transform: scale(1);
    opacity: 1; } }

@keyframes fadeOut {
  0% {
    opacity: 1;
    transform: scale(1); }
  100% {
    opacity: 0;
    transform: scale(1); } }

@keyframes spinner {
  0% {
    transform: rotate(0); }
  100% {
    transform: rotate(360deg); } }

.full-width {
  display: flex;
  justify-content: center;
  margin-right: calc(50% - 50vw);
  margin-left: calc(50% - 50vw); }

.full-width-inner {
  width: 100vw; }

.product-name {
  font-weight: bold;
  margin-bottom: 0; }

.modal-backdrop {
  background-color: rgba(0, 0, 0, 0.2); }

.modal-backdrop.show {
  opacity: 1; }

.overlay {
  background: rgba(0, 0, 0, 0.2);
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 8;
  cursor: pointer;
  transition: 500ms opacity ease;
  opacity: 0;
  visibility: hidden;
  pointer-events: none; }
  @media (min-width: 992px) {
    .overlay {
      top: 0; } }

@media (max-width: 991.99px) {
  .--cart-open, .--search-open, .--menu-open {
    overflow: hidden; } }

.--cart-open .overlay, .--search-open .overlay, .--menu-open .overlay {
  visibility: visible;
  opacity: 1;
  pointer-events: auto; }

.--search-open .overlay {
  top: 60px; }
  @media (min-width: 992px) {
    .--search-open .overlay {
      top: 0; } }

.cms-content ul {
  list-style-type: disc;
  padding-left: 1.5em; }

.img-bg .img-item {
  position: relative; }
  .img-bg .img-item::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.05);
    pointer-events: none; }

#authentication main .container, #order-confirmation main .container {
  max-width: 1024px; }

.left-column {
  /*padding-top: 1.5rem;*/ }
  .left-column ul {
    margin-bottom: 0; }
    .left-column ul li {
      padding-right: 1em;
      display: block;
      position: relative; }
      .left-column ul li[data-depth="1"], .left-column ul li[data-depth="2"], .left-column ul li[data-depth="3"] {
        padding-left: 1em; }
      .left-column ul li a {
        padding: .15em 0;
        display: block; }
      .left-column ul li .navbar-toggler {
        position: absolute;
        right: 0;
        top: 0px;
        padding: 0;
        z-index: 2; }
        .left-column ul li .navbar-toggler .add {
          display: none; }
        .left-column ul li .navbar-toggler.collapsed .add {
          display: block; }
        .left-column ul li .navbar-toggler.collapsed .remove {
          display: none; }
  .left-column .category-sub-menu ul li a {
    display: inline-block; }
  .left-column .block-categories .arrows[aria-expanded=true] .arrow-down {
    display: inline-block; }
  .left-column .block-categories .arrows .arrow-down,
  .left-column .block-categories .arrows[aria-expanded=true] .arrow-right {
    display: none; }
  .left-column .block-categories .arrows .arrow-down, .left-column .block-categories .arrows .arrow-right {
    font-size: .875rem;
    cursor: pointer;
    margin-left: 2px; }

.layout-boxed .left-column {
  padding-top: 0; }

.layout-left-side-column .page-content {
  /*margin-top: 2rem;*/
  padding-top: 15px; }

.layout-left-side-column .spacer {
  padding-top: 0; }

.layout-left-side-column .kaliber-block {
  padding: 1.25rem;
  margin-bottom: 1.25rem; }

#category main > .page-header {
  margin-bottom: 0; }

.carousel-products {
  position: relative;
  -webkit-tap-highlight-color: transparent;
  tap-highlight-color: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }
  .carousel-products .carousel-arrows {
    position: relative; }
  .carousel-products button.slick-arrow {
    /*transform: translateY(-100%);*/
    position: absolute;
    background: rgba(255, 255, 255, 0.5);
    cursor: pointer;
    border-width: 0;
    height: 32px;
    width: 32px;
    z-index: 1;
    top: 0; }
    .carousel-products button.slick-arrow:focus {
      outline: 0; }
    .carousel-products button.slick-arrow > svg {
      position: absolute;
      left: 0;
      top: 0;
      width: 32px;
      height: 32px; }
  .carousel-products button.slick-prev {
    left: 0; }
  .carousel-products button.slick-next {
    right: 0; }
  .carousel-products .slick-list {
    min-width: 100%; }
    .carousel-products .slick-list .slick-track {
      margin-left: 0;
      margin-right: 0; }

.site-brands .carousel-arrows button, .site-brands .carousel-arrows button > svg {
  height: 32px;
  width: 32px; }

/*Components*/
.site-header {
  position: fixed;
  left: 0;
  width: 100%;
  top: 0;
  z-index: 55;
  transition: 300ms ease all;
  backface-visibility: hidden; }
  @media (min-width: 992px) {
    .site-header {
      position: relative; } }

.header-top-alt {
  background: #000;
  color: #fff;
  padding: 8px 0px;
  font-size: 0.875rem; }

.header-top {
  width: 100%;
  background: #000;
  color: #fff;
  display: none;
  font-size: 0.875rem; }
  @media (min-width: 992px) {
    .header-top {
      display: block; } }
  .header-top .row > div {
    padding-left: 1rem; }
  .header-top a, .header-top button {
    text-transform: uppercase;
    padding: .7rem 0;
    display: block; }
  .header-top select {
    border: none; }
    .header-top select:focus {
      outline: none; }
  .header-top .dropdown-menu {
    margin-top: -10px; }
  .header-top .linklist ul {
    margin: 0; }
    .header-top .linklist ul li {
      display: inline;
      margin-right: .5em; }

.header-main {
  background: #fff;
  border-bottom: 1px solid #eee; }
  .header-main .header-icon {
    color: #000; }
  .header-main .widget-button {
    height: 26px; }

.header-main-inner {
  position: relative;
  height: 60px;
  padding: 1rem 0; }
  @media (min-width: 992px) {
    .header-main-inner {
      height: auto;
      padding: 0; } }

@media (max-width: 991.99px) {
  .header-logo {
    position: absolute;
    left: 0;
    width: 100%;
    text-align: center; }
    .header-logo img {
      height: 24px; } }

@media (min-width: 992px) {
  .header-logo {
    padding: 15px; } }

.header-logo a {
  display: inline-block;
  position: relative;
  z-index: 1; }

.header-menu {
  display: none; }
  @media (min-width: 992px) {
    .header-menu {
      display: block; } }
  @media (min-width: 992px) {
    .header-menu {
      align-self: stretch; } }

.cart-preview .header-icon {
  vertical-align: text-bottom; }

.header-right > .row {
  margin-right: 0;
  margin-left: -.7em; }
  .header-right > .row > .col,
  .header-right > .row > [class*="col-"] {
    padding-right: 0;
    padding-left: .4em; }
    @media (min-width: 768px) {
      .header-right > .row > .col,
      .header-right > .row > [class*="col-"] {
        padding-left: 1em; } }

.sticky-shadow .sticky-wrapper.is-sticky .stick {
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.08); }

.header-top-action {
  color: #fff; }

.hamburger {
  height: 20px;
  width: 24px;
  position: relative;
  display: block;
  cursor: pointer;
  overflow: hidden; }
  .hamburger > span {
    height: 2px;
    width: 100%;
    background: #000;
    display: block;
    position: absolute;
    top: 2px;
    left: 0;
    transition: 200ms transform ease-in-out;
    opacity: 1; }
    .hamburger > span:nth-child(2) {
      top: 10px; }
    .hamburger > span:last-child {
      top: 18px; }
  .hamburger:hover span {
    background: #31C9AA; }
  .hamburger.active span {
    background: #31C9AA; }
    .hamburger.active span:first-child {
      top: 10px;
      transform: rotate(-45deg); }
    .hamburger.active span:nth-child(2) {
      transform: translateX(-100%);
      opacity: 0; }
    .hamburger.active span:last-child {
      top: 10px;
      transform: rotate(45deg); }

.col-fill {
  display: none; }

.header-right {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
  flex: 0 0 100%;
  max-width: 100%;
  margin-left: auto; }

@media (min-width: 992px) {
  .header-logo {
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .header-menu {
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px; }
  .header-right {
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
    flex: 0 0 16.66667%;
    max-width: 16.66667%; } }

@media (min-width: 992px) {
  .centered-header .header-main-inner {
    height: auto;
    justify-content: center; }
  .centered-header .col-fill {
    display: block;
    order: 1;
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
    .centered-header .col-fill .header-top {
      background: none; }
    .centered-header .col-fill .header-top .row > div {
      padding-left: 0;
      padding-right: 1rem; }
  .centered-header #header > .header-top {
    display: none; }
  .centered-header .header-logo {
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
    order: 2;
    text-align: center; }
  .centered-header .header-right {
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
    order: 3;
    margin-left: 0; }
  .centered-header .header-menu {
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
    flex: 0 0 100%;
    max-width: 100%;
    order: 4; } }

@media (min-width: 992px) {
  .logo-left-menu-left .header-main-inner {
    padding-top: 1rem; }
  .logo-left-menu-left .header-menu {
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
    flex: 0 0 100%;
    max-width: 100%;
    order: 4; }
  .logo-left-menu-left .menu {
    text-align: left; } }

@media (min-width: 992px) {
  .is-sticky > div {
    backface-visibility: hidden;
    position: fixed;
    top: 0;
    width: 100%;
    left: 0; } }

.menu {
  position: static;
  text-align: center;
  padding: 0; }
  .menu > ul {
    width: 100%;
    margin: 0; }
    @media (min-width: 992px) {
      .menu > ul {
        height: 100%; } }
    .menu > ul > li {
      display: inline-block;
      padding: 1rem .5rem;
      font-size: 1em;
      cursor: pointer;
      position: relative; }
      @media (min-width: 992px) {
        .menu > ul > li {
          align-items: center;
          display: inline-flex;
          height: 100%; } }
      @media (min-width: 1200px) {
        .menu > ul > li {
          padding: 1rem .8rem; } }
      .menu > ul > li > a {
        display: inline-block;
        text-align: center;
        text-transform: uppercase;
        color: #000; }
      .menu > ul > li:hover > div {
        left: 0; }
      .menu > ul > li:hover a {
        position: relative; }
      .menu > ul > li:hover > a::after {
        position: absolute;
        bottom: -1rem;
        left: 0;
        content: "";
        width: 100%;
        height: 3px;
        display: block;
        background: currentColor; }
      .menu > ul > li > div {
        position: absolute;
        z-index: 8;
        left: -9999px;
        top: 100%;
        text-align: left;
        width: 18rem; }
        .menu > ul > li > div::before {
          border-top: 1px solid #eee;
          position: absolute;
          top: 0;
          z-index: -100;
          left: 0;
          right: 0;
          background: #fff;
          content: "";
          display: block;
          bottom: 0;
          box-shadow: 0 2px 2px rgba(0, 0, 0, 0.07); }
        .menu > ul > li > div > ul {
          padding: .5rem 0 .5rem 0; }
          .menu > ul > li > div > ul > li {
            line-height: 1.45;
            padding: .5rem 1rem;
            display: block;
            overflow: hidden; }
            .menu > ul > li > div > ul > li > a {
              display: inline-block; }
              .menu > ul > li > div > ul > li > a .icon {
                display: none; }
            .menu > ul > li > div > ul > li ul {
              margin-top: .5rem; }
      .menu > ul > li.mega {
        position: static; }
        .menu > ul > li.mega:hover > div {
          left: 0; }
        .menu > ul > li.mega > div {
          position: absolute;
          z-index: 8;
          left: -9999px;
          top: 100%;
          margin: 0 auto;
          text-align: left;
          width: 100%; }
          .menu > ul > li.mega > div::before {
            border-top: 1px solid #eee;
            position: absolute;
            top: 0;
            z-index: -100;
            left: -100%;
            right: -100%;
            background: #fff;
            content: "";
            display: block;
            bottom: 0;
            box-shadow: 0 2px 2px rgba(0, 0, 0, 0.07); }
          .menu > ul > li.mega > div > ul {
            padding: 1em 0 1rem 0;
            margin: 0 auto;
            max-width: 1440px;
            column-count: auto;
            column-width: 18rem; }
            .menu > ul > li.mega > div > ul > li {
              line-height: 1.45;
              padding: 1rem;
              page-break-inside: avoid;
              display: block;
              overflow: hidden; }
              .menu > ul > li.mega > div > ul > li > a {
                text-transform: uppercase;
                font-weight: bold;
                display: inline-block; }
                .menu > ul > li.mega > div > ul > li > a .icon {
                  display: none; }
              .menu > ul > li.mega > div > ul > li ul {
                margin-top: .5rem; }

.product-grid {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.js-product-miniature {
  animation: fadeIn 250ms ease; }

#product-list {
  position: relative; }
  #product-list.--updating:after, #product-list.--updating:before {
    top: 200px; }

/*.product-miniature {
    @include make-col-ready();
    @include make-col(3);
}
@include media-breakpoint-up(lg) {
    .desktop-half .product-grid .product-miniature {
        @include make-col(6);
    }
    .desktop-third .product-grid .product-miniature {
        @include make-col(4);
    }
    .desktop-fourth .product-grid .product-miniature {
        @include make-col(3);
    }
    .desktop-sixth .product-grid .product-miniature {
        @include make-col(2);
    }
    .desktop-full .product-grid .product-miniature {
        @include make-col(12);
    }
}

@include media-breakpoint-down(md) {
    .tablet-half .product-grid .product-miniature {
        @include make-col(6);
    }
    .tablet-third .product-grid .product-miniature {
        @include make-col(4);
    }
    .tablet-fourth .product-grid .product-miniature {
        @include make-col(3);
    }
    .tablet-sixth .product-grid .product-miniature {
        @include make-col(2);
    }
    .tablet-full .product-grid .product-miniature {
        @include make-col(12);
    }
}



@include media-breakpoint-down(sm) {
    .mobile-half .product-grid .product-miniature {
        @include make-col(6);
    }
    .mobile-third .product-grid .product-miniature {
        @include make-col(4);
    }
    .mobile-fourth .product-grid .product-miniature {
        @include make-col(3);
    }
    .mobile-sixth .product-grid .product-miniature {
        @include make-col(2);
    }
    .mobile-full .product-grid .product-miniature {
        @include make-col(12);
    }
}*/
.dropdown button > .icon {
  display: inline-block;
  transition: 100ms transform ease-in-out;
  font-size: 18px;
  margin-left: -3px; }

.dropdown.show button .icon {
  transform: rotate(180deg); }

.dropdown.show .dropdown-menu {
  max-height: 300px;
  overflow-y: auto;
  opacity: 1;
  visibility: visible; }

.dropdown-menu {
  border-radius: 0;
  border-color: #eee;
  background: #fff;
  padding: 0;
  margin-top: 0; }
  .dropdown-menu > ul {
    margin-bottom: 0; }
  .dropdown-menu li.dropdown-item {
    font-size: 0.875em;
    padding: 0; }
    .dropdown-menu li.dropdown-item.current > a {
      font-weight: bold; }

.dropdown-item a {
  padding: .8em 1em;
  display: block;
  position: relative;
  line-height: 1.25; }

.dropdown-menu-right {
  margin-right: -1px; }

.btn {
  padding: 0.8rem 1rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0;
  font-family: Arial, "Helvetica Neue", sans-serif;
  font-size: 1rem; }
  .btn:focus, .btn .focus {
    box-shadow: none !important; }
  .btn:hover {
    border-color: transparent; }

.btn-primary {
  background: #31C9AA;
  color: #fff;
  border-color: #31C9AA; }
  .btn-primary:hover {
    border-color: transparent;
    background: #1daa8d; }
  .btn-primary:active:not([disabled]):not(.disabled):active {
    border-color: transparent;
    background: #0e866d; }
  .btn-primary.disabled, .btn-primary:disabled {
    border-color: transparent;
    background: #E6E6E6;
    color: #C8C8C8; }
    .btn-primary.disabled:hover, .btn-primary:disabled:hover {
      background: #E6E6E6;
      color: #C8C8C8;
      cursor: not-allowed; }
  .btn-primary:focus {
    color: #fff; }

.btn-secondary {
  background: #6A7478;
  color: #fff; }
  .btn-secondary:hover {
    background: #222527;
    border-color: transparent; }
  .btn-secondary:active:not([disabled]):not(.disabled):active {
    background: #0a0b0c;
    border-color: transparent; }
  .btn-secondary.disabled, .btn-secondary:disabled {
    background: #E6E6E6;
    color: #C8C8C8;
    border-color: transparent; }

.btn-default {
  border: 1px solid #eee;
  box-sizing: border-box; }

.btn-link {
  color: #000;
  font-weight: normal;
  text-transform: none; }
  .btn-link:hover {
    color: #31C9AA; }

.btn-unstyle {
  border: none;
  background: none;
  padding: 0; }
  .btn-unstyle:focus, .btn-unstyle:active {
    outline: none; }

/* [class*="btn-outline-"] {
    text-transform: none;
    font-weight: normal;
} */
.btn-outline-secondary {
  background: none; }
  .btn-outline-secondary:focus, .btn-outline-secondary:active {
    outline: none;
    box-shadow: none; }

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.8rem;
  font-size: 0.875rem;
  line-height: 1.5rem;
  border-radius: 0; }

.btn-lg, .btn-group-lg > .btn {
  padding: 1rem 2rem;
  font-size: 1.2rem;
  line-height: 1.5rem;
  border-radius: 0; }

.spacer {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem; }

.breadcrumb[data-depth="1"] {
  display: none; }

.breadcrumb {
  border-radius: 0;
  margin: 0;
  background: none;
  padding: 0;
  display: block;
  font-size: 0.875em; }
  .breadcrumb ol {
    padding: 0;
    margin: 0;
    display: block;
    overflow: hidden;
    list-style: none; }
    .breadcrumb ol li {
      float: left;
      margin-right: .5rem; }
      .breadcrumb ol li::after {
        float: right;
        vertical-align: top;
        margin-left: .5rem;
        font-family: 'feather' !important;
        content: "/";
        opacity: .7; }
      .breadcrumb ol li:last-child::after {
        content: ""; }
      .breadcrumb ol li a {
        display: block;
        float: left;
        text-transform: uppercase; }
        .breadcrumb ol li a span {
          max-width: 200px;
          text-overflow: ellipsis;
          display: block;
          white-space: nowrap;
          overflow: hidden; }
      .breadcrumb ol li:last-child a {
        opacity: .5;
        pointer-events: none; }

.product-flag {
  background: #fff;
  padding: .1em .7em .15em .7em;
  font-size: 0.875em;
  z-index: 3;
  display: inline-block;
  color: #000; }
  .product-flag.new {
    background: #000;
    color: #fff; }
  .product-flag.on-sale {
    color: #fff;
    background: #E44A4A; }
  .product-flag.discount {
    background: #E44A4A;
    color: #fff; }
  @media (max-width: 991.99px) {
    .product-flag {
      font-size: 0.750em; } }

.product-price {
  margin-bottom: 0; }
  .product-price.has-discount .price {
    color: #E44A4A; }
  .product-price.has-discount .regular-price {
    text-decoration: line-through;
    display: inline-block;
    margin-left: .2em;
    color: #888; }
  .product-price.has-discount .discount-amount, .product-price.has-discount .discount-percentage {
    display: inline-block;
    margin-right: .2em; }

.product-miniature .product-price .price {
  font-size: 1em; }

.nav-tabs {
  background: none;
  border-bottom-style: solid;
  border-bottom-color: #eee;
  border-bottom-width: 1px;
  padding: 0 1.3rem; }
  .nav-tabs > .nav-item h2, .nav-tabs > .nav-item h3, .nav-tabs > .nav-item h4 {
    font-weight: normal;
    font-size: 1em;
    margin: 0; }
  .nav-tabs > .nav-item a {
    background: none;
    padding: 0.7em 1em .5em 1em;
    color: #3e3e3e;
    border: none;
    border-radius: 0; }
    .nav-tabs > .nav-item a.active {
      background: none;
      font-weight: bold;
      border-bottom: 3px solid;
      border-bottom-color: #000; }

.product-tabs > ul {
  display: none; }
  @media (min-width: 992px) {
    .product-tabs > ul {
      display: flex; } }

#tabToAcc .nav-tabs {
  display: none; }

#tabToAcc .card-header {
  border: none; }

#tabToAcc .tab-pane {
  border-bottom: 1px solid #eee; }
  #tabToAcc .tab-pane:last-child {
    border-bottom: none; }

@media (min-width: 992px) {
  #tabToAcc .nav-tabs {
    display: flex; }
  #tabToAcc .card {
    border: none; }
  #tabToAcc .card .card-header {
    display: none; }
  #tabToAcc .card .collapse {
    display: block; } }

@media (max-width: 991px) {
  #tabToAcc .tab-pane {
    display: block !important;
    opacity: 1; } }

#tabToAcc .tab-content ul {
  list-style: disc;
  list-style-position: inside; }
  #tabToAcc .tab-content ul li {
    padding-bottom: .5em; }

.tab-pane {
  padding: 1em 0; }

.form-control {
  border: 1px solid #eee;
  color: initial;
  border-radius: 0;
  background: #fff;
  color: #000; }
  .form-control::-webkit-input-placeholder {
    color: #999; }
  .form-control:focus {
    outline: none;
    border-color: #666;
    box-shadow: none;
    background: #fff; }

select.form-control:not([size]):not([multiple]) {
  height: auto; }

input[type=text], input[type=email], input[type=password], select, .form-control {
  width: 100%;
  border: 1px solid #eee;
  padding: .8rem .7rem;
  transition: 200ms ease all;
  border-radius: 0;
  font-size: 1rem; }
  input[type=text]:focus, input[type=email]:focus, input[type=password]:focus, select:focus, .form-control:focus {
    background: #fff;
    outline: none;
    border-color: #666;
    box-shadow: none; }
  input[type=text].not-empty, input[type=email].not-empty, input[type=password].not-empty, select.not-empty, .form-control.not-empty {
    border-color: #31C9AA; }

textarea, textarea.form-control {
  width: 100%;
  border: 1px solid #eee;
  padding: 1em;
  transition: 200ms ease all; }

label.required::after {
  content: "*";
  display: inline-block;
  color: #E44A4A; }

.option-input-label {
  position: relative;
  padding-left: 2.3em;
  line-height: 1.6rem; }
  .option-input-label::before {
    content: "";
    display: inline-block;
    height: 1.6rem;
    width: 1.6rem;
    border: 2px solid #ccc;
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 2px;
    font-size: 0;
    line-height: 1;
    transition: 200ms ease all; }
  .option-input-label:hover::before {
    background: #f5f5f5;
    border-color: #999; }
  .option-input-label.radio::before {
    border-radius: 100%;
    content: ""; }
  .option-input-label a {
    text-decoration: underline; }

.option-input {
  display: none !important;
  height: 0;
  width: 0; }
  .option-input:checked + label::before {
    border-color: #3e3e3e;
    content: "\e83f";
    font-family: 'feather' !important;
    font-size: 1.3rem; }
  .option-input.radio:checked + label::before {
    content: "";
    background: #3e3e3e;
    box-shadow: inset 0 0 0 4px #ffffff; }

.group-span-filestyle label {
  margin-bottom: 0; }

.alert {
  border-radius: 0;
  border-width: 2px;
  background: none; }

.notification {
  margin-top: 1rem;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  max-width: 1440px; }
  .notification ul {
    border: 2px solid #000;
    padding: 1rem; }
  .notification-danger ul {
    border-color: #dc3545;
    color: #dc3545; }
  .notification-success {
    border-color: #28a745;
    color: #28a745; }
  .notification-info {
    border-color: #007bff;
    color: #007bff; }
  .notification-warning {
    border-color: #ffc107;
    color: #ffc107; }

.pagination {
  width: 100%;
  display: block; }
  .pagination .icon {
    font-size: 18px; }
  .pagination .pagination-links {
    text-align: right; }
    .pagination .pagination-links > ul {
      display: inline-block;
      margin-bottom: 0; }
      .pagination .pagination-links > ul > li {
        float: left; }
        .pagination .pagination-links > ul > li a {
          display: block;
          padding: .5em 1em; }
        .pagination .pagination-links > ul > li:not(.current) .disabled {
          opacity: .5;
          pointer-events: none; }
        .pagination .pagination-links > ul > li.current a {
          font-weight: bold; }
        .pagination .pagination-links > ul > li.current .disabled {
          pointer-events: none; }

.pagination-mobile {
  position: relative;
  text-align: center;
  padding: .8em 0;
  display: block; }
  .pagination-mobile > a {
    position: absolute;
    display: block;
    top: 0;
    padding: .7em 1em;
    box-shadow: 1px 0 0 0 #eee, 0 1px 0 0 #eee, 1px 1px 0 0 #eee, 1px 0 0 0 #eee inset, 0 1px 0 0 #eee inset; }
    .pagination-mobile > a.left {
      left: 0; }
    .pagination-mobile > a.right {
      right: 0; }
    .pagination-mobile > a.disabled {
      pointer-events: none;
      opacity: .5; }

.listing-text-center .product-miniature {
  text-align: center; }

.product-miniature {
  position: relative;
  margin-bottom: 2rem; }
  .product-miniature .thumbnail-container {
    position: relative;
    margin-bottom: 1rem;
    height: auto !important;
    overflow: hidden; }
  .product-miniature .thumbnail {
    display: block; }
    .product-miniature .thumbnail img {
      width: 100%;
      height: auto;
      display: block; }
    .product-miniature .thumbnail .thumbnail-overlay {
      background-color: rgba(0, 0, 0, 0.05);
      position: absolute;
      top: 0;
      right: 0;
      bottom: 5px;
      left: 0;
      z-index: 1; }
  .product-miniature .product-name {
    display: block;
    margin-bottom: .3rem; }
  .product-miniature .product-flags {
    position: absolute;
    top: 10px;
    right: 10px;
    margin: 0; }
  .product-miniature .product-price {
    font-size: 1em; }
  .product-miniature .variant-links a.color {
    text-indent: -9999px;
    display: inline-block;
    height: 1rem;
    width: 1rem;
    border-radius: 50%;
    background-size: 1rem;
    box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.2); }
  .product-miniature .product-list-actions {
    margin-top: .5rem; }
    .product-miniature .product-list-actions form {
      display: inline; }
  .product-miniature .quick-view {
    position: absolute;
    bottom: 0;
    right: 0;
    background: rgba(255, 255, 255, 0.6);
    padding: 1em;
    font-weight: bold;
    font-size: .750em;
    text-transform: uppercase;
    display: none;
    color: #000; }
  .product-miniature .add-to-cart .mini .icon {
    font-size: 16px; }
  .product-miniature .variant-links {
    margin-top: .3rem; }
  .product-miniature:hover .quick-view {
    display: block; }

.product_right_column .quick-view i {
  font-size: 12px; }

.product_right_column .quick-view span {
  display: none; }

.product_right_column .variant-links a.color {
  width: .5rem;
  height: .5rem; }

.hide-quickview .quick-view {
  display: none !important; }

.hide-add .product-miniature .product-list-actions {
  display: none; }

.hide-variants .highlighted-informations {
  display: none; }

.brand {
  opacity: .5; }
  @media (max-width: 991.99px) {
    .brand img {
      height: 35px; } }
  .brand:hover {
    opacity: 1; }

#search_filters_brands {
  background: #fff;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: #eee; }

.site-footer {
  margin-top: 4em;
  background: #000;
  color: #fff; }
  .site-footer h4 {
    color: var(--theme-footer-text); }
  .site-footer a {
    color: rgba(255, 255, 255, 0.4); }
    .site-footer a:hover {
      color: #31C9AA; }
  .site-footer ul > li {
    margin-bottom: .3em; }
  .site-footer .row {
    margin-bottom: 1em; }
    .site-footer .row:last-child {
      margin-bottom: 0; }
  .site-footer .footer-section {
    padding: 2rem 0; }
    .site-footer .footer-section.--first {
      padding: 4rem 0 2rem 0; }
    .site-footer .footer-section.--last {
      background: #fff;
      color: #212529;
      padding: 1rem 0;
      margin-top: 1em; }
      .site-footer .footer-section.--last a {
        color: #202020; }
  .site-footer .footer-col {
    margin-bottom: 1em; }
  .site-footer .block-title {
    font-weight: bold;
    margin-bottom: .5em;
    text-transform: uppercase; }

.email_subscription input[type=text] {
  width: 100%;
  background: none;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(255, 255, 255, 0.4);
  border-right: none;
  height: 55px;
  padding-left: 1.5em;
  border-right: none; }
  .email_subscription input[type=text]:focus {
    color: var(--theme-footer-text); }

.email_subscription input[type=submit] {
  background: none;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(255, 255, 255, 0.4);
  height: 55px;
  display: block;
  border-radius: 0 !important;
  padding-left: 2rem;
  padding-right: 2rem; }
  .email_subscription input[type=submit]:hover {
    background: rgba(255, 255, 255, 0.4); }

.email_subscription .sub_form {
  position: relative; }

.email_subscription .notification {
  margin-top: .5em;
  border: 2px solid;
  border-color: rgba(255, 255, 255, 0.4);
  text-align: center;
  padding: .5em; }
  .email_subscription .notification.notification-success {
    border-color: #28a745;
    color: #28a745; }
  .email_subscription .notification.notification-error {
    border-color: #dc3545;
    color: #dc3545; }

.modal-content {
  border-radius: 0;
  border: none; }

.quickview .product-cover .layer {
  display: none !important; }

.quickview .product-price .product-price-current {
  font-size: 2em;
  font-weight: bold;
  color: #000; }

.quickview .product-price .regular-price {
  color: #999;
  text-decoration: line-through; }

.quickview .product-price .discount-percentage, .quickview .product-price .discount-amount {
  color: red; }

.qty {
  font-size: .875em; }
  .qty .js-cart-line-product-quantity, .qty #quantity_wanted {
    text-align: center;
    height: 3.3em;
    margin: 0;
    width: calc(2.5em + 5em);
    padding: 0;
    display: block;
    box-sizing: content-box;
    font-size: 1em; }
  .qty.qty-small {
    font-size: .750em; }

.bootstrap-touchspin {
  position: relative;
  padding: 0 2.5em;
  border: 1px solid #eee;
  height: 3.3em;
  box-sizing: content-box;
  width: 2.5em; }
  .bootstrap-touchspin #quantity_wanted {
    border: none !important; }
  .bootstrap-touchspin .js-cart-line-product-quantity, .bootstrap-touchspin #quantity_wanted {
    width: 2.5em;
    border: none;
    font-size: 1em; }
  .bootstrap-touchspin .input-group-btn-vertical {
    display: block;
    width: auto;
    position: static; }
  .bootstrap-touchspin .btn.btn-touchspin {
    position: absolute;
    background: none;
    width: 2.5em;
    height: 3.3em;
    text-align: center;
    padding: 0;
    line-height: calc(1em + 0.7em);
    border: none;
    margin: 0;
    font-size: 1em; }
  .bootstrap-touchspin .btn.bootstrap-touchspin-down {
    left: 0;
    right: auto; }
  .bootstrap-touchspin .btn.bootstrap-touchspin-up {
    right: 0;
    left: auto; }
  .bootstrap-touchspin .input-group-btn-vertical i {
    position: static;
    font-size: 12px;
    font-weight: 400;
    color: #000; }

@media (min-width: 992px) {
  .products-sort-order {
    float: right; } }

.products-sort-order > button {
  text-align: right;
  background: none;
  width: 100%;
  padding: .5rem 0; }
  @media (min-width: 992px) {
    .products-sort-order > button {
      width: auto; } }
  .products-sort-order > button:focus, .products-sort-order > button:active {
    outline: none; }

body.--menu-open .off-canvas-menu {
  transform: translateY(0);
  pointer-events: auto;
  overflow-y: auto;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.07); }

.off-canvas-menu {
  background: #F5F5F5;
  position: fixed;
  z-index: 60;
  width: 80vw;
  transition: 500ms all cubic-bezier(0.19, 1, 0.22, 1);
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0);
  transform: translateX(-100%);
  border-top: 1px solid #eee;
  overflow: auto;
  overflow: hidden;
  top: 0;
  pointer-events: none;
  height: calc(100vh); }
  @media (min-width: 992px) {
    .off-canvas-menu {
      display: none; } }
  .off-canvas-menu .icon {
    display: inline-block;
    position: relative;
    transition: 100ms transform ease-in-out; }
  .off-canvas-menu #menu-close {
    position: absolute;
    top: 1rem;
    left: .8rem;
    cursor: pointer;
    z-index: 3; }
  .off-canvas-menu li.open > a {
    color: #31C9AA; }
    .off-canvas-menu li.open > a > .icon {
      transform: rotate(-180deg); }
  .off-canvas-menu > ul {
    margin: 4rem 0 1rem 0; }
    .off-canvas-menu > ul > li > a {
      display: block;
      padding: .6rem 1rem;
      position: relative;
      font-size: 1em; }
      .off-canvas-menu > ul > li > a > .icon {
        float: right; }
      .off-canvas-menu > ul > li > a:hover {
        color: #31C9AA; }
    .off-canvas-menu > ul > li > ul {
      display: none; }
      .off-canvas-menu > ul > li > ul > li > a {
        display: block;
        padding: 0.3em 1em 0.3em 3em; }
        .off-canvas-menu > ul > li > ul > li > a .icon {
          font-size: 16px; }
      .off-canvas-menu > ul > li > ul > li > ul {
        display: none; }
        .off-canvas-menu > ul > li > ul > li > ul > li > a {
          display: block;
          padding: .3em 1em .3em 5em; }
        .off-canvas-menu > ul > li > ul > li > ul > li > ul {
          display: none; }
    .off-canvas-menu > ul > li:last-child a {
      border-bottom: none; }

.shop-options {
  padding: 1rem; }

.shop-options button {
  padding: .5rem 1rem;
  background: #fff;
  width: 100%;
  text-align: left;
  border: 1px solid #eee;
  line-height: 24px; }
  .shop-options button span {
    float: left; }
  .shop-options button .button-label {
    margin-right: 1rem; }

.shop-options .dropdown-menu {
  position: static !important;
  top: 0;
  left: 0;
  width: 100%;
  transform: translate3d(0, 0, 0) !important;
  border: none;
  padding: .3em 0; }

.shop-options a {
  padding: .3em 1em; }

.shop-options .icon {
  float: right; }

.shop-options .shop-option {
  margin-bottom: .5rem; }
  .shop-options .shop-option:last-child {
    border-bottom: 0; }
  .shop-options .shop-option.show button {
    border-bottom: 1px solid #eee; }
  .shop-options .shop-option > a {
    display: block;
    padding: 1rem .1rem; }

/*Modules*/
.block-reassurance .reassurance-item {
  margin-bottom: .5rem; }

.block-reassurance .reassurance-text {
  padding-left: 1em;
  font-size: 1.250em; }

.cart-preview {
  position: relative; }
  .cart-preview .header > a {
    text-decoration: none;
    position: relative; }
  .cart-preview .summary .--blink {
    animation: blink 300ms cubic-bezier(0.75, 0.34, 0.32, 0.62) 3 normal; }
  @media (min-width: 992px) {
    .cart-preview .summary span {
      font-size: 14px; } }
  .cart-preview .summary__count {
    background: #31C9AA;
    border-radius: 50%;
    height: 17px;
    width: 17px;
    text-align: center;
    color: #fff;
    line-height: 17px;
    position: absolute;
    top: -14px;
    right: -3px;
    font-size: 10px;
    transform-origin: center; }
    @media (min-width: 992px) {
      .cart-preview .summary__count {
        background: none;
        position: static;
        text-align: center;
        border-radius: 0;
        line-height: 20px;
        display: inline-block;
        height: 20px;
        color: #000;
        vertical-align: text-bottom; } }
  .cart-preview .summary__label {
    display: none; }
    @media (min-width: 992px) {
      .cart-preview .summary__label {
        display: inline-block;
        vertical-align: text-bottom; } }
  .cart-preview .body-wrapper {
    display: flex;
    flex-direction: column; }
  .cart-preview .body {
    background: #fff;
    right: 0;
    width: 80vw;
    z-index: 25;
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
    will-change: transform;
    transition: 500ms all cubic-bezier(0.19, 1, 0.22, 1);
    box-shadow: -2px 2px 2px rgba(0, 0, 0, 0.07);
    bottom: 0;
    position: fixed;
    top: 0;
    transform: translateX(100%);
    display: flex;
    flex-direction: column; }
    .cart-preview .body.visible {
      transform: translateX(0);
      opacity: 1;
      visibility: visible;
      pointer-events: auto; }
    .cart-preview .body #cart-close {
      position: absolute;
      top: .5rem;
      right: .5rem;
      cursor: pointer; }
      @media (min-width: 992px) {
        .cart-preview .body #cart-close {
          right: 1rem; } }
    @media (min-width: 992px) {
      .cart-preview .body {
        box-shadow: 0 2px 2px rgba(0, 0, 0, 0.07);
        position: absolute;
        top: 150%;
        width: 35rem;
        bottom: auto;
        height: auto;
        transform: perspective(40cm) scale(0.9, 0.9) rotateX(5deg);
        transform-origin: top right;
        display: block; }
        .cart-preview .body.visible {
          opacity: 1;
          transform: perspective(40cm) scale(1, 1) rotateX(0deg);
          visibility: visible;
          pointer-events: auto; }
        .cart-preview .body::after {
          content: "";
          display: block;
          width: 0;
          height: 0;
          border-left: 10px solid transparent;
          border-right: 10px solid transparent;
          border-bottom: 10px solid #fff;
          position: absolute;
          top: -9px;
          right: 11px;
          z-index: 2; } }
    @media (min-width: 992px) and (min-width: 992px) {
      .cart-preview .body::after {
        right: 24px; } }
    .cart-preview .body .blockcart {
      flex: 1;
      display: flex;
      flex-direction: column; }
      @media (min-width: 992px) {
        .cart-preview .body .blockcart {
          display: block; } }
      .cart-preview .body .blockcart > ul {
        flex: 1;
        margin-bottom: 0;
        height: auto;
        overflow-y: auto;
        padding: 0 1rem; }
        @media (min-width: 992px) {
          .cart-preview .body .blockcart > ul {
            max-height: 500px;
            display: block; } }
        .cart-preview .body .blockcart > ul > li {
          font-size: .875em;
          padding: .5rem 0;
          border-bottom: 1px solid #eee; }
          .cart-preview .body .blockcart > ul > li .row {
            margin-left: -7px;
            margin-right: -7px; }
            .cart-preview .body .blockcart > ul > li .row [class*="col-"] {
              padding-right: 7px;
              padding-left: 7px; }
          @media (min-width: 992px) {
            .cart-preview .body .blockcart > ul > li {
              border-top: none; }
              .cart-preview .body .blockcart > ul > li:last-child {
                border-bottom: none; } }
    .cart-preview .body .cart-total {
      padding: 1rem;
      border-top: 1px solid #eee; }
      .cart-preview .body .cart-total .value {
        font-size: 1.375em;
        font-weight: bold; }
    .cart-preview .body .cart-body-header {
      margin: 0;
      padding: .7rem 1rem;
      border-bottom: 1px solid #eee; }
      @media (min-width: 992px) {
        .cart-preview .body .cart-body-header {
          border-top: none; } }
    .cart-preview .body .no-items {
      border-bottom: 1px solid #eee; }

#cart-preview-toggle {
  cursor: pointer; }

.hidden-on-scroll .cart-preview .body {
  top: 70px; }

@keyframes blink {
  0% {
    transform: scale(0.8); }
  50% {
    transform: scale(1.2); }
  100% {
    transform: scale(1); } }

.cart-drawer .cart-preview .body {
  background: #fff;
  right: 0;
  width: 80vw;
  max-width: 450px;
  z-index: 25;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  will-change: transform;
  transition: 500ms all cubic-bezier(0.19, 1, 0.22, 1);
  box-shadow: -2px 2px 2px rgba(0, 0, 0, 0.07);
  height: auto;
  position: fixed;
  bottom: 0;
  top: 0;
  transform: translateX(100%);
  display: flex;
  flex-direction: column; }
  .cart-drawer .cart-preview .body.visible {
    transform: translateX(0);
    opacity: 1;
    visibility: visible;
    pointer-events: auto; }

@media (max-width: 991px) {
  #blockcart-wrapper {
    padding-left: 0.4em; }
  .cart-preview .summary__count {
    top: -5px; } }

.user-info span {
  display: inline-block; }

@media (min-width: 992px) {
  .user-info .icon {
    font-size: 18px; } }

.layout-boxed #search_filters {
  border-bottom: none;
  padding: 1.5rem;
  padding-bottom: .5rem; }

.layout-boxed .filter-sidebar .dropdown-menu {
  background: none; }

.layout-boxed .facet {
  margin-top: 0; }

body:not(.layout-boxed) #search_filters {
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #eee; }

#search_filters {
  margin-bottom: 1rem; }

#search_filters .card-header a:not(.collapsed) .icon {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg); }

#search_filters .card-header .icon {
  transition: 100ms -webkit-transform ease-in-out;
  transition: 100ms transform ease-in-out;
  transition: 100ms transform ease-in-out, 100ms -webkit-transform ease-in-out; }

@media (min-width: 992px) {
  .horizontal-filter .card-header {
    display: none; } }

.horizontal-filter .facets {
  padding: 0; }

.filter_fullwidth .filter-list {
  margin-right: 0; }

.filter_fullwidth .filter-list > li {
  margin-bottom: .5rem;
  margin-left: 0;
  margin-right: .5rem; }

.filter_fullwidth .reset-filters {
  display: block;
  float: none;
  width: 100%;
  text-align: center;
  background: rgba(0, 0, 0, 0.03);
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 5rem;
  padding: 0.15rem 0.3rem; }

.filter_left_column .js-search-link {
  display: inline; }

.filter_left_column .filter-list {
  margin-right: 0; }

.filter_left_column .filter-list > li {
  margin-bottom: .5rem;
  margin-left: 0;
  margin-right: .5rem; }

.filter_left_column .card-header {
  background-color: rgba(0, 0, 0, 0.03); }

.filter_left_column .reset-filters {
  display: block;
  float: none;
  width: 100%;
  text-align: center;
  background: rgba(0, 0, 0, 0.01);
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 5rem;
  padding: 0.35rem 0.3rem;
  margin-bottom: 0.75rem; }

@media (min-width: 992px) {
  .left-column .card-header {
    display: none; } }

.left-column .facets .card-header {
  background-color: rgba(0, 0, 0, 0.03); }

.left-column .facets {
  padding: 0; }

.facet {
  margin-top: 1rem;
  margin-bottom: 1rem;
  margin-right: 1rem; }
  @media (min-width: 992px) {
    .facet {
      float: left;
      position: relative; } }
  .facet > button {
    text-transform: uppercase;
    cursor: pointer; }
  .facet .dropdown-menu {
    right: auto;
    margin: 1px 0;
    left: -1px !important; }
    .facet .dropdown-menu > ul {
      padding: .5em 0; }

.active_filters {
  width: 100%; }
  .active_filters .filter-list {
    margin-right: .5em; }
  .active_filters .content-box {
    padding-top: 1em;
    padding-bottom: 0; }
  @media (min-width: 992px) {
    .active_filters span, .active_filters .filter-list, .active_filters p {
      display: inline; } }
  .active_filters > span {
    font-weight: bold; }
  .active_filters p {
    margin: 0; }
    @media (min-width: 992px) {
      .active_filters p {
        float: right; } }

.filter-list {
  margin: 0; }
  .filter-list > li {
    padding: 0.35rem 0.6rem;
    font-weight: normal;
    border: 1px solid #eee;
    border-radius: 5rem;
    float: left;
    margin-left: .5rem;
    line-height: 1; }
    .filter-list > li > span {
      font-size: 0.875em; }
    .filter-list > li .icon {
      font-size: 14px; }

.filter-option > a {
  padding: .5rem 1rem .5rem 2.3rem;
  display: block;
  position: relative;
  line-height: 1.3rem; }
  .filter-option > a .magnitude {
    color: #999; }

.filter-option .color {
  position: absolute;
  left: 10px;
  top: 7px;
  height: 1.3rem;
  width: 1.3rem;
  display: block;
  z-index: -1;
  border-radius: 50%;
  background-size: 1.3rem;
  box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.2); }

.filter-option a.has-color::before {
  display: none !important; }

.filter-option a.has-color.active .color {
  border: 2px solid #000; }

.filter-option a.has-color:hover .color {
  box-shadow: inset 0 0 0 2px rgba(255, 255, 255, 0.4); }

.filter-option.option-check a::before {
  content: "";
  display: block;
  height: 1.3rem;
  width: 1.3rem;
  border: 2px solid #eee;
  position: absolute;
  left: 10px;
  top: 7px;
  border-radius: 2px;
  font-size: 1.2em;
  line-height: 17px;
  text-align: center; }

.filter-option.option-check a.active::before {
  border-color: #3e3e3e;
  content: "\e83f";
  font-family: 'feather' !important; }

.filter-option.option-radio a::before {
  content: "";
  display: block;
  height: 1.3rem;
  width: 1.3rem;
  border: 2px solid #eee;
  position: absolute;
  left: 10px;
  top: 7px;
  border-radius: 50%; }

.filter-option.option-radio a.active::before {
  content: "";
  background: #3e3e3e;
  box-shadow: inset 0 0 0 4px #ffffff; }

.filter-option.option-radio .color {
  border-radius: 100%; }

.filter-option:hover a::before {
  border-color: #999; }

.filter-option:last-child a {
  border-bottom: none; }

.left-column .facets .dropdown-menu {
  display: block;
  position: static;
  float: none;
  border: none;
  background: none; }

.left-column .facets .facet {
  margin-top: 0; }
  .left-column .facets .facet > button {
    pointer-events: none; }
    .left-column .facets .facet > button .icon {
      display: none; }
  @media (min-width: 992px) {
    .left-column .facets .facet {
      float: none;
      position: relative; } }

.left-column .facets .filter-option.option-check a::before, .left-column .facets .filter-option.option-radio a::before {
  left: 0;
  top: 2px; }

.left-column .facets .filter-option > a {
  padding: .2rem 1rem .2rem 2.1rem; }

.left-column .facets .filter-option.option-check a::before {
  top: 3px; }

.left-column .facets .filter-option .color {
  z-index: 0;
  left: 0;
  top: 3px; }

.left-column #search_filters {
  border-bottom: none !important; }

/*Module Custom Text ps_customtext */
.custom-text-block {
  margin: 2rem 0 2rem 0; }
  .custom-text-block .content-box {
    background: #fff;
    color: initial;
    padding: 5rem 0; }

.social-sharing > span {
  display: block;
  margin-bottom: .5em; }

.social-sharing > ul {
  margin: 0;
  padding: 0; }
  .social-sharing > ul li {
    margin-right: 10px;
    display: inline-block; }
    .social-sharing > ul li > a {
      display: block;
      border: 1px solid #eee;
      padding: .7rem;
      border-radius: 50%; }

.social-sharing a:hover .social-icon {
  fill: #000; }

.social-sharing .social-icon {
  height: 16px;
  width: 16px;
  fill: #999; }

.slideshow-narrow .home-slideshow {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  margin-top: 2rem;
  max-width: 1440px; }

.slideshow-text-centered .text-container {
  justify-content: center; }

.slideshow-text-centered .home-slideshow .slide {
  text-align: center; }

.home-slideshow {
  position: relative;
  overflow: hidden;
  z-index: 5;
  margin-bottom: 2rem; }
  .home-slideshow .slide {
    position: relative;
    font-size: 1em; }
    @media (min-width: 768px) {
      .home-slideshow .slide {
        font-size: 1.2em; } }
    @media (min-width: 992px) {
      .home-slideshow .slide {
        font-size: 1.7em; } }
    @media (min-width: 1200px) {
      .home-slideshow .slide {
        font-size: 2em; } }
    .home-slideshow .slide .container {
      width: 100%; }
    .home-slideshow .slide a > .content {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      display: flex;
      padding: 3em 0;
      width: 100%;
      color: #fff;
      opacity: 0;
      transition: 600ms all cubic-bezier(0.64, 0.2, 0.43, 0.88); }
    .home-slideshow .slide p {
      font-size: .875em; }
      @media (min-width: 992px) {
        .home-slideshow .slide p {
          font-size: .750em; } }
    .home-slideshow .slide h1, .home-slideshow .slide h2, .home-slideshow .slide h3, .home-slideshow .slide h4, .home-slideshow .slide h5, .home-slideshow .slide h6, .home-slideshow .slide .h1, .home-slideshow .slide .h2, .home-slideshow .slide .h3, .home-slideshow .slide .h4, .home-slideshow .slide .h5, .home-slideshow .slide .h6 {
      color: #fff;
      line-height: 1;
      margin-bottom: 1rem; }
    .home-slideshow .slide img {
      width: 200%;
      max-width: none;
      height: auto;
      margin-left: -100%; }
      @media (min-width: 992px) {
        .home-slideshow .slide img {
          width: 100%;
          margin-left: 0; } }
    .home-slideshow .slide .text-content {
      position: relative;
      width: 100%;
      min-height: 1px;
      padding-right: 15px;
      padding-left: 15px;
      flex: 0 0 83.33333%;
      max-width: 83.33333%;
      margin-left: 8.33333%; }
      @media (min-width: 768px) {
        .home-slideshow .slide .text-content {
          flex: 0 0 50%;
          max-width: 50%; } }
      @media (min-width: 1200px) {
        .home-slideshow .slide .text-content {
          margin-left: 0;
          flex: 0 0 66.66667%;
          max-width: 66.66667%;
          padding: 0 5rem; } }
    .home-slideshow .slide.homeslider1_on a > .content {
      opacity: 1; }

.slides-control span {
  display: block;
  height: 20px;
  width: 20px;
  background: black; }

.homeslider_tabs {
  padding-top: 1em;
  text-align: center;
  margin: 0; }
  .homeslider_tabs li {
    display: inline-block;
    width: 12px;
    margin: 0 .2em; }
    .homeslider_tabs li > a {
      background: #999;
      text-indent: -9999px;
      border: none;
      border-radius: 50%;
      display: block;
      height: 12px;
      width: 12px; }
      .homeslider_tabs li > a:focus {
        outline: none; }
    .homeslider_tabs li.homeslider_here a {
      background: #31C9AA; }

@media (min-width: 768px) {
  .homeslider_tabs {
    position: absolute;
    top: 50%;
    right: 2em;
    transform: translateY(-50%);
    z-index: 44; }
    .homeslider_tabs li {
      display: block; }
    .homeslider_tabs > li > a {
      display: block;
      min-width: 20px;
      border-bottom: 2px solid #fff;
      text-align: right;
      float: right;
      color: #fff;
      font-size: 1.143em;
      transition: 300ms all ease-in-out;
      margin-bottom: .5em;
      text-decoration: none;
      background: none !important;
      border-radius: 0;
      height: auto;
      width: auto; }
      .homeslider_tabs > li > a:hover {
        min-width: 30px;
        color: #31C9AA;
        border-color: currentColor; }
      .homeslider_tabs > li > a:focus, .homeslider_tabs > li > a:active {
        outline: none;
        text-decoration: none; }
    .homeslider_tabs .homeslider_here a {
      background: none;
      min-width: 30px;
      color: #31C9AA;
      border-color: currentColor; } }

.block-social > ul {
  margin: 0;
  padding: 0; }
  .block-social > ul li {
    display: inline-block; }

.block-social a > span {
  display: none; }

.block-social a:hover .social-icon {
  fill: #31C9AA; }

.site-footer .social-icon {
  height: 24px;
  width: 24px;
  fill: rgba(255, 255, 255, 0.4);
  vertical-align: middle; }

.site-footer .block-social > ul li {
  margin-bottom: .5em;
  display: inline-block;
  margin-right: .8em; }

.site-footer .block-social a > span {
  margin-left: .5em;
  display: inline-block; }

#search-trigger {
  display: inline;
  cursor: pointer; }

.searchbar .header > a {
  text-decoration: none;
  position: relative; }

.searchbar .body {
  background: #fff;
  position: fixed;
  right: 0;
  top: 60px;
  width: 100%;
  z-index: 20;
  visibility: hidden;
  opacity: 0;
  transform: perspective(40cm) scale(0.9, 0.9) rotateX(5deg);
  transform-origin: top right;
  transition: 500ms all cubic-bezier(0.19, 1, 0.22, 1);
  pointer-events: none;
  will-change: transform;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.07); }
  .searchbar .body #search-close {
    position: absolute;
    top: .6rem;
    right: 1rem;
    cursor: pointer;
    z-index: 3; }
  .searchbar .body.visible {
    opacity: 1;
    transform: perspective(40cm) scale(1, 1) rotateX(0deg);
    visibility: visible;
    pointer-events: auto; }
  @media (min-width: 992px) {
    .searchbar .body {
      top: 150%;
      right: -25px;
      width: 35rem;
      position: absolute; }
      .searchbar .body::after {
        content: "";
        display: block;
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid #fff;
        position: absolute;
        top: -9px;
        right: 11px;
        z-index: 2; } }
    @media (min-width: 992px) and (min-width: 992px) {
      .searchbar .body::after {
        right: 26px; } }

.search-query {
  border-top: 1px solid #eee;
  position: relative; }
  @media (min-width: 992px) {
    .search-query {
      border-top: none; } }
  .search-query .icon {
    position: absolute;
    top: 50%;
    margin-top: -12px;
    left: 10px; }
  .search-query input[type=text] {
    border: none;
    padding-left: 2.5em;
    font-size: 16px; }
    .search-query input[type=text]::-ms-clear {
      display: none;
      width: 0;
      height: 0; }

.not-found {
  display: none;
  padding: 1em; }
  .not-found .h3 {
    font-size: 1em; }
  .not-found p {
    margin: 0; }

.search-results {
  border-top: 1px solid #eee; }
  .search-results.has-results .not-found {
    display: none; }
  .search-results.no-results .not-found {
    display: block; }

.js-search-results {
  margin: 0;
  padding: 0 1rem; }
  .js-search-results > li {
    font-size: .875em;
    padding: .5rem 0;
    border-bottom: 1px solid #eee; }
    .js-search-results > li .row {
      margin-left: -7px;
      margin-right: -7px; }
      .js-search-results > li .row [class*="col-"] {
        padding-right: 7px;
        padding-left: 7px; }
    .js-search-results > li:last-child {
      border-bottom: none; }
    .js-search-results > li .product-description > *:last-child {
      margin-bottom: 0; }

.js-more-results {
  border-top: 1px solid #eee; }

#search_widget {
  padding-right: 0;
  padding-left: 0; }

/*Pages*/
.product {
  background: none;
  margin-bottom: 2.5rem;
  padding-bottom: 2.5rem; }
  .product.has-bg {
    margin-bottom: 3rem;
    padding-bottom: 3rem; }

.layout-boxed .product {
  padding-bottom: 0;
  margin-bottom: 0; }
  .layout-boxed .product.has-bg {
    padding-bottom: 3rem; }

.product_right_column .carousel-arrows button, .product_right_column .carousel-products button.slick-arrow > svg, .site-brands .carousel-arrows button, .site-brands .carousel-products button.slick-arrow > svg {
  height: 32px;
  width: 32px; }

@media (min-width: 992px) {
  .product_right_column {
    font-size: 1em;
    font-weight: bold;
    margin-bottom: 1em; }
    .product_right_column .products-section-title {
      font-size: 15px; }
    .product_right_column .product-miniature {
      margin-bottom: 1rem; } }

@media (max-width: 991.99px) {
  .images-container > .no-gutters {
    margin-left: -15px;
    margin-right: -15px; } }

.product-cover {
  position: relative;
  /*margin-bottom: 1em;*/ }
  .product-cover > img {
    width: 100%;
    height: auto;
    display: block; }
  .product-cover > .layer {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.2);
    transition: 200ms all ease-in-out;
    opacity: 0; }
    .product-cover > .layer > .icon {
      font-size: 72px;
      color: #fff; }
  .product-cover:hover > .layer {
    opacity: 1; }
  .product-cover .product-flags {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 10; }

.cover-overlay {
  background-color: rgba(0, 0, 0, 0.05);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1; }

.product-add-to-cart {
  margin: 1rem 0 2rem 0; }
  .product-add-to-cart .add-to-cart {
    float: right;
    display: block;
    width: 100%; }
    .product-add-to-cart .add-to-cart .icon {
      margin-right: .3em; }

@media (max-width: 575.99px) {
  .product-add-to-cart .add-to-cart {
    padding-left: 0;
    padding-right: 0;
    font-size: .8rem; } }

#kaliber_qty-add-to-cart .product-quantity {
  height: 100%; }
  #kaliber_qty-add-to-cart .product-quantity .qty {
    height: 100%; }
    #kaliber_qty-add-to-cart .product-quantity .qty .bootstrap-touchspin {
      width: 100%;
      height: 100%;
      box-sizing: border-box; }
      #kaliber_qty-add-to-cart .product-quantity .qty .bootstrap-touchspin input, #kaliber_qty-add-to-cart .product-quantity .qty .bootstrap-touchspin button {
        height: 100%; }

@media (min-width: 992px) {
  .small-product-miniature,
  .small-product-miniature button,
  .small-product-miniature .btn {
    font-size: .8em; } }

.product-variants {
  margin-top: 1.5rem; }

.variant {
  margin-bottom: 1em; }
  .variant select {
    width: auto;
    max-width: 100%; }

#product-modal .image-container {
  display: flex;
  align-items: center;
  justify-content: center; }

.product-information > .product-prices .product-price {
  /*             .regular-price {
            }
            .aeuc_before_label, .tax {
            }
            .discount-amount, .discount-percentage {
            } */ }

.product-information #product-description-short {
  margin: 1em 0; }

.product-pack {
  margin: 1em 0;
  padding: 1em;
  border-width: 1px;
  border-color: #eee;
  border-style: solid; }

.product-pack-miniature {
  margin-bottom: .5rem;
  padding: 0 0 .5rem 0;
  border-bottom-width: 1px;
  border-bottom-color: #eee;
  border-bottom-style: solid;
  font-size: 0.875em;
  text-align: left; }
  .product-pack-miniature .product-pack-title {
    font-size: 1em;
    margin-bottom: 0; }
  .product-pack-miniature:last-child {
    margin-bottom: 0;
    border-bottom: none; }
  .product-pack-miniature p {
    margin-bottom: 0; }

.tax-delivery {
  font-size: 0.875em;
  color: #999;
  margin-bottom: .5em; }

.option-label, .variant label {
  font-size: 0.875em;
  font-weight: bold;
  display: block;
  margin-bottom: .3em; }

#product-quantities {
  font-size: 0.875em; }

.mail-alert {
  border: 1px solid #eee;
  padding: 1rem;
  margin-top: 1.5rem; }
  .mail-alert-title {
    font-weight: bold;
    margin-bottom: .5rem; }

.mail-alert-form {
  position: relative; }
  .mail-alert-form .notification {
    margin-top: .5em;
    border: 1px solid rgba(255, 255, 255, 0.2);
    text-align: center;
    padding: .5em; }
    .mail-alert-form .notification.notification-success {
      border-color: #00d08d;
      color: #00d08d; }

.slider {
  opacity: 0;
  transition: opacity 1s ease;
  visibility: hidden; }

.slider.slick-initialized {
  opacity: 1;
  visibility: visible; }

.slick-slide {
  margin-bottom: 1em;
  overflow: hidden; }
  .slick-slide .image {
    text-align: center; }
    .slick-slide .image img {
      width: 100%;
      height: auto; }

.slick-dots {
  text-align: center; }
  .slick-dots li {
    display: inline-block;
    width: 12px;
    margin: 0 .2em; }
    .slick-dots li button {
      background: #999;
      text-indent: -9999px;
      border: none;
      border-radius: 12px;
      display: block;
      height: 12px;
      width: 12px;
      -webkit-appearance: none;
      cursor: pointer;
      padding: 0; }
      .slick-dots li button:focus {
        outline: none; }
    .slick-dots li.slick-active button {
      background: #31C9AA; }

.product-information .social-sharing {
  margin-top: 1.5rem; }

.product-flags {
  margin-bottom: .4em; }

.product-tabs {
  margin-top: 1.5rem;
  border-style: solid;
  border-width: 1px;
  border-color: #eee; }
  .product-tabs .card {
    border: none;
    border-radius: 0; }
  .product-tabs .card-header {
    background: none; }
  .product-tabs .card-body, .product-tabs .card {
    background: none; }
  .product-tabs .tab-pane {
    padding-top: 0;
    padding-bottom: 0; }
  .product-tabs .nav-tabs > .nav-item a {
    font-size: 0.875em;
    text-transform: uppercase; }

.product-customization {
  margin-top: 1.5rem; }
  .product-customization .file-input {
    width: 100%;
    opacity: 0;
    left: 0;
    z-index: 1;
    cursor: pointer;
    height: 2.625rem;
    overflow: hidden;
    position: absolute; }
  .product-customization .custom-file {
    position: relative;
    width: 100%;
    height: 2.625rem;
    line-height: 2.625rem;
    text-indent: 0.625rem;
    display: block; }
    .product-customization .custom-file button {
      z-index: 0;
      position: absolute;
      right: 0;
      top: 0; }

.tabs-to-accordion .active {
  display: none !important; }

.tabs-to-accordion .collapse {
  display: none !important; }

.tabs-to-accordion .collapse.show {
  display: block !important; }

.product-actions .classic-variants-mode .radio-group > li {
  float: none; }
  .product-actions .classic-variants-mode .radio-group > li label {
    line-height: inherit;
    padding-left: 30px;
    cursor: pointer;
    font-weight: normal; }
    .product-actions .classic-variants-mode .radio-group > li label .input-container input[type=radio] {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0; }
    .product-actions .classic-variants-mode .radio-group > li label .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 20px;
      width: 20px;
      background-color: #eee;
      border-radius: 50%; }
    .product-actions .classic-variants-mode .radio-group > li label:hover input[type=radio] ~ .checkmark {
      background-color: #ccc; }
    .product-actions .classic-variants-mode .radio-group > li label input[type=radio]:checked ~ .checkmark {
      background-color: #333; }
    .product-actions .classic-variants-mode .radio-group > li label .checkmark:after {
      content: "";
      position: absolute;
      display: none; }
    .product-actions .classic-variants-mode .radio-group > li label input[type=radio]:checked ~ .checkmark:after {
      display: block; }
    .product-actions .classic-variants-mode .radio-group > li label .checkmark:after {
      top: 7px;
      left: 7px;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background: white; }
    .product-actions .classic-variants-mode .radio-group > li label span {
      border: 0; }

.product-actions .radio-group {
  overflow: hidden;
  margin-bottom: 0; }
  .product-actions .radio-group > li {
    float: left;
    position: relative;
    margin-right: .5rem;
    margin-bottom: .5rem; }
  .product-actions .radio-group input[type=radio] {
    position: absolute;
    top: 0;
    cursor: pointer;
    opacity: 0;
    left: -9999px; }
  .product-actions .radio-group li > label {
    margin: 0;
    line-height: 1;
    cursor: pointer;
    display: block; }
    .product-actions .radio-group li > label > span {
      border: 1px solid #eee;
      display: inline-block;
      height: 3rem;
      width: 3rem;
      line-height: 3rem;
      text-align: center;
      font-size: 0.875em;
      background: #fff; }
  .product-actions .radio-group input[type=radio]:checked ~ span {
    background-color: #000;
    color: #fff; }
  .product-actions .radio-group .kaliber-rounded > label > span {
    border-radius: 50%; }

.product-actions .color-variants {
  border: none;
  padding: 1px; }
  .product-actions .color-variants li {
    margin-right: .5rem;
    margin-bottom: .5rem; }
  .product-actions .color-variants li > label > span {
    border: none;
    height: 2rem;
    width: 2rem;
    display: inline-block;
    border-radius: 50%;
    line-height: 1;
    background-size: 2rem 2rem;
    box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.2); }
  .product-actions .color-variants input[type=radio]:checked ~ span {
    box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.5); }

@media (min-width: 768px) {
  #product .social-sharing {
    display: block; } }

.js-response > span {
  display: block;
  padding: 1em .5em;
  background: #eee;
  margin-top: .5em;
  font-size: 0.875em;
  text-align: center; }

.js-response.error > span {
  border: 2px solid #dc3545;
  color: #dc3545;
  background: none; }

.js-response.success > span {
  margin-top: 0;
  border: 2px solid #28a745;
  color: #28a745;
  background: none; }

.product-avalibility {
  font-size: .875em;
  text-align: center;
  padding: .5rem;
  font-weight: bold;
  text-transform: uppercase; }
  .product-avalibility span {
    vertical-align: middle; }
  .product-avalibility.last-items {
    color: #ffc107; }
  .product-avalibility.unavailable {
    color: #000; }

.delivery-information {
  vertical-align: middle; }

.delivery-information-status {
  font-size: .875em;
  text-align: center; }

.product-detail {
  margin-bottom: .5rem; }
  .product-detail label {
    font-weight: bold;
    margin-bottom: 0; }

.product-features {
  margin-top: 1rem; }

.list-group-item {
  border-color: #eee; }

.feature-table dt, .feature-table dd {
  margin-right: 0;
  padding: .5rem 1rem;
  background: #f7f7f7;
  display: inline-table;
  width: 49%; }

.feature-table dd {
  margin-right: 0; }

/**
 * Remember to adjust the CSS according to the theme. This does not
 * override the classic theme. This is the essentials you need for
 * this to work. Adjust accordingly.
 */
.js-qv-mask.mask {
  overflow: hidden;
  position: relative; }

.product-images {
  font-size: 0;
  list-style: none;
  padding: 0;
  z-index: 1;
  margin-bottom: 0; }
  .product-images .thumb-container {
    padding-bottom: 0.5rem; }
    .product-images .thumb-container:last-child {
      padding-bottom: 0; }
  .product-images.is-expanded .thumb-container {
    display: inline-block; }

.modal .product-images.is-expanded .thumb-container {
  width: 100%; }

.thumb-container {
  cursor: pointer;
  display: inline-block;
  width: 100%; }

.thumb-container.is-hidden {
  display: none; }

.thumb-container img {
  display: block;
  height: auto;
  width: 100%; }

.thumb-container.show-all-images span {
  display: block;
  font-size: 14px;
  font-weight: 700;
  height: 0;
  line-height: 0;
  position: relative;
  width: 100%; }

.show-all-images span:before {
  content: attr(data-content-title);
  line-height: normal;
  text-align: center;
  position: absolute;
  top: calc(50% + 0.5em);
  right: 0;
  bottom: 0;
  left: 0; }

.show-all-images .icon {
  position: absolute;
  top: calc(50% - 0.7em);
  text-align: center;
  width: 100%;
  left: 0; }

#product-modal .modal-dialog {
  max-width: 800px; }

#product-modal figure {
  margin-bottom: 1rem; }

.table-product-discounts {
  width: 100%; }
  .table-product-discounts thead > tr {
    background: #cccccc;
    color: #000000; }
    .table-product-discounts thead > tr th {
      padding: 10px;
      text-transform: uppercase; }
  .table-product-discounts tbody {
    border-right: 1px solid #EEEEEE;
    border-left: 1px solid #EEEEEE;
    border-bottom: 1px solid #EEEEEE; }
    .table-product-discounts tbody tr {
      border-bottom: 1px solid #EEEEEE; }
      .table-product-discounts tbody tr td {
        padding: 5px 10px; }
      .table-product-discounts tbody tr:last-child {
        border-bottom: 0; }

.--updating#product-list, .--updating .content-box {
  filter: blur(2px); }

.highlighted-informations {
  position: absolute;
  bottom: 0;
  left: 0;
  padding-left: 10px; }

.subcategories {
  margin-top: 1.5rem; }
  @media (min-width: 992px) {
    .subcategories {
      margin-top: 0; } }
  .subcategories ul {
    margin-left: 0; }
    .subcategories ul > li {
      margin-bottom: .3rem;
      page-break-inside: avoid; }
      .subcategories ul > li > a {
        padding: .5rem 0; }
        .subcategories ul > li > a .icon {
          font-size: 18px; }
        .subcategories ul > li > a span {
          vertical-align: middle; }
      .subcategories ul > li .center-sub {
        text-align: center; }
    .subcategories ul.show-images > li, .subcategories ul.show-desc > li {
      padding-bottom: .5rem;
      border-bottom: 1px solid #eee;
      margin-bottom: .5rem; }
      .subcategories ul.show-images > li .category-title, .subcategories ul.show-desc > li .category-title {
        font-weight: bold; }
      .subcategories ul.show-images > li .icon, .subcategories ul.show-desc > li .icon {
        display: none; }
      .subcategories ul.show-images > li .category-description *:last-child, .subcategories ul.show-desc > li .category-description *:last-child {
        margin-bottom: 0; }

.subcategory-box ul {
  margin-left: -3px;
  margin-right: -3px; }
  .subcategory-box ul.show-images > li {
    border-width: 0; }
  .subcategory-box ul li {
    padding-left: 3px;
    padding-right: 3px; }
    .subcategory-box ul li .align-items-center {
      padding-left: 3px;
      padding-right: 3px;
      margin-left: -3px;
      margin-right: -3px; }
    .subcategory-box ul li .center-sub:first-child {
      border-style: solid;
      border-width: 1px; }

#category-description *:last-child {
  margin-bottom: 0; }

.back-to-top > a {
  display: block;
  background: #eee;
  padding: 1em;
  text-align: center;
  font-size: 0.857em;
  margin-top: 1em; }

.category-header #category-description {
  font-size: 1.250em; }

.product-list-bottom .content-box {
  padding-top: 1em;
  padding-bottom: 1em; }

.cover-narrow .category-cover {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  margin-top: 2rem;
  max-width: 1440px; }

.category-cover > div {
  min-height: 160px;
  height: 20vh;
  background-position: center;
  background-size: cover; }
  @media (min-width: 992px) {
    .category-cover > div {
      height: 300px; } }

ul.cart-items {
  margin: 0; }
  ul.cart-items > li {
    padding: 1rem 0;
    border-bottom: 1px solid #eee; }
    ul.cart-items > li:first-child {
      padding-top: 0; }
    ul.cart-items > li:last-child {
      padding-bottom: 0;
      border-bottom: none; }

.cart-overview .cart-product-title {
  margin-bottom: .3rem; }
  .cart-overview .cart-product-title a {
    color: #000; }

.cart-overview .content-box {
  padding: 1.5rem;
  border: 1px solid #eee; }

#cart-subtotal-discount .value {
  color: #E44A4A; }

.cart-actions {
  margin-bottom: 1rem; }
  .cart-actions .button {
    display: block; }

.cart-total > .value {
  font-weight: bold;
  font-size: 1.286em; }

.cart-product-name {
  font-weight: bold; }

.remove-from-cart i {
  font-size: 1rem; }

.cart-item .product-image {
  display: block; }

.product-line-info.product-details {
  font-size: 0.875em; }

.promo-code .alert-danger {
  display: none;
  border: 2px solid #dc3545;
  background: none;
  color: #dc3545;
  padding: .5rem;
  font-size: 0.875em;
  font-weight: 500;
  margin-top: 1em; }

.promo-code span {
  vertical-align: middle; }

.promo-code-line {
  padding: .5em 1em;
  border: 1px solid #eee; }

.cart-voucher .cart-summary-line {
  background: #F5F5F5;
  padding: .5em 1em;
  font-size: 0.875em; }

.cart-voucher .voucher-discount {
  color: #E44A4A;
  font-weight: 500; }
  .cart-voucher .voucher-discount a {
    margin-left: .5em; }
  .cart-voucher .voucher-discount .icon {
    font-size: 18px; }

.cart-voucher .voucher-form {
  overflow: hidden; }
  .cart-voucher .voucher-form form {
    width: 100%; }

.cart-detailed-totals {
  margin-bottom: 1rem; }

.cart-summary-line {
  padding: 1rem;
  background: #eee;
  margin-bottom: 1px; }
  .cart-summary-line:last-child {
    border-bottom: none; }

.cart-summary-line, .promo-code-line {
  display: table;
  width: 100%; }
  .cart-summary-line > div, .promo-code-line > div {
    display: table;
    width: 100%; }
  .cart-summary-line span, .promo-code-line span {
    display: table-cell; }
    .cart-summary-line span.value, .promo-code-line span.value {
      text-align: right;
      font-weight: bold; }

.layout-boxed .cart-overview .content-box {
  margin-top: 0; }

.media-list {
  font-size: 0.875em; }
  .media-list li {
    margin-bottom: .5rem; }

.address-selector {
  overflow: hidden; }

#checkout {
  font-size: 93.8%; }

#checkout main .container {
  max-width: 1024px; }

.checkout-step {
  border-bottom: none;
  position: relative; }
  @media (min-width: 992px) {
    .checkout-step::before {
      content: "";
      position: absolute;
      top: -1px;
      bottom: -1px;
      left: 1rem;
      width: 1px;
      background: #eee;
      z-index: 1; } }
  .checkout-step .content {
    padding: 1.5rem 0; }
  .checkout-step .step-title {
    padding: .5rem 0;
    font-size: 1.333em;
    text-transform: none;
    margin: 0;
    position: relative; }
    @media (min-width: 992px) {
      .checkout-step .step-title {
        padding: 0 0 2rem 0; } }
    .checkout-step .step-title:hover {
      color: #000; }
    .checkout-step .step-title > .row {
      position: static; }
  .checkout-step .step-number {
    height: 36px;
    width: 36px;
    background: #fff;
    z-index: 3;
    border-radius: 100%;
    font-size: 1rem;
    text-align: center;
    line-height: 36px;
    color: #fff;
    background: #ccc;
    font-weight: normal;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    display: block;
    position: relative; }
    @media (min-width: 992px) {
      .checkout-step .step-number {
        left: 1rem;
        right: auto;
        top: 0;
        transform: translateX(-50%); } }
  .checkout-step .done {
    display: none !important;
    font-size: 36px; }
  .checkout-step.-complete .step-number {
    background: #31C9AA; }
  .checkout-step.-complete > .content {
    display: none; }
  .checkout-step.-reachable > .content {
    display: none; }
  .checkout-step.-reachable .done {
    display: none; }
  .checkout-step.-reachable .step-title {
    cursor: pointer; }
  .checkout-step.-reachable.-complete .done {
    display: block; }
  .checkout-step.-unreachable .done {
    display: none; }
  .checkout-step.-current .step-number {
    background: #000; }
  .checkout-step.-current .done {
    display: none; }
  .checkout-step.-current .step-title {
    color: #000; }
  .checkout-step.js-current-step .step-title {
    padding-bottom: 0; }
  .checkout-step.js-current-step > .content {
    display: block; }
  .checkout-step:last-child .step-title {
    padding-bottom: 0; }
  .checkout-step:last-child .content {
    border-bottom: none; }
  .checkout-step.-reachable.-complete.-current .done {
    display: none; }

/* Adress block */
.address-item {
  margin-bottom: 1em; }
  .address-item > .inner {
    border: 1px solid #eee; }
  .address-item header {
    border-bottom: 1px solid #eee; }
  .address-item footer {
    padding: .8rem;
    font-size: 0.875em; }
    .address-item footer .icon {
      font-size: 16px; }
    .address-item footer a {
      text-transform: uppercase;
      font-weight: 500; }
    .address-item footer .delete-address {
      float: right; }

.address-option label {
  width: 100%;
  margin: 0;
  padding-left: 1em;
  transition: 300ms ease all;
  z-index: 1;
  background: #fff;
  cursor: pointer; }
  .address-option label::before {
    width: 100%;
    height: 100%;
    border: none;
    line-height: 38px;
    opacity: 0;
    border-radius: 0 !important;
    top: 0;
    z-index: -1;
    transition: 300ms ease all; }
  .address-option label::after {
    display: none !important; }

.address-option .option-input:checked + label {
  color: #fff; }

.address-option .option-input:checked + label::before {
  line-height: 38px;
  opacity: 1;
  background: #262626;
  color: #fff;
  box-shadow: none; }

.address-option .address {
  padding: .8em 0;
  line-height: 1.2;
  font-size: 0.875em; }
  .address-option .address .address-alias {
    display: block;
    font-weight: bold; }

/*Shippnig*/
.delivery-options {
  border: 1px solid #eee;
  margin-bottom: 2rem; }

.delivery-options-list .delivery-option {
  border-top: 1px solid #eee; }
  .delivery-options-list .delivery-option:first-child {
    border-top: none; }

.delivery-options-list .delivery-option > label {
  margin: 0;
  width: 100%;
  padding: 1rem 3.5rem; }
  .delivery-options-list .delivery-option > label::before {
    left: 12px;
    top: 50%;
    transform: translateY(-50%); }
  .delivery-options-list .delivery-option > label:hover {
    background: #f5f5f5; }

.delivery-options-list .option-input:checked + label {
  background: #f5f5f5; }

.delivery-options-list .carrier-name {
  display: inline-block;
  margin-right: .5em;
  font-weight: bold; }

.delivery-options-list .delivery-option:last-child label {
  border-bottom: none; }

.carrier-extra-content {
  overflow: hidden; }
  .carrier-extra-content .content {
    padding: 0; }
    .carrier-extra-content .content > * {
      padding-left: 1em;
      padding-right: 1em; }
    .carrier-extra-content .content > *:first-child {
      padding-top: 1em; }
    .carrier-extra-content .content > *:last-child {
      padding-bottom: 1em;
      margin-bottom: 0; }

.order-options textarea {
  height: 60px;
  transition: 150ms ease all; }
  .order-options textarea:focus {
    height: 100px; }

/*Payment*/
.payment-options {
  border: 1px solid #eee; }
  .payment-options .payment-option {
    border-top: 1px solid #eee; }
    .payment-options .payment-option:first-child {
      border-top: none; }
    .payment-options .payment-option > input {
      display: none !important; }
    .payment-options .payment-option > label {
      margin: 0;
      width: 100%;
      padding: 1em 50px; }
      .payment-options .payment-option > label::before {
        left: 12px;
        top: 50%;
        transform: translateY(-50%); }
      .payment-options .payment-option > label:hover {
        background: #f5f5f5; }
    .payment-options .payment-option .option-input:checked + label {
      background: #f5f5f5; }
  .payment-options .additional-information {
    border-top: 1px solid #eee; }
    .payment-options .additional-information .content {
      padding: 1em; }

/*Summary*/
.summary-block {
  margin-bottom: 1em; }

.summary-addresses {
  border: 1px solid #eee; }
  .summary-addresses .summary-address {
    padding: 1em;
    border-bottom: 1px solid #eee; }
    @media (min-width: 768px) {
      .summary-addresses .summary-address {
        border-right: 1px solid #eee;
        border-bottom: none; } }
    .summary-addresses .summary-address.last {
      border-right: none;
      border-bottom: none; }

.summary-selected-carrier {
  padding: 1em;
  border: 1px solid #eee; }
  .summary-selected-carrier .carrier-name {
    font-weight: bold; }

.order-confirmation-products {
  border: 1px solid #eee;
  margin-bottom: 1em; }

.order-line {
  padding: .5em;
  border-bottom: 1px solid #eee; }
  .order-line:last-child {
    border-bottom: none; }

.order-confirmation-table table {
  border: 1px solid #eee;
  width: 100%;
  border-collapse: collapse; }
  .order-confirmation-table table td {
    padding: .5em;
    border-bottom: 1px solid #eee; }

#payment-confirmation .alert-danger {
  margin: 0;
  background: none;
  font-size: 0.875em;
  color: #E44A4A;
  padding: 1em;
  border: 2px solid #E44A4A; }
  #payment-confirmation .alert-danger a {
    color: #E44A4A;
    font-weight: bold; }

.order-confirmation {
  border: 1px solid #eee;
  padding: 1.5rem;
  margin-bottom: 1.5em; }
  .order-confirmation .icon {
    color: #28a745; }

.order-confirmation-table {
  margin-bottom: 1.5rem; }

.customer-nav {
  background: #fff;
  border-style: solid;
  border-width: 1px;
  border-color: #eee;
  margin-bottom: 1.5rem; }
  .customer-nav > ul {
    margin: 0; }
    .customer-nav > ul.overview > li {
      height: 53px; }
    .customer-nav > ul > li {
      position: relative;
      height: 0;
      overflow: hidden;
      transition: 300ms ease all; }
      @media (min-width: 992px) {
        .customer-nav > ul > li {
          display: block;
          height: 53px; } }
      .customer-nav > ul > li a {
        height: 53px;
        display: block;
        width: 100%;
        max-width: none;
        border-bottom-style: solid;
        border-bottom-width: 1px;
        border-bottom-color: #eee;
        display: block;
        padding: 1em; }
        .customer-nav > ul > li a i {
          margin-right: .3em; }
      .customer-nav > ul > li.current-page {
        height: 53px;
        border: none; }
        .customer-nav > ul > li.current-page::after {
          content: "\e842";
          font-family: 'feather' !important;
          text-rendering: optimizeLegibility;
          font-size: 24px;
          position: absolute;
          top: 50%;
          margin-top: -12px;
          right: 1em;
          cursor: pointer;
          height: 24px;
          line-height: 1; }
        .customer-nav > ul > li.current-page a {
          border-bottom: none; }
        @media (min-width: 992px) {
          .customer-nav > ul > li.current-page {
            border-bottom: 1px solid #eee; }
            .customer-nav > ul > li.current-page:last-child {
              border-bottom: 0; }
            .customer-nav > ul > li.current-page::after {
              display: none; } }
        .customer-nav > ul > li.current-page a {
          font-weight: bold; }
      .customer-nav > ul > li:last-child a {
        border-bottom: none; }
  .customer-nav.open li {
    height: 53px; }
    .customer-nav.open li.current-page a {
      border-bottom-style: solid;
      border-bottom-width: 1px;
      border-bottom-color: #eee; }
    .customer-nav.open li.current-page::after {
      content: "\e83f"; }

article.address {
  border-style: solid;
  border-width: 1px;
  border-color: #eee; }
  article.address .address-body {
    padding: 1rem; }
  article.address .address-footer {
    padding: 1rem; }

.overview-block {
  border-style: solid;
  border-width: 1px;
  border-color: #eee;
  padding: 1em;
  margin-bottom: 1em; }

.addresses-footer {
  margin-bottom: 1em; }
  .addresses-footer a {
    padding: 2em;
    border: 1px dashed #eee;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center; }
    .addresses-footer a:hover {
      border-color: currentColor; }

.customer-page .content-box {
  margin-top: 0; }

.order, .cart-rule {
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #eee;
  padding-bottom: 1em;
  margin-bottom: 1em; }
  .order:last-child, .cart-rule:last-child {
    border: none;
    padding-bottom: 0;
    margin-bottom: 0; }

.ps-alert-error li, .ps-alert-success li {
  border: 2px solid #eee;
  padding: .5rem 1rem;
  text-align: center; }
  .ps-alert-error li p, .ps-alert-success li p {
    margin: 0; }

.ps-alert-success li {
  border-color: #28a745;
  color: #28a745; }

.ps-alert-error li {
  border-color: #E44A4A;
  color: #E44A4A; }

body:not(.layout-boxed) .login-form .content-box {
  padding: 1.5rem;
  border: 1px solid #eee; }

#login-form .btn[data-action="show-password"] {
  width: 80px; }

.my-account-links > a {
  margin-bottom: .5rem; }

.my-account-links .link-item {
  display: block;
  padding: 1.5rem;
  border: 1px solid #eee; }

.page-cms h1 {
  margin-bottom: 1em; }

body {
  background: #fff;
  overflow-x: hidden;
  padding-top: 60px; }
  @media (min-width: 992px) {
    body {
      padding-top: 0; } }

.modal {
  padding-right: 0 !important; }

@media (min-width: 992px) {
  .modal-dialog {
    max-width: 960px; } }

.products-section-title {
  margin: 0; }

.block-header {
  padding-bottom: 1rem;
  margin-bottom: 1.5rem;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #eee; }

.featured-products {
  margin-bottom: 3rem; }

.modal figure {
  margin-bottom: 0; }

#menu-trigger {
  display: block; }
  @media (min-width: 992px) {
    #menu-trigger {
      display: none; } }

.home-banner {
  margin-top: 2em; }
  .home-banner img {
    width: 100%;
    height: auto; }

body#checkout {
  padding-top: 0 !important; }

hr {
  border-top: 1px solid #eee; }

.category-tree {
  background: #fff; }
  .category-tree ul {
    display: none; }
  .category-tree > ul {
    display: block; }
    .category-tree > ul > li {
      display: block;
      border-bottom: 1px solid #eee; }
      .category-tree > ul > li > ul {
        display: block; }
        .category-tree > ul > li > ul > li {
          border-bottom: 1px solid #eee;
          padding-left: 1.5rem; }
          .category-tree > ul > li > ul > li:last-child {
            border-bottom: none; }
  .category-tree a {
    display: block;
    padding: 1rem; }

#login-form .col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.page-header {
  overflow: hidden; }

#demo-trigger {
  position: absolute;
  left: 0;
  top: 50%;
  height: 70px;
  width: 70px;
  box-shadow: -2px 0 3px rgba(0, 0, 0, 0.2);
  z-index: 99999;
  background: #ffffff;
  border-radius: 3px 0 0 3px;
  padding: .5rem;
  text-align: center;
  color: #000;
  transform: translateX(-100%);
  cursor: pointer; }
  #demo-trigger span {
    position: absolute;
    left: 2px;
    top: 15px;
    transform: rotate(-90deg);
    transform-origin: bottom; }
  #demo-trigger .icon {
    position: absolute;
    top: 50%;
    right: 10px;
    font-size: 2em;
    transform: translateY(-50%); }

#demo-flyout {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 99989;
  height: 100vh;
  background: #ffffff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  transform: translateX(100%);
  transition: 300ms ease all; }
  #demo-flyout.out {
    transform: translateX(0); }
  #demo-flyout .inner {
    padding: 1.5rem; }
  #demo-flyout .demo {
    margin-bottom: 1rem; }
    #demo-flyout .demo img {
      border-radius: 5px;
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
      margin-bottom: .4rem;
      width: 150px; }
    #demo-flyout .demo span {
      display: block; }
