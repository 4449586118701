.products-sort-order {
    
    @include media-breakpoint-up(lg) {
        float: right;  
    }
    > button {
        text-align: right;
        background: none;
        width: 100%;
        padding: .5rem 0;

        @include media-breakpoint-up(lg) {
            width: auto;
        }
        &:focus, &:active {
            outline: none;
        }
    }

}