.media-list {
    font-size: 0.875em;
    li {
        margin-bottom: .5rem;
    }
}
.address-selector {
    overflow: hidden;
}
#checkout {
    font-size: 93.8%;
}
#checkout main .container {
    max-width: 1024px;
}

.checkout-step {
    //border: 1px solid #eee;
    border-bottom: none;
    position: relative;

    @include media-breakpoint-up(lg) {
        &::before {
            content: "";
            position: absolute;
            top: -1px;
            bottom: -1px;
            left: 1rem;
            width: 1px;
            background: #eee;
            z-index: 1;
        }
    }

    .content {
        padding: 1.5rem 0;
    }

    .step-title {
        padding:.5rem 0;
        font-size: 1.333em;
        text-transform: none;
        margin: 0;
        position: relative;


        @include media-breakpoint-up(lg) {
            padding:0 0 2rem 0;
        }

        &:hover {
            color: #000;
        }
        > .row {
            position: static;
        }

    }
    .step-number {
        height: 36px;
        width: 36px;
        background: #fff;
        z-index: 3;
        border-radius: 100%;
        font-size: 1rem;
        text-align: center;
        line-height: 36px;
        color: #fff;
        background: #ccc;
        font-weight: normal;
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
        display: block;
        position: relative;

        @include media-breakpoint-up(lg) {
            left: 1rem;
            right: auto;
            top: 0;
            transform: translateX(-50%);
        }
    }
    .done {
        display: none !important;
        font-size: 36px;
    }

    &.-complete {

        .step-number  {
            background:$primary;
        }
        > .content {
            display: none;
        }
    }
    &.-reachable {
        > .content {
            display: none;
        }
        .done {
            display: none;
        }
        .step-title {
            cursor: pointer;
        }
    }
    &.-reachable {
        &.-complete {
            .done {
                display: block;
            }
        }
    }
    &.-unreachable {
        .done {
            display: none;
        }
    }
    &.-current {

        .step-number  {
            background: #000;
        }

        .done {
            display: none;
        }
        .step-title {
            color: #000;
        }
    }
    &.js-current-step {
        .step-title {
            padding-bottom: 0;
        }
        > .content {
            display: block;
        }
    }
    &:last-child {
        .step-title {
            padding-bottom: 0;
        }
        .content {
            border-bottom: none;
        }
    }
    &.-reachable.-complete.-current {
        .done {
            display: none;
        }
    }
}


/* Adress block */
.address-item {
    margin-bottom: 1em;
    > .inner {
        border: 1px solid #eee;
    }
    header {
        border-bottom: 1px solid #eee;
    }
    footer {
        padding: .8rem;
        font-size: 0.875em;
        .icon {
            font-size: 16px;
        }
        a {
            text-transform: uppercase;
            font-weight: 500;
        }
        .delete-address {
            float: right;
        }
    }

}

.address-option {
    label {
        width: 100%;
        margin: 0;
        padding-left: 1em;
        transition: 300ms ease all;
        z-index: 1;
        background: #fff;
        cursor: pointer;


        &::before {
            width: 100%;
            height: 100%;
            border: none;
            line-height: 38px;
            opacity: 0;
            border-radius: 0 !important;
            top: 0;
            z-index: -1;
            transition: 300ms ease all;
        }
        &::after {
            display: none !important;
        }
    }
    .option-input:checked + label {
        color: #fff;

    }
    .option-input:checked + label::before {
        line-height: 38px;
        opacity: 1;
        background: #262626;
        color: #fff;
        box-shadow: none;
    }
    .address {
        padding: .8em 0;
        line-height: 1.2;
        font-size: 0.875em;
        .address-alias {
            display: block;
            font-weight: bold;
        }
    }
}

/*Shippnig*/

.delivery-options {
    border: 1px solid #eee;
    margin-bottom: 2rem;
}

.delivery-options-list {
    .delivery-option {
        border-top: 1px solid #eee;
        &:first-child {
            border-top: none;
        }
    }
    .delivery-option > label {
        margin: 0;
        width: 100%;
        padding: 1rem 3.5rem;
        
        &::before {
            left: 12px;
            top: 50%;
            transform: translateY(-50%);
        }
        &:hover {
            background: #f5f5f5;
        }
    }
    .option-input:checked + label {
        background: #f5f5f5;
    }
    .carrier-name {
        display: inline-block;
        margin-right: .5em;
        font-weight: bold;
    }
    .delivery-option {
        &:last-child {
            label  {
                border-bottom: none;
            }
        }
    }
}
.carrier-extra-content {
    overflow: hidden;
    .content {
        padding: 0;
        > * {
            padding-left: 1em;
            padding-right: 1em;
        }
        & > *:first-child {
            padding-top: 1em;
        }
        & > *:last-child {
            padding-bottom: 1em;
            margin-bottom: 0; 
        }
    }
}
.order-options {
    textarea {
        height: 60px;
        transition: 150ms ease all;
        &:focus {
            height: 100px;
        }
    }
}



/*Payment*/


.payment-options {
    border: 1px solid #eee;

    .payment-option {
        border-top: 1px solid #eee;
        &:first-child {
            border-top: none;
        }
        > input {
            display: none !important;
        }
        > label {
        margin: 0;
        width: 100%;
        padding: 1em 50px;

            &::before {
                left: 12px;
                top: 50%;
                transform: translateY(-50%);
            }
            &:hover {
                background: #f5f5f5;
            }
        }

        .option-input:checked + label {
            background: #f5f5f5;
        }
    }
    .additional-information {
        border-top: 1px solid #eee;
        .content {
            padding: 1em;
        }
    }
}


/*Summary*/
.summary-block {
    margin-bottom: 1em;
}
.summary-addresses {
    border: 1px solid #eee;
    .summary-address {
        padding: 1em;
        border-bottom: 1px solid #eee;
        @include media-breakpoint-up(md) {
            border-right: 1px solid #eee;
            border-bottom: none;
        }
        &.last {
            border-right: none;
            border-bottom: none;
        }
    }
}
.summary-selected-carrier {
    padding: 1em;
    border: 1px solid #eee;
    .carrier-name {
        font-weight: bold;
    }
}
.order-confirmation-products {
    border: 1px solid #eee;
    margin-bottom: 1em;
}
.order-line {
    padding: .5em;
    border-bottom: 1px solid #eee;
    &:last-child {
        border-bottom: none;
    }
}
.order-confirmation-table {
    table {
        border: 1px solid #eee;
        width: 100%;
        border-collapse: collapse;
        td {
            padding: .5em;
            border-bottom: 1px solid #eee;
        }
    }
}

#payment-confirmation {
    .alert-danger {
        margin: 0;
        background: none;
        font-size: 0.875em;
        color: $sale;
        padding: 1em;
        border: 2px solid $sale;
        a {
            color: $sale;
            font-weight: bold;
        }
    }
}

.order-confirmation {
    border: 1px solid #eee;
    padding: 1.5rem;
    margin-bottom: 1.5em;
    .icon {
        color: $success;
    }
}

.order-confirmation-table {
    margin-bottom: 1.5rem;
}